import React from "react";
import moment from 'moment';

import Utils from '../../../utils/utils';
import { StyledBilllingStatus } from "../../../utils/styles";
import { BillingTypes } from "../../../utils/enums";


const customerNameTemplate = (id, row, col, obj, index) => {
    return (row.customer && row.customer.customerName) ? row.customer.customerName : ''
};
const customerNameExportTemplate = (row) => {
    return (row.customer && row.customer.customerName) ? row.customer.customerName : '';
};
const customerNameExportCSVTemplate = (row) => {
    return (row.customer && row.customer.customerName) ? row.customer.customerName : '';
};

const siteNameTemplate = (id, row, col, obj, index) => {
    let address = '';
    if(row.steps && row.steps.length > 0){
      for(let i = 0; i < row.steps.length; i++){
        let item = row.steps[i];

        if(item.customerSiteId){
          address = Utils.formatCustomerSiteAddress(item);
          break;
        }
      }

      if(address === ''){
        let item = (row.steps && row.steps[0]) ? row.steps[0] : null;
        address = (item && item.address) ? item.address : '';
      }
    }

    return address
};
const siteNameExportTemplate = (row) => {
    let address = '';
    if(row.steps && row.steps.length > 0){
      for(let i = 0; i < row.steps.length; i++){
        let item = row.steps[i];

        if(item.customerSiteId){
          address = Utils.formatCustomerSiteAddress(item);
          break;
        }
      }

      if(address === ''){
        let item = (row.steps && row.steps[0]) ? row.steps[0] : null;
        address = (item && item.address) ? item.address : '';
      }
    }

    return address
};
const siteNameExportCSVTemplate = (row) => {
    let address = '';
    if(row.steps && row.steps.length > 0){
      for(let i = 0; i < row.steps.length; i++){
        let item = row.steps[i];

        if(item.customerSiteId){
          address = Utils.formatCustomerSiteAddress(item);
          break;
        }
      }

      if(address === ''){
        let item = (row.steps && row.steps[0]) ? row.steps[0] : null;
        address = (item && item.address) ? item.address : '';
      }
    }

    return address
};

const jobStatusNameTemplate = (id, row, col, obj, index) => {
  return <div className={((row.jobStatusName == 'Cancelled' || row.jobStatusName == 'Failed') ? 'text-danger' : '')}>{row.jobStatusName}</div>
};
const jobStatusNameExportTemplate = (row) => {
  return row.jobStatusName;
}
const jobStatusNameExportCSVTemplate = (row) => {
  return row.jobStatusName;
}

const jobDateTemplate = (id, row, col, obj, index) => {
    return (row && row.jobAttemptCompletedDate) ? moment(row.jobAttemptCompletedDate).format(Utils.getDefaultDateFormat()) : ''
};
const jobDateExportTemplate = (row) => {
    return (row && row.jobAttemptCompletedDate) ? moment(row.jobAttemptCompletedDate).format(Utils.getDefaultDateFormat()) : ''
};
const jobDateExportCSVTemplate = (row) => {
    return (row && row.jobAttemptCompletedDate) ? moment(row.jobAttemptCompletedDate).format(Utils.getCSVDateFormat()) : ''
};

const jobNumberTemplate = (id, row, col, obj, index) => {
    return <a href={'/jobs-form?id=' + row.jobId}
      className={'d-flex align-items-center'}
      target={'_blank'}
      rel="noreferrer"
      onClick={(e) => {
        // e.preventDefault();
        e.stopPropagation();
        
        // this.props.history.push('/jobs-form?id=' + row.jobId);
      }}
    >
      {row.jobNumber}
      <span className={'material-icons ps-1'} style={{ fontSize: '18px' }}>open_in_new</span>
    </a>
};
const jobNumberExportTemplate = (row) => {
    return row.jobNumber
};
const jobNumberExportCSVTemplate = (row) => {
    return row.jobNumber
};

const amountTemplate = (id, row, col, obj, index) => {
    let totalPrice = (row && row.totalPrice !== '') ? row.totalPrice : 0;
    return Utils.getCurrency() + Utils.formatNumber(totalPrice)
};
const amountExportTemplate = (row) => {
    let totalPrice = (row && row.totalPrice !== '') ? row.totalPrice : 0;
    return Utils.getCurrency() + Utils.formatNumber(totalPrice)
}
const amountExportCSVTemplate = (row) => {
    let totalPrice = (row && row.totalPrice !== '') ? row.totalPrice : 0;
    return Utils.getCurrency() + Utils.formatNumber(totalPrice)
}

const getTaxInclusive = (row) => {
    let isTaxInclusive = (row && ((row.isTaxInclusive === true) || (row.isTaxInclusive === false))) ? row.isTaxInclusive : false;
    let invoiceTaxRate = (row && row.invoiceTaxRate) ? row.invoiceTaxRate : null;
    let purchaseTaxRate = (row && row.purchaseTaxRate) ? row.purchaseTaxRate : null;
    let taxRate = (invoiceTaxRate) ? Utils.formatNumber(invoiceTaxRate) : (purchaseTaxRate) ? Utils.formatNumber(purchaseTaxRate) : null;

    return (isTaxInclusive) ? (taxRate) ? 'YES (' + taxRate + '%)' : 'NO' : 'NO';
}
const taxInclusiveTemplate = (id, row, col, obj, index) => {
    return getTaxInclusive(row);
};
const taxInclusiveExportTemplate = (row) => {
    return getTaxInclusive(row);
};
const taxInclusiveExportCSVTemplate = (row) => {
    return getTaxInclusive(row);
};

const amountWithoutTaxTemplate = (id, row, col, obj, index) => {
    let totalPriceBeforeTax = (row && Utils.isNumber(row.totalPriceBeforeTax)) ? row.totalPriceBeforeTax : 0;
    return Utils.getCurrency() + Utils.formatNumber(totalPriceBeforeTax)
};
const amountWithoutTaxExportTemplate = (row) => {
    let totalPriceBeforeTax = (row && Utils.isNumber(row.totalPriceBeforeTax)) ? row.totalPriceBeforeTax : 0;
    return Utils.getCurrency() + Utils.formatNumber(totalPriceBeforeTax)
}
const amountWithoutTaxExportCSVTemplate = (row) => {
    let totalPriceBeforeTax = (row && Utils.isNumber(row.totalPriceBeforeTax)) ? row.totalPriceBeforeTax : 0;
    return Utils.getCurrency() + Utils.formatNumber(totalPriceBeforeTax)
}

const billingStatusTemplate = (id, row, col, obj, index) => {
    let status = (obj.props.getBillingType == BillingTypes.Purchase) ? row.purchaseOrderBillingStatus : (obj.props.getBillingType == BillingTypes.Expense) ? row.expenseBillingStatus : row.billingStatus;
    return <StyledBilllingStatus status={status}>{status}</StyledBilllingStatus>
};
const billingStatusExportTemplate = (row, obj) => {
    return (obj.props.getBillingType == BillingTypes.Purchase) ? row.purchaseOrderBillingStatus : (obj.props.getBillingType == BillingTypes.Expense) ? row.expenseBillingStatus : row.billingStatus;
}
const billingStatusExportCSVTemplate = (row, obj) => {
    return (obj.props.getBillingType == BillingTypes.Purchase) ? row.purchaseOrderBillingStatus : (obj.props.getBillingType == BillingTypes.Expense) ? row.expenseBillingStatus : row.billingStatus;
}

const isPurchaseOrderedTemplate = (id, row, col, obj, index) => {
    let txt = (row.isPurchaseOrdered) ? 'PO issued' : 'PO not issued';
    return <StyledBilllingStatus status={txt}>{txt}</StyledBilllingStatus>
};
const isPurchaseOrderedExportTemplate = (row) => {
    return (row.isPurchaseOrdered) ? 'PO issued' : 'PO not issued';
}
const isPurchaseOrderedExportCSVTemplate = (row) => {
    return (row.isPurchaseOrdered) ? 'PO issued' : 'PO not issued';
}

const isExpensedTemplate = (id, row, col, obj, index) => {
    let txt = (row.isExpensed) ? 'EXP issued' : 'EXP not issued';
    return <StyledBilllingStatus status={txt}>{txt}</StyledBilllingStatus>
};
const isExpensedExportTemplate = (row) => {
    return (row.isExpensed) ? 'EXP issued' : 'EXP not issued';
}
const isExpensedExportCSVTemplate = (row) => {
    return (row.isExpensed) ? 'EXP issued' : 'EXP not issued';
}

const billedDateTemplate = (id, row, col, obj, index) => {
    return (row && row.billedDate) ? moment(row.billedDate).format(Utils.getDefaultDateFormat()) : ''
};
const billedDateExportTemplate = (row) => {
    return (row && row.billedDate) ? moment(row.billedDate).format(Utils.getDefaultDateFormat()) : ''
}
const billedDateExportCSVTemplate = (row) => {
    return (row && row.billedDate) ? moment(row.billedDate).format(Utils.getCSVDateFormat()) : ''
}

const purchaseOrderDateTemplate = (id, row, col, obj, index) => {
    return (row && row.purchaseOrderDate) ? moment(row.purchaseOrderDate).format(Utils.getDefaultDateFormat()) : ''
};
const purchaseOrderDateExportTemplate = (row) => {
    return (row && row.purchaseOrderDate) ? moment(row.purchaseOrderDate).format(Utils.getDefaultDateFormat()) : ''
}
const purchaseOrderDateExportCSVTemplate = (row) => {
    return (row && row.purchaseOrderDate) ? moment(row.purchaseOrderDate).format(Utils.getCSVDateFormat()) : ''
}

const expenseDateTemplate = (id, row, col, obj, index) => {
    return (row && row.expenseDate) ? moment(row.expenseDate).format(Utils.getDefaultDateFormat()) : ''
};
const expenseDateExportTemplate = (row) => {
    return (row && row.expenseDate) ? moment(row.expenseDate).format(Utils.getDefaultDateFormat()) : ''
}
const expenseDateExportCSVTemplate = (row) => {
    return (row && row.expenseDate) ? moment(row.expenseDate).format(Utils.getCSVDateFormat()) : ''
}

const invoiceDateTemplate = (id, row, col, obj, index) => {
    if(row.billingStatus == 'Unbilled'){
        return ''
    } else {
        return (row && row.invoiceDate) ? moment(row.invoiceDate).format(Utils.getDefaultDateFormat()) : ''
    }
};
const invoiceDateExportTemplate = (row) => {
    return (row && row.invoiceDate) ? moment(row.invoiceDate).format(Utils.getDefaultDateFormat()) : ''
}
const invoiceDateExportCSVTemplate = (row) => {
    return (row && row.invoiceDate) ? moment(row.invoiceDate).format(Utils.getCSVDateFormat()) : ''
}

const invoiceNumberTemplate = (id, row, col, obj, index) => {
    if(row.quickbookInvoiceUrl && row.quickbookInvoiceUrl !== ''){
      return obj.props.getQBList(row);
    } else if (row.xeroInvoiceUrl && row.xeroInvoiceUrl !== '') {
      return obj.props.getXeroList(row);
    }
};
const invoiceNumberExportTemplate = (row) => {
    return row.invoiceNumber
};
const invoiceNumberExportCSVTemplate = (row) => {
    return row.invoiceNumber
};

const purchaseInvoiceNumberTemplate = (id, row, col, obj, index) => {
    if (row.quickbookPurchaseOrderUrl && row.quickbookPurchaseOrderUrl !== '') {
        return obj.props.getQBPurchaseList(row);
    } else if (row.xeroPurchaseOrderUrl && row.xeroPurchaseOrderUrl !== '') {
        return obj.props.getXeroPurchaseList(row);
    }
};
const purchaseInvoiceNumberExportTemplate = (row) => {
    return row.invoiceNumber
};
const purchaseInvoiceNumberExportCSVTemplate = (row) => {
    return row.invoiceNumber
};

const expenseInvoiceNumberTemplate = (id, row, col, obj, index) => {
    if (row.quickbookExpenseUrl && row.quickbookExpenseUrl !== '') {
        return obj.props.getQBExpenseList(row);
    } else if (row.xeroExpenseUrl && row.xeroExpenseUrl !== '') {
        return obj.props.getXeroExpenseList(row);
    }
};
const expenseInvoiceNumberExportTemplate = (row) => {
    return row.expenseNumber
};
const expenseInvoiceNumberExportCSVTemplate = (row) => {
    return row.expenseNumber
};

const paymentTermsTemplate = (id, row, col, obj, index) => {
    return (row.customer && row.customer.paymentTerms) ? row.customer.paymentTerms : ''
};
const paymentTermsExportTemplate = (row) => {
    return (row.customer && row.customer.paymentTerms) ? row.customer.paymentTerms : '';
};
const paymentTermsExportCSVTemplate = (row) => {
    return (row.customer && row.customer.paymentTerms) ? row.customer.paymentTerms : '';
};

const invoiceNumberForDeatilsTemplate = (id, row, col, obj, index) => {
    if(obj.props.getBillingType == BillingTypes.Purchase){
        if (row.quickbookPurchaseOrderUrl && row.quickbookPurchaseOrderUrl !== '') {
            return obj.props.getQBPurchaseList(row);
        } else if (row.xeroPurchaseOrderUrl && row.xeroPurchaseOrderUrl !== '') {
            return obj.props.getXeroPurchaseList(row);
        }
    } else if(obj.props.getBillingType == BillingTypes.Expense){
        if (row.quickbookExpenseUrl && row.quickbookExpenseUrl !== '') {
            return obj.props.getQBExpenseList(row);
        } else if (row.xeroExpenseUrl && row.xeroExpenseUrl !== '') {
            return obj.props.getXeroExpenseList(row);
        }
    } else {
        if(row.quickbookInvoiceUrl && row.quickbookInvoiceUrl !== ''){
          return obj.props.getQBList(row);
        } else if (row.xeroInvoiceUrl && row.xeroInvoiceUrl !== '') {
          return obj.props.getXeroList(row);
        }
    }
};

export const childColumnNames = [
    'serviceTag', 
    'chargeCategory.chargeCategoryName', 
    'chargeCategory.billingTypeDisplay', 
    'quantity', 
    'price',  
    'isTaxInclusive', 
    'amount',
];


export const initColumnsInvoice = [
    { 
        id: 'jobId',
        filterKey: 'jobId',
        name: 'jobId',
        label: 'ID',
        visible: false
    },
    { 
        id: 'customer.customerName', 
        filterKey: 'customerName', 
        name: 'customerName', 
        label: 'Customer name',
        width: '150px',
        orderable: true,
        template: customerNameTemplate,
        exportTemplate: customerNameExportTemplate,
        exportCSVTemplate: customerNameExportCSVTemplate,
    },
    { 
        id: 'siteName', 
        filterKey: 'siteName', 
        name: 'siteName', 
        label: 'Site name',
        width: '150px',
        orderable: false,
        template: siteNameTemplate,
        exportTemplate: siteNameExportTemplate,
        exportCSVTemplate: siteNameExportCSVTemplate,
    },
    { 
        id: 'jobTemplateName', 
        filterKey: 'jobTemplateName', 
        name: 'jobTemplateName', 
        label: 'Job type',
        width: '150px',
        orderable: true,
    },
    { 
        id: 'jobStatusName', 
        filterKey: 'jobStatusName', 
        name: 'jobStatusName', 
        label: 'Job status',
        width: '150px',
        orderable: true,
        template: jobStatusNameTemplate,
        exportTemplate: jobStatusNameExportTemplate,
        exportCSVTemplate: jobStatusNameExportCSVTemplate,
    },
    { 
        id: 'jobAttemptCompletedDate', 
        filterKey: 'jobAttemptCompletedDate', 
        name: 'jobDate', 
        label: 'Complete date',
        width: '150px',
        orderable: true,
        template: jobDateTemplate,
        exportTemplate: jobDateExportTemplate,
        exportCSVTemplate: jobDateExportCSVTemplate,
    },
    { 
        id: 'jobNumber', 
        filterKey: 'jobNumber', 
        name: 'jobNumber', 
        label: 'DO #',
        labelClass: 'uppercase-text',
        width: '150px',
        orderable: true,
        template: jobNumberTemplate,
        exportTemplate: jobNumberExportTemplate,
        exportCSVTemplate: jobNumberExportCSVTemplate,
    },
    { 
        id: 'serviceTag', 
        filterKey: 'serviceTag', 
        name: 'serviceTag', 
        label: 'Service name',
        width: '150px',
        orderable: false,
    },
    { 
        id: 'totalPrice', 
        name: 'totalPrice', 
        label: 'Amount',
        width: '150px',
        filterable: false,
        orderable: true,
        template: amountTemplate,
        exportTemplate: amountExportTemplate,
        exportCSVTemplate: amountExportCSVTemplate,
    },
    { 
        id: 'isTaxInclusive', 
        name: 'isTaxInclusive', 
        label: 'Tax inclusive',
        width: '150px',
        filterable: false,
        orderable: true,
        template: taxInclusiveTemplate,
        exportTemplate: taxInclusiveExportTemplate,
        exportCSVTemplate: taxInclusiveExportCSVTemplate,
    },
    { 
        id: 'totalPriceBeforeTax', 
        name: 'totalPriceBeforeTax', 
        label: 'Amount Without Tax',
        width: '200px',
        filterable: false,
        orderable: true,
        template: amountWithoutTaxTemplate,
        exportTemplate: amountWithoutTaxExportTemplate,
        exportCSVTemplate: amountWithoutTaxExportCSVTemplate,
    },
    { 
        id: 'billingStatus', 
        name: 'billingStatus', 
        label: 'Billing status',
        width: '130px',
        filterable: false,
        orderable: true,
        template: billingStatusTemplate,
        exportTemplate: billingStatusExportTemplate,
        exportCSVTemplate: billingStatusExportCSVTemplate,
    },
    { 
        id: 'billedDate', 
        filterKey: 'billedDate', 
        name: 'billedDate', 
        label: 'Billed date',
        width: '150px',
        orderable: true,
        template: billedDateTemplate,
        exportTemplate: billedDateExportTemplate,
        exportCSVTemplate: billedDateExportCSVTemplate,
    },
    { 
        id: 'invoiceDate', 
        filterKey: 'invoiceDate', 
        name: 'invoiceDate', 
        label: 'Invoice date',
        width: '150px',
        orderable: true,
        template: invoiceDateTemplate,
        exportTemplate: invoiceDateExportTemplate,
        exportCSVTemplate: invoiceDateExportCSVTemplate,
    },
    { 
        id: 'invoiceNumber', 
        filterKey: 'invoiceNumber', 
        name: 'invoiceNumber', 
        label: 'Invoice #',
        width: '150px',
        orderable: true,
        template: invoiceNumberTemplate,
        exportTemplate: invoiceNumberExportTemplate,
        exportCSVTemplate: invoiceNumberExportCSVTemplate,
    },
    { 
        id: 'billingProfileName', 
        filterKey: 'billingProfileName', 
        name: 'billingProfileName', 
        label: 'Billing by',
        width: '150px',
        orderable: true,
    },
    { 
        id: 'remarks', 
        filterKey: 'remarks', 
        name: 'remarks', 
        label: 'Job note',
        width: '150px',
        orderable: true,
    },
    { 
        id: 'customer.paymentTerms', 
        filterKey: 'paymentTerms', 
        name: 'customer.paymentTerms', 
        label: 'Payment Term',
        width: '150px',
        orderable: true,
        template: paymentTermsTemplate,
        exportTemplate: paymentTermsExportTemplate,
        exportCSVTemplate: paymentTermsExportCSVTemplate,
    },
];

export const initColumnVisibilityInvoice = [
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
];


export const initColumnsExpense = [
    { 
        id: 'jobId',
        filterKey: 'jobId',
        name: 'jobId',
        label: 'ID',
        visible: false
    },
    { 
        id: 'customer.customerName', 
        filterKey: 'customerName', 
        name: 'customerName', 
        label: 'Customer name',
        width: '150px',
        orderable: true,
        template: customerNameTemplate,
        exportTemplate: customerNameExportTemplate,
        exportCSVTemplate: customerNameExportCSVTemplate,
    },
    { 
        id: 'siteName', 
        filterKey: 'siteName', 
        name: 'siteName', 
        label: 'Site name',
        width: '150px',
        orderable: false,
        template: siteNameTemplate,
        exportTemplate: siteNameExportTemplate,
        exportCSVTemplate: siteNameExportCSVTemplate,
    },
    { 
        id: 'jobTemplateName', 
        filterKey: 'jobTemplateName', 
        name: 'jobTemplateName', 
        label: 'Job type',
        width: '150px',
        orderable: true,
    },
    { 
        id: 'jobStatusName', 
        filterKey: 'jobStatusName', 
        name: 'jobStatusName', 
        label: 'Job status',
        width: '150px',
        orderable: true,
        template: jobStatusNameTemplate,
        exportTemplate: jobStatusNameExportTemplate,
        exportCSVTemplate: jobStatusNameExportCSVTemplate,
    },
    { 
        id: 'jobAttemptCompletedDate', 
        filterKey: 'jobAttemptCompletedDate', 
        name: 'jobDate', 
        label: 'Complete date',
        width: '150px',
        orderable: true,
        template: jobDateTemplate,
        exportTemplate: jobDateExportTemplate,
        exportCSVTemplate: jobDateExportCSVTemplate,
    },
    { 
        id: 'jobNumber', 
        filterKey: 'jobNumber', 
        name: 'jobNumber', 
        label: 'DO #',
        labelClass: 'uppercase-text',
        width: '150px',
        orderable: true,
        template: jobNumberTemplate,
        exportTemplate: jobNumberExportTemplate,
        exportCSVTemplate: jobNumberExportCSVTemplate,
    },
    { 
        id: 'serviceTag', 
        filterKey: 'serviceTag', 
        name: 'serviceTag', 
        label: 'Service name',
        width: '150px',
        orderable: false,
    },
    { 
        id: 'totalPrice', 
        name: 'totalPrice', 
        label: 'Amount',
        width: '150px',
        filterable: false,
        orderable: true,
        template: amountTemplate,
        exportTemplate: amountExportTemplate,
        exportCSVTemplate: amountExportCSVTemplate,
    },
    { 
        id: 'isTaxInclusive', 
        name: 'isTaxInclusive', 
        label: 'Tax inclusive',
        width: '150px',
        filterable: false,
        orderable: true,
        template: taxInclusiveTemplate,
        exportTemplate: taxInclusiveExportTemplate,
        exportCSVTemplate: taxInclusiveExportCSVTemplate,
    },
    { 
        id: 'totalPriceBeforeTax', 
        name: 'totalPriceBeforeTax', 
        label: 'Amount Without Tax',
        width: '200px',
        filterable: false,
        orderable: true,
        template: amountWithoutTaxTemplate,
        exportTemplate: amountWithoutTaxExportTemplate,
        exportCSVTemplate: amountWithoutTaxExportCSVTemplate,
    },
    { 
        id: 'isExpensed', 
        name: 'isExpensed', 
        label: 'Status',
        width: '150px',
        filterable: false,
        orderable: false,
        template: isExpensedTemplate,
        exportTemplate: isExpensedExportTemplate,
        exportCSVTemplate: isExpensedExportCSVTemplate,
    },
    { 
        id: 'billedDate', 
        filterKey: 'billedDate', 
        name: 'billedDate', 
        label: 'Billed date',
        width: '150px',
        orderable: true,
        template: billedDateTemplate,
        exportTemplate: billedDateExportTemplate,
        exportCSVTemplate: billedDateExportCSVTemplate,
    },
    { 
        id: 'expenseDate', 
        filterKey: 'expenseDate', 
        name: 'expenseDate', 
        label: 'Expensed date',
        width: '150px',
        orderable: true,
        template: expenseDateTemplate,
        exportTemplate: expenseDateExportTemplate,
        exportCSVTemplate: expenseDateExportCSVTemplate,
      },
      { 
        id: 'expenseNumber', 
        filterKey: 'expenseNumber', 
        name: 'expenseNumber', 
        label: 'Expensed #',
        width: '150px',
        orderable: true,
        template: expenseInvoiceNumberTemplate,
        exportTemplate: expenseInvoiceNumberExportTemplate,
        exportCSVTemplate: expenseInvoiceNumberExportCSVTemplate,
    },
    { 
        id: 'billingProfileName', 
        filterKey: 'billingProfileName', 
        name: 'billingProfileName', 
        label: 'Billing by',
        width: '150px',
        orderable: true,
    },
    { 
        id: 'remarks', 
        filterKey: 'remarks', 
        name: 'remarks', 
        label: 'Job note',
        width: '150px',
        orderable: true,
    },
    { 
        id: 'customer.paymentTerms', 
        filterKey: 'paymentTerms', 
        name: 'customer.paymentTerms', 
        label: 'Payment Term',
        width: '150px',
        orderable: true,
        template: paymentTermsTemplate,
        exportTemplate: paymentTermsExportTemplate,
        exportCSVTemplate: paymentTermsExportCSVTemplate,
    },
];

export const initColumnVisibilityExpense = [
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
];


export const initColumnsPurchase = [
    { 
        id: 'jobId',
        filterKey: 'jobId',
        name: 'jobId',
        label: 'ID',
        visible: false
    },
    { 
        id: 'customer.customerName', 
        filterKey: 'customerName', 
        name: 'customerName', 
        label: 'Customer name',
        width: '150px',
        orderable: true,
        template: customerNameTemplate,
        exportTemplate: customerNameExportTemplate,
        exportCSVTemplate: customerNameExportCSVTemplate,
    },
    { 
        id: 'siteName', 
        filterKey: 'siteName', 
        name: 'siteName', 
        label: 'Site name',
        width: '150px',
        orderable: false,
        template: siteNameTemplate,
        exportTemplate: siteNameExportTemplate,
        exportCSVTemplate: siteNameExportCSVTemplate,
    },
    { 
        id: 'jobTemplateName', 
        filterKey: 'jobTemplateName', 
        name: 'jobTemplateName', 
        label: 'Job type',
        width: '150px',
        orderable: true,
    },
    { 
        id: 'jobStatusName', 
        filterKey: 'jobStatusName', 
        name: 'jobStatusName', 
        label: 'Job status',
        width: '150px',
        orderable: true,
        template: jobStatusNameTemplate,
        exportTemplate: jobStatusNameExportTemplate,
        exportCSVTemplate: jobStatusNameExportCSVTemplate,
    },
    { 
        id: 'jobAttemptCompletedDate', 
        filterKey: 'jobAttemptCompletedDate', 
        name: 'jobDate', 
        label: 'Complete date',
        width: '150px',
        orderable: true,
        template: jobDateTemplate,
        exportTemplate: jobDateExportTemplate,
        exportCSVTemplate: jobDateExportCSVTemplate,
    },
    { 
        id: 'jobNumber', 
        filterKey: 'jobNumber', 
        name: 'jobNumber', 
        label: 'DO #',
        labelClass: 'uppercase-text',
        width: '150px',
        orderable: true,
        template: jobNumberTemplate,
        exportTemplate: jobNumberExportTemplate,
        exportCSVTemplate: jobNumberExportCSVTemplate,
    },
    { 
        id: 'serviceTag', 
        filterKey: 'serviceTag', 
        name: 'serviceTag', 
        label: 'Service name',
        width: '150px',
        orderable: false,
    },
    { 
        id: 'totalPrice', 
        name: 'totalPrice', 
        label: 'Amount',
        width: '150px',
        filterable: false,
        orderable: true,
        template: amountTemplate,
        exportTemplate: amountExportTemplate,
        exportCSVTemplate: amountExportCSVTemplate,
    },
    { 
        id: 'isTaxInclusive', 
        name: 'isTaxInclusive', 
        label: 'Tax inclusive',
        width: '150px',
        filterable: false,
        orderable: true,
        template: taxInclusiveTemplate,
        exportTemplate: taxInclusiveExportTemplate,
        exportCSVTemplate: taxInclusiveExportCSVTemplate,
    },
    { 
        id: 'totalPriceBeforeTax', 
        name: 'totalPriceBeforeTax', 
        label: 'Amount Without Tax',
        width: '200px',
        filterable: false,
        orderable: true,
        template: amountWithoutTaxTemplate,
        exportTemplate: amountWithoutTaxExportTemplate,
        exportCSVTemplate: amountWithoutTaxExportCSVTemplate,
    },
    { 
        id: 'isPurchaseOrdered', 
        name: 'isPurchaseOrdered', 
        label: 'Status',
        width: '150px',
        filterable: false,
        orderable: false,
        template: isPurchaseOrderedTemplate,
        exportTemplate: isPurchaseOrderedExportTemplate,
        exportCSVTemplate: isPurchaseOrderedExportCSVTemplate,
    },
    { 
        id: 'billedDate', 
        filterKey: 'billedDate', 
        name: 'billedDate', 
        label: 'Billed date',
        width: '150px',
        orderable: true,
        template: billedDateTemplate,
        exportTemplate: billedDateExportTemplate,
        exportCSVTemplate: billedDateExportCSVTemplate,
    },
    { 
        id: 'purchaseOrderDate', 
        filterKey: 'purchaseOrderDate', 
        name: 'purchaseOrderDate', 
        label: 'Purchase date',
        width: '150px',
        orderable: true,
        template: purchaseOrderDateTemplate,
        exportTemplate: purchaseOrderDateExportTemplate,
        exportCSVTemplate: purchaseOrderDateExportCSVTemplate,
      },
      { 
        id: 'purchaseOrderNumber', 
        filterKey: 'purchaseOrderNumber', 
        name: 'purchaseOrderNumber', 
        label: 'Purchase #',
        width: '150px',
        orderable: true,
        template: purchaseInvoiceNumberTemplate,
        exportTemplate: purchaseInvoiceNumberExportTemplate,
        exportCSVTemplate: purchaseInvoiceNumberExportCSVTemplate,
    },
    { 
        id: 'billingProfileName', 
        filterKey: 'billingProfileName', 
        name: 'billingProfileName', 
        label: 'Billing by',
        width: '150px',
        orderable: true,
    },
    { 
        id: 'remarks', 
        filterKey: 'remarks', 
        name: 'remarks', 
        label: 'Job note',
        width: '150px',
        orderable: true,
    },
    { 
        id: 'customer.paymentTerms', 
        filterKey: 'paymentTerms', 
        name: 'customer.paymentTerms', 
        label: 'Payment Term',
        width: '150px',
        orderable: true,
        template: paymentTermsTemplate,
        exportTemplate: paymentTermsExportTemplate,
        exportCSVTemplate: paymentTermsExportCSVTemplate,
    },
];

export const initColumnVisibilityPurchase = [
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
];


export const initColumnDetailsInvoice = [
    { 
        id: 'jobId',
        filterKey: 'jobId',
        name: 'jobId',
        label: 'ID',
        visible: false
    },
    { 
        id: 'billingStatus', 
        name: 'billingStatus', 
        label: 'Billing status',
        width: '150px',
        filterable: false,
        orderable: true,
        template: billingStatusTemplate,
        exportTemplate: billingStatusExportTemplate,
        exportCSVTemplate: billingStatusExportCSVTemplate,
    },
    { 
        id: 'jobAttemptCompletedDate', 
        filterKey: 'jobAttemptCompletedDate', 
        name: 'jobDate', 
        label: 'Complete date',
        width: '250px',
        orderable: true,
        template: jobDateTemplate,
        exportTemplate: jobDateExportTemplate,
        exportCSVTemplate: jobDateExportCSVTemplate,
    },
    { 
        id: 'jobNumber', 
        filterKey: 'jobNumber', 
        name: 'jobNumber', 
        label: 'DO #',
        labelClass: 'uppercase-text',
        width: '200px',
        orderable: true,
        template: jobNumberTemplate,
        exportTemplate: jobNumberExportTemplate,
        exportCSVTemplate: jobNumberExportCSVTemplate,
    },
    { 
        id: 'jobTemplateName', 
        filterKey: 'jobTemplateName', 
        name: 'jobTemplateName', 
        label: 'Job type',
        width: '150px',
        orderable: true,
    },
    { 
        id: 'invoiceDate', 
        filterKey: 'invoiceDate', 
        name: 'invoiceDate', 
        label: 'Invoice date',
        width: '150px',
        orderable: false,
        template: invoiceDateTemplate,
        exportTemplate: invoiceDateExportTemplate,
        exportCSVTemplate: invoiceDateExportCSVTemplate,
    },
    { 
        id: 'invoiceNumber', 
        filterKey: 'invoiceNumber', 
        name: 'invoiceNumber', 
        label: 'Invoice #',
        width: '150px',
        orderable: true,
        template: invoiceNumberTemplate,
        exportTemplate: invoiceNumberExportTemplate,
        exportCSVTemplate: invoiceNumberExportCSVTemplate,
    },
    { 
        id: 'billedDate', 
        filterKey: 'billedDate', 
        name: 'billedDate', 
        label: 'Billed date',
        width: '150px',
        orderable: false,
        template: billedDateTemplate,
        exportTemplate: billedDateExportTemplate,
        exportCSVTemplate: billedDateExportCSVTemplate,
    },
    { 
        id: 'totalPrice', 
        name: 'totalPrice', 
        label: 'Amount',
        width: '150px',
        filterable: false,
        orderable: false,
        template: amountTemplate,
        exportTemplate: amountExportTemplate,
        exportCSVTemplate: amountExportCSVTemplate,
    },
    { 
        id: 'isTaxInclusive', 
        name: 'isTaxInclusive', 
        label: 'Tax inclusive',
        width: '150px',
        filterable: false,
        orderable: false,
        template: taxInclusiveTemplate,
        exportTemplate: taxInclusiveExportTemplate,
        exportCSVTemplate: taxInclusiveExportCSVTemplate,
    },
    { 
        id: 'totalPriceBeforeTax', 
        name: 'totalPriceBeforeTax', 
        label: 'Amount Without Tax',
        width: '200px',
        filterable: false,
        orderable: true,
        template: amountWithoutTaxTemplate,
        exportTemplate: amountWithoutTaxExportTemplate,
        exportCSVTemplate: amountWithoutTaxExportCSVTemplate,
    },
    // { 
    //     id: 'invoiceNumber', 
    //     filterKey: 'invoiceNumber', 
    //     name: 'invoiceNumber', 
    //     label: 'Invoice #',
    //     width: '150px',
    //     orderable: true,
    //     template: invoiceNumberForDeatilsTemplate,
    //     exportTemplate: invoiceNumberExportTemplate,
    //     exportCSVTemplate: invoiceNumberExportCSVTemplate,
    // },
    { 
        id: 'remarks', 
        filterKey: 'remarks', 
        name: 'remarks', 
        label: 'Job note',
        width: '250px',
        orderable: true,
    },
];

export const initColumnVisibilityDetailsInvoice = [
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    // true,
    true,
];


export const initColumnDetailsExpense = [
    { 
        id: 'jobId',
        filterKey: 'jobId',
        name: 'jobId',
        label: 'ID',
        visible: false
    },
    { 
        id: 'billingStatus', 
        name: 'billingStatus', 
        label: 'Billing status',
        width: '150px',
        filterable: false,
        orderable: true,
        template: billingStatusTemplate,
        exportTemplate: billingStatusExportTemplate,
        exportCSVTemplate: billingStatusExportCSVTemplate,
    },
    { 
        id: 'jobAttemptCompletedDate', 
        filterKey: 'jobAttemptCompletedDate', 
        name: 'jobDate', 
        label: 'Complete date',
        width: '250px',
        orderable: true,
        template: jobDateTemplate,
        exportTemplate: jobDateExportTemplate,
        exportCSVTemplate: jobDateExportCSVTemplate,
    },
    { 
        id: 'jobNumber', 
        filterKey: 'jobNumber', 
        name: 'jobNumber', 
        label: 'DO #',
        labelClass: 'uppercase-text',
        width: '200px',
        orderable: true,
        template: jobNumberTemplate,
        exportTemplate: jobNumberExportTemplate,
        exportCSVTemplate: jobNumberExportCSVTemplate,
    },
    { 
        id: 'jobTemplateName', 
        filterKey: 'jobTemplateName', 
        name: 'jobTemplateName', 
        label: 'Job type',
        width: '150px',
        orderable: true,
    },
    { 
        id: 'expenseDate', 
        filterKey: 'expenseDate', 
        name: 'expenseDate', 
        label: 'Expense date',
        width: '150px',
        orderable: false,
        template: expenseDateTemplate,
        exportTemplate: expenseDateExportTemplate,
        exportCSVTemplate: expenseDateExportCSVTemplate,
    },
    { 
      id: 'expenseNumber', 
      filterKey: 'expenseNumber', 
      name: 'expenseNumber', 
      label: 'Expense #',
      width: '150px',
      orderable: true,
      template: expenseInvoiceNumberTemplate,
      exportTemplate: expenseInvoiceNumberExportTemplate,
      exportCSVTemplate: expenseInvoiceNumberExportCSVTemplate,
    },
    { 
        id: 'billedDate', 
        filterKey: 'billedDate', 
        name: 'billedDate', 
        label: 'Billed date',
        width: '150px',
        orderable: false,
        template: billedDateTemplate,
        exportTemplate: billedDateExportTemplate,
        exportCSVTemplate: billedDateExportCSVTemplate,
    },
    { 
        id: 'totalPrice', 
        name: 'totalPrice', 
        label: 'Amount',
        width: '150px',
        filterable: false,
        orderable: false,
        template: amountTemplate,
        exportTemplate: amountExportTemplate,
        exportCSVTemplate: amountExportCSVTemplate,
    },
    { 
        id: 'isTaxInclusive', 
        name: 'isTaxInclusive', 
        label: 'Tax inclusive',
        width: '150px',
        filterable: false,
        orderable: false,
        template: taxInclusiveTemplate,
        exportTemplate: taxInclusiveExportTemplate,
        exportCSVTemplate: taxInclusiveExportCSVTemplate,
    },
    { 
        id: 'totalPriceBeforeTax', 
        name: 'totalPriceBeforeTax', 
        label: 'Amount Without Tax',
        width: '200px',
        filterable: false,
        orderable: true,
        template: amountWithoutTaxTemplate,
        exportTemplate: amountWithoutTaxExportTemplate,
        exportCSVTemplate: amountWithoutTaxExportCSVTemplate,
    },
    // { 
    //     id: 'invoiceNumber', 
    //     filterKey: 'invoiceNumber', 
    //     name: 'invoiceNumber', 
    //     label: 'Invoice #',
    //     width: '150px',
    //     orderable: true,
    //     template: invoiceNumberForDeatilsTemplate,
    //     exportTemplate: invoiceNumberExportTemplate,
    //     exportCSVTemplate: invoiceNumberExportCSVTemplate,
    // },
    { 
        id: 'remarks', 
        filterKey: 'remarks', 
        name: 'remarks', 
        label: 'Job note',
        width: '250px',
        orderable: true,
    },
];

export const initColumnVisibilityDetailsExpense = [
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    // true,
    true,
];


export const initColumnDetailsPurchase = [
    { 
        id: 'jobId',
        filterKey: 'jobId',
        name: 'jobId',
        label: 'ID',
        visible: false
    },
    { 
        id: 'billingStatus', 
        name: 'billingStatus', 
        label: 'Billing status',
        width: '150px',
        filterable: false,
        orderable: true,
        template: billingStatusTemplate,
        exportTemplate: billingStatusExportTemplate,
        exportCSVTemplate: billingStatusExportCSVTemplate,
    },
    { 
        id: 'jobAttemptCompletedDate', 
        filterKey: 'jobAttemptCompletedDate', 
        name: 'jobDate', 
        label: 'Complete date',
        width: '250px',
        orderable: true,
        template: jobDateTemplate,
        exportTemplate: jobDateExportTemplate,
        exportCSVTemplate: jobDateExportCSVTemplate,
    },
    { 
        id: 'jobNumber', 
        filterKey: 'jobNumber', 
        name: 'jobNumber', 
        label: 'DO #',
        labelClass: 'uppercase-text',
        width: '200px',
        orderable: true,
        template: jobNumberTemplate,
        exportTemplate: jobNumberExportTemplate,
        exportCSVTemplate: jobNumberExportCSVTemplate,
    },
    { 
        id: 'jobTemplateName', 
        filterKey: 'jobTemplateName', 
        name: 'jobTemplateName', 
        label: 'Job type',
        width: '150px',
        orderable: true,
    },
    { 
        id: 'invoiceDate', 
        filterKey: 'invoiceDate', 
        name: 'invoiceDate', 
        label: 'Invoice date',
        width: '150px',
        orderable: false,
        template: invoiceDateTemplate,
        exportTemplate: invoiceDateExportTemplate,
        exportCSVTemplate: invoiceDateExportCSVTemplate,
    },
    { 
      id: 'purchaseOrderNumber', 
      filterKey: 'purchaseOrderNumber', 
      name: 'purchaseOrderNumber', 
      label: 'Purchase #',
      width: '150px',
      orderable: true,
      template: purchaseInvoiceNumberTemplate,
      exportTemplate: purchaseInvoiceNumberExportTemplate,
      exportCSVTemplate: purchaseInvoiceNumberExportCSVTemplate,
    },
    { 
        id: 'billedDate', 
        filterKey: 'billedDate', 
        name: 'billedDate', 
        label: 'Billed date',
        width: '150px',
        orderable: false,
        template: billedDateTemplate,
        exportTemplate: billedDateExportTemplate,
        exportCSVTemplate: billedDateExportCSVTemplate,
    },
    { 
        id: 'totalPrice', 
        name: 'totalPrice', 
        label: 'Amount',
        width: '150px',
        filterable: false,
        orderable: false,
        template: amountTemplate,
        exportTemplate: amountExportTemplate,
        exportCSVTemplate: amountExportCSVTemplate,
    },
    { 
        id: 'isTaxInclusive', 
        name: 'isTaxInclusive', 
        label: 'Tax inclusive',
        width: '150px',
        filterable: false,
        orderable: false,
        template: taxInclusiveTemplate,
        exportTemplate: taxInclusiveExportTemplate,
        exportCSVTemplate: taxInclusiveExportCSVTemplate,
    },
    { 
        id: 'totalPriceBeforeTax', 
        name: 'totalPriceBeforeTax', 
        label: 'Amount Without Tax',
        width: '200px',
        filterable: false,
        orderable: true,
        template: amountWithoutTaxTemplate,
        exportTemplate: amountWithoutTaxExportTemplate,
        exportCSVTemplate: amountWithoutTaxExportCSVTemplate,
    },
    // { 
    //     id: 'invoiceNumber', 
    //     filterKey: 'invoiceNumber', 
    //     name: 'invoiceNumber', 
    //     label: 'Invoice #',
    //     width: '150px',
    //     orderable: true,
    //     template: invoiceNumberForDeatilsTemplate,
    //     exportTemplate: invoiceNumberExportTemplate,
    //     exportCSVTemplate: invoiceNumberExportCSVTemplate,
    // },
    { 
        id: 'remarks', 
        filterKey: 'remarks', 
        name: 'remarks', 
        label: 'Job note',
        width: '250px',
        orderable: true,
    },
];

export const initColumnVisibilityDetailsPurchase = [
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    // true,
    true,
];