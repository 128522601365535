/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import randomColor from 'randomcolor';
import moment from 'moment';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
import { ParseResult } from "../../../utils/interfaces";
import { ActiveBinNumberRequestFilter, BinActivity } from '../../../utils/enums'


interface InitState {
  isLoading: boolean,
  isOpen: boolean,

  isLoadingBinCountByType: boolean,
  dataBinCountByType: any,
  totalBinCountByType: number,

  isLoadingActiveInactive: boolean,
  dataActiveInactive: any,

  isLoadingActiveBins: boolean,
  dataActiveBins: any,
  totalActiveBins: number,

  isLoadingInOut: boolean,
  dataInOut: any,

  isLoadingActivitiesBins: boolean,
  dataActivitiesBins: any,
  totalActivitiesBins: number,
}


function NewReducer() {
  const name = 'binCenter';


  const initialState: InitState = {
    isLoading: false,
    isOpen: true,

    isLoadingBinCountByType: true,
    dataBinCountByType: null,
    totalBinCountByType: 0,

    isLoadingActiveInactive: true,
    dataActiveInactive: null,

    isLoadingActiveBins: true,
    dataActiveBins: null,
    totalActiveBins: 0,

    isLoadingInOut: true,
    dataInOut: null,

    isLoadingActivitiesBins: true,
    dataActivitiesBins: null,
    totalActivitiesBins: 0,
  };


  const reducers = {
    setOpen: (state: InitState, action: PayloadAction<any>) => {
      state.isOpen = action.payload;
    },

    startBinCountByType: (state: InitState) => {
      state.isLoadingBinCountByType = true;
    },
    finishBinCountByType: (state: InitState, action: PayloadAction<{ data: any }>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : null;

      let labels = [];
      let total = 0;
      if(data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let item = data[i];

          if(item.quantity > 0){
            total = total + item.quantity;
  
            labels.push({ 
              id: item.binTypeId,
              label: item.binTypeName,
              count: item.quantity,
              backgroundColor: randomColor(),
              isHidden: undefined,
            });
          }
        }
      }
      
      const datasets = {
        labels: labels,
        datasets: [
          {
            data: labels.map(x => x.count),
            backgroundColor: labels.map(x => x.backgroundColor),
            cutout: '75%',
          },
        ],
      };

      state.dataBinCountByType = datasets;
      state.totalBinCountByType = total;
      state.isLoadingBinCountByType = false;
    },

    startActiveInactive: (state: InitState) => {
      state.isLoadingActiveInactive = true;
    },
    finishActiveInactive: (state: InitState, action: PayloadAction<{ data: any }>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : null;

      let dataACTIVE = [];
      let dataINACTIVE = [];
      let totalActive = 0;
      let totalInactive = 0;
      if(data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let item = data[i];
          totalActive = totalActive + item.activeCount;
          totalInactive = totalInactive + item.inactiveCount;

          dataACTIVE.push({ 
            label: item.binTypeName,
            count: [0, item.activeCount]
          });

          dataINACTIVE.push({ 
            label: item.binTypeName,
            count: [0, item.inactiveCount]
          });
        }
      }
          
      const labels = [
        { 
          id: 'IN',
          label: 'IN', 
          count: totalInactive, 
          backgroundColor: '#D9D9D9',
          data: dataINACTIVE,
          isHidden: undefined,
        },
        { 
          id: 'OUT',
          label: 'OUT',
          count: totalActive,
          backgroundColor: '#7000FF',
          data: dataACTIVE,
          isHidden: undefined,
        }, 
      ];

      let datasets = {
        labels: labels[0].data.map((x: any) => x.label),
        datasets: [
            {
                label: labels[0],
                backgroundColor: labels[0].backgroundColor,
                data: labels[0].data.map((x: any) => x.count),
                barThickness: 20,
                stack: 'Stack 0',
            },
            {
                label: labels[1],
                backgroundColor: labels[1].backgroundColor,
                data: labels[1].data.map((x: any) => x.count),
                barThickness: 20,
                stack: 'Stack 0',
            },
        ],
      };


      state.dataActiveInactive = datasets;
      state.isLoadingActiveInactive = false;
    },

    startActiveBins: (state: InitState) => {
      state.isLoadingActiveBins = true;
    },
    finishActiveBins: (state: InitState, action: PayloadAction<{ data: any }>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : null;

      let labels = [];
      let total = 0;
      if(data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let item = data[i];
          total = total + item.count;

          if(item.filter.toString() == ActiveBinNumberRequestFilter.Seven.toString()){
            if(item.count){
              labels.push({
                id: ActiveBinNumberRequestFilter.Seven,
                value: ActiveBinNumberRequestFilter.Seven.toString(),
                label: '0 - 7',
                count: item.count,
                data: [[0, item.count]],
                backgroundColor: '#088F8F',
                isHidden: undefined,
              });
            }

          } else if(item.filter.toString() == ActiveBinNumberRequestFilter.Fourteen.toString()){
            if(item.count){
              labels.push({
                id: ActiveBinNumberRequestFilter.Fourteen,
                value: ActiveBinNumberRequestFilter.Fourteen.toString(),
                label: '8 - 20',
                count: item.count,
                data: [[0, item.count]],
                backgroundColor: '#FFC107',
                isHidden: undefined,
              });
            }

          } else if(item.filter.toString() == ActiveBinNumberRequestFilter.TwentyOne.toString()){
            if(item.count){
              labels.push({
                id: ActiveBinNumberRequestFilter.TwentyOne,
                value: ActiveBinNumberRequestFilter.TwentyOne.toString(),
                label: '21 - 30',
                count: item.count,
                data: [[0, item.count]],
                backgroundColor: '#FD7E14',
                isHidden: undefined,
              });
            }

          } else if(item.filter.toString() == ActiveBinNumberRequestFilter.ThirtyPlus.toString()){
            if(item.count){
              labels.push({
                id: ActiveBinNumberRequestFilter.ThirtyPlus,
                value: ActiveBinNumberRequestFilter.ThirtyPlus.toString(),
                label: '31 - 60',
                count: item.count,
                data: [[0, item.count]],
                backgroundColor: '#DC3545',
                isHidden: undefined,
              });
            }
          } else if(item.filter.toString() == ActiveBinNumberRequestFilter.SixtyPlus.toString()){
            if(item.count){
              labels.push({
                id: ActiveBinNumberRequestFilter.SixtyPlus,
                value: ActiveBinNumberRequestFilter.SixtyPlus.toString(),
                label: '60+',
                count: item.count,
                data: [[0, item.count]],
                backgroundColor: '#7d2029',
                isHidden: undefined,
              });
            }
          }
        }
      }

      let dataSet: any = [];
      if(labels && labels.length > 0){
        dataSet = labels.map((item) => {
          return {
            label: item,
            data: item.data,
            backgroundColor: item.backgroundColor,
            barThickness: 60,
            stack: 'Stack 0',
          }
        });
      }

      let datasets = {
        labels: [''],
        datasets: dataSet,
      };

      state.dataActiveBins = datasets;
      state.totalActiveBins = total;
      state.isLoadingActiveBins = false;
    },

    startInOut: (state: InitState) => {
      state.isLoadingInOut = true;
    },
    finishInOut: (state: InitState, action: PayloadAction<{ data: any }>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : null;

      let dataIN = [];
      let dataOUT = [];
      let totalIn = 0;
      let totalOut = 0;
      if(data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let item = data[i];
          totalIn = totalIn + item.binNumberInCount;
          totalOut = totalOut + item.binNumberOutCount;

          dataIN.push({ 
            label: moment(item.date).format('DD-MM'),
            count: [0, item.binNumberInCount]
          });

          dataOUT.push({ 
            label: moment(item.date).format('DD-MM'),
            count: [0, item.binNumberOutCount]
          });
        }
      }
          
      const labels = [
        { 
            id: BinActivity.IN,
            value: BinActivity.IN.toString(),
            label: 'IN', 
            count: totalIn, 
            backgroundColor: '#D9D9D9',
            data: dataIN,
            isHidden: undefined,
        },
        { 
            id: BinActivity.OUT,
            value: BinActivity.OUT.toString(),
            label: 'OUT',
            count: totalOut,
            backgroundColor: '#185CFF',
            data: dataOUT,
            isHidden: undefined,
        }, 
      ];

      let datasets = {
        labels: labels[0].data.map((x: any) => x.label),
        datasets: [
            {
                label: labels[0],
                backgroundColor: labels[0].backgroundColor,
                data: labels[0].data.map((x: any) => x.count),
                barThickness: 20,
                stack: 'Stack 0',
            },
            {
                label: labels[1],
                backgroundColor: labels[1].backgroundColor,
                data: labels[1].data.map((x: any) => x.count),
                barThickness: 20,
                stack: 'Stack 0',
            },
        ],
      };


      state.dataInOut = datasets;
      state.isLoadingInOut = false;
    },

    startActivitiesBins: (state: InitState) => {
      state.isLoadingActivitiesBins = true;
    },
    finishActivitiesBins: (state: InitState, action: PayloadAction<{ data: any }>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : null;

      let binInCount = (data) ? data.binInCount : 0;
      let binOutCount = (data) ? data.binOutCount : 0;
      let binNeedAttentionCount = (data) ? data.binNeedAttentionCount : 0;

      let total = binInCount + binOutCount + binNeedAttentionCount;

      let labels = [];
      labels.push({
        id: BinActivity.IN,
        value: BinActivity.IN.toString(),
        label: 'CHECK IN',
        count: binInCount,
        data: [[0, binInCount]],
        backgroundColor: '#CFCFCF',
        isHidden: undefined,
      });

      labels.push({
        id: BinActivity.OUT,
        value: BinActivity.OUT.toString(),
        label: 'CHECK OUT',
        count: binOutCount,
        data: [[0, binOutCount]],
        backgroundColor: '#000AFF',
        isHidden: undefined,
      });

      labels.push({
        id: BinActivity.NEED_ATTENTION,
        value: BinActivity.NEED_ATTENTION.toString(),
        label: 'NEEDS ATTENTION',
        count: binNeedAttentionCount,
        data: [[0, binNeedAttentionCount]],
        backgroundColor: '#FF00B8',
        isHidden: undefined,
      });


      let dataSet: any = [];
      if(labels && labels.length > 0){
        dataSet = labels.map((item) => {
          return {
            label: item,
            data: item.data,
            backgroundColor: item.backgroundColor,
            barThickness: 60,
            stack: 'Stack 0',
          }
        });
      }

      let datasets = {
        labels: [''],
        datasets: dataSet,
      };

      state.dataActivitiesBins = datasets;
      state.totalActivitiesBins = total;
      state.isLoadingActivitiesBins = false;
    },

    
    startSave: (state: InitState) => {
      state.isLoading = true;
    },
    finishSave: (state: InitState, action: PayloadAction<{ data: any }>) => {
      state.isLoading = false;
    },

    startRetrie: (state: InitState) => {
      state.isLoading = true;
    },
    finishRetrie: (state: InitState, action: PayloadAction<{ data: any }>) => {
      state.isLoading = false;
    },
    
    startMarkAsCollected: (state: InitState) => {
      state.isLoading = true;
    },
    finishMarkAsCollected: (state: InitState, action: PayloadAction<{ data: any }>) => {
      state.isLoading = false;
    },

    startDelete: (state: InitState) => {
      state.isLoading = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<{ data: any }>) => {
      state.isLoading = false;
    },

    startMerge: (state: InitState) => {
      state.isLoading = true;
    },
    finishMerge: (state: InitState, action: PayloadAction<{ data: any }>) => {
      state.isLoading = false;
    },
    
    startDownloadExcel: (state: InitState) => {
      state.isLoading = true;
    },
    finishDownloadExcel: (state: InitState, action: PayloadAction<{ data: any }>) => {
      state.isLoading = false;
    },
    
    startDeleteDraft: (state: InitState) => {
      state.isLoading = true;
    },
    finishDeleteDraft: (state: InitState, action: PayloadAction<{ data: any }>) => {
      state.isLoading = false;
    },
    
    startActivateDrafts: (state: InitState) => {
      state.isLoading = true;
    },
    finishActivateDrafts: (state: InitState, action: PayloadAction<{ data: any }>) => {
      state.isLoading = false;
    },
  };


  const apis = {
    callBinCountByTypeApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startBinCountByType());

      await dispatchCrudApi.readApi(param, 'bintype').then(result => {
        let data = result.data.data;
        
        callback(true, data);
        dispatch(actions.finishBinCountByType({ data: data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishBinCountByType({ data: null }));
      });
    },

    callActiveInactiveApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startActiveInactive());

      await dispatchCrudApi.readApi(param, 'bin-center/active-bin-count-stats').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishActiveInactive({ data: data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishActiveInactive({ data: null }));
      });
    },

    callActiveBinsApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startActiveBins());

      await dispatchCrudApi.readApi(param, 'bin-center/active-bin-on-site-stats').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishActiveBins({ data: data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishActiveBins({ data: null }));
      });
    },

    callInOutApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startInOut());

      await dispatchCrudApi.createApi(param, 'bin-center/bin-in-out').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishInOut({ data: data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishInOut({ data: null }));
      });
    },

    callActivitiesBinsApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startActivitiesBins());

      await dispatchCrudApi.readApi(param, 'bin-center/live-bin-stat').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishActivitiesBins({ data: data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishActivitiesBins({ data: null }));
      });
    },

    
    callSaveApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startSave());

      await dispatchCrudApi.updateApi(param, 'binnumber/activate').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishSave({ data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishSave({ data: null }));
      });
    },
    callRetrieApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startRetrie());

      await dispatchCrudApi.updateApi(param, 'binnumber/deactivate').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishRetrie({ data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishRetrie({ data: null }));
      });
    },
    callMarkAsCollectedApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startMarkAsCollected());

      await dispatchCrudApi.updateApi(param, 'binnumber/mark-bin-as-collected').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishMarkAsCollected({ data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishMarkAsCollected({ data: null }));
      });
    },
    callDeleteApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDelete());

      await dispatchCrudApi.deleteApi(param, 'binnumber').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishDelete({ data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishDelete({ data: null }));
      });
    },
    callMergeApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startMerge());

      await dispatchCrudApi.createApi(param, 'binnumber/merge-bin').then(result => {
        let data = result.data;
        
        let successMessage = (data && data.successMessage && data.successMessage != '') ? data.successMessage : null;
        if(successMessage != ''){
          Utils.toast(successMessage, 'success');
        }

        callback(true, data);
        dispatch(actions.finishMerge({ data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishMerge({ data: null }));
      });
    },
    callDownloadExcelApi: (param: any, callback: (state: boolean, data: any, type: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDownloadExcel());

      await dispatchCrudApi.readApi(param, 'bin-center/bin-on-site-download-excel').then(result => {
        let data = result.data;
        
        callback(true, data, 'application/vnd.ms-excel');
        dispatch(actions.finishDownloadExcel({ data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null, '');
        dispatch(actions.finishDownloadExcel({ data: null }));
      });
    },
    callDeleteDraftsApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDeleteDraft());

      await dispatchCrudApi.deleteApi(param, 'binnumber/bulk').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishDeleteDraft({ data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishDeleteDraft({ data: null }));
      });
    },
    callActivateDraftsApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startActivateDrafts());

      await dispatchCrudApi.updateApi(param, 'binnumber/activate/bulk').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishActivateDrafts({ data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishActivateDrafts({ data: null }));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();