import React from 'react';
import _ from 'lodash';

import {
  Row,
  Col,
  Form,
  Button,
  Overlay,
  Popover,
} from 'react-bootstrap';
// import styled from 'styled-components';

// import {  } from 'antd';
// import {  } from 'rsuite';
import CurrencyInput from 'react-currency-input-field';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../setup'
import taxRate, { fields } from "../../../../setup/redux/slices/taxRate";

// import { ReactComponent as SearchIcon } from "../../../../_metronic/assets/icons/search.svg";

// import Utils from '../../../utils/utils';
import { AccountSettingType } from '../../../utils/enums';

// ----------------------------------------------------------------------

const FormComponent = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { isLoading, defaultTaxRate } = useSelector((state: RootState) => state.taxRate);

    const [showPopup, setShowPopup] = React.useState<boolean>(false);
    const [targetPopup, setTargetPopup] = React.useState<any>(null);
    const [value, setValue] = React.useState<any>('');
    const refPopup = React.useRef<any>(null);



    const handleClickOutside = (event: any) => {
        if (refPopup && refPopup.current && !refPopup.current.contains(event.target)) {
            setShowPopup(false);
            setTargetPopup(null);
        }
    }


    React.useEffect(() => {
        if (showPopup) {
            dispatch(taxRate.getDefaultTaxRateApi((val: any) => {
                setValue(defaultTaxRate);
            }));
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showPopup]);


    React.useImperativeHandle(
        ref,
        () => ({
            show(e: any, show: boolean) {
                setTargetPopup(e.target);
                setShowPopup(show);
            },
         }),
    );

    const checkIsNotTaxRateBetween0and100 = (value: any) => {
        const numericValue = parseFloat(value || '0');
        if (numericValue >= 0 && numericValue <= 100) {
            return false
        } else {
            return true
        }
    }


    const setFooter = () => {
        return <Row>
            <Col xs={true} className='text-start'>
                <Button
                    variant={'light'}
                    size={'sm'}
                    disabled={isLoading}
                    onClick={(e) => {
                        setShowPopup(false);
                        setTargetPopup(null);
                        setValue('');
                    }}
                >Cancel</Button>
            </Col>
            <Col xs={true} className='text-end'>
                <Button
                    variant={'primary'}
                    size={'sm'}
                    disabled={isLoading}
                    onClick={() => {
                        if(!checkIsNotTaxRateBetween0and100(value)){
                            let params = {
                                type: AccountSettingType.DefaultTaxRate,
                                value: value,
                            }
                            dispatch(taxRate.callSaveDefaultTaxRateApi(params, (state: boolean, data: any) => {
                                if(state){
                                    dispatch(taxRate.setDefaultTaxRate((value && value != '') ? value : ''));
                                }
                            }));

                            setShowPopup(false);
                            setTargetPopup(null);
                        }
                    }}
                >Save</Button>
            </Col>
        </Row>
    }

    const setForm = () => {
        return <Row style={{ gap: '16px' }}>
            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.defaultTaxRate.label}</Form.Label>
                    <Form.Control
                        as = {CurrencyInput}
                        isInvalid={!isLoading && checkIsNotTaxRateBetween0and100(value)}
                        
                        allowDecimals={true}
                        allowNegativeValue={false}
                        disableGroupSeparators={true}
                        suffix={'%'}

                        id={fields.defaultTaxRate.id}
                        placeholder={fields.defaultTaxRate.placeholder}
                        disabled={isLoading}
                        value={isLoading ? '' : value}
                        onValueChange={async (val: any) => {
                            setValue((val && val != '') ? val : '');
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{'Tax rate cannot exceed 100%'}</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
    }


    return <Overlay
        show={showPopup}
        target={targetPopup}
        placement={'bottom'}
    >
        <Popover 
            style={{ width: '210px' }}
        >
            <Popover.Body ref={refPopup}>
                <Row className='p-1'>
                    <Col xs={12}>{setForm()}</Col>
                    <Col xs={12} className='mt-5'>{setFooter()}</Col>
                </Row>
            </Popover.Body>
        </Popover>
    </Overlay>
});

export default FormComponent;
