import React, { Component } from 'react';
import {withRouter} from "react-router-dom"

import apiUtil from '../../../../api/apiUtil.jsx';
import { PagesPermissions } from "../../../../utils/enums";

import { 
  Box,
  Grid,
  ListItem,
  ListItemText,
  Divider,
  List,
} from '@material-ui/core';

import ManageLabel from '../../../../components/OLD/input/ManageLabel.jsx';


class MenuLeftItems extends Component {
    
	constructor(props){
		super(props);

		let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
		let page = props.page ? props.page : '';

		this.state = {
			isLoading: isLoading,
            page: page,
            permissions: [],
		}
	}
    
    
    componentDidMount() {
		this.setPermissions();
    }


    setPermissions = () => {
        const { user } = this.props.auth;

        let permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];
        
        this.setState({
            permissions: permissions
        });
    }

	
	render() {
		return <Box className={'menu-list-items'}>
            {(
                apiUtil.hasPermission(this.state.permissions, PagesPermissions.ReportsBins.read) || 
                apiUtil.hasPermission(this.state.permissions, PagesPermissions.ReportsBinsActivities.read)) && 
            (<Box pb={2}>
                <List 
                    component="nav"
                    subheader={
                        <Grid container>
                            <Box clone pl={2}>
                                <Grid item xs={12} >
                                    <ManageLabel
                                        className={'custom-page-name'}
                                        label={"BINS"}
                                        size={'xs'}
                                    />
                                </Grid>
                            </Box>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    }
                >
                    {apiUtil.hasPermission(this.state.permissions, PagesPermissions.ReportsBins.read) && <ListItem button
                        className={(window.location.pathname.indexOf("/Reports-bins-on-site") !== -1 ? "active" : "")}
                        onClick={() => {
                            this.props.history.push("/Reports-bins-on-site");
                        }}
                    >
                        <ListItemText primary="Bins on sites" />
                    </ListItem>}
                    {apiUtil.hasPermission(this.state.permissions, PagesPermissions.ReportsBinsActivities.read) && <ListItem button
                        className={(window.location.pathname.indexOf("/Reports-bin-activities") !== -1 ? "active" : "")}
                        onClick={(e) => {
                            this.props.history.push("/Reports-bin-activities");
                        }}
                    >
                        <ListItemText primary="Bin activities" />
                    </ListItem>}
                </List>
            </Box>)}

            {(
                apiUtil.hasPermission(this.state.permissions, PagesPermissions.ReportsCollectionSummary.read) ||
                apiUtil.hasPermission(this.state.permissions, PagesPermissions.ReportsCollectionDetailed.read) ||
                apiUtil.hasPermission(this.state.permissions, PagesPermissions.ReportsPurchaseDetailed.read)
            ) && 
            (<Box pb={2}>
                <List 
                    component="nav"
                    subheader={
                        <Grid container>
                            <Box clone pl={2}>
                                <Grid item xs={12} >
                                    <ManageLabel
                                        className={'custom-page-name'}
                                        label={"WASTE"}
                                        size={'xs'}
                                    />
                                </Grid>
                            </Box>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    }
                >
                    {apiUtil.hasPermission(this.state.permissions, PagesPermissions.ReportsCollectionSummary.read) && <ListItem button
                        className={(window.location.pathname.indexOf("/Reports-waste-collections-summary") !== -1 ? "active" : "")}
                        onClick={() => {
                            this.props.history.push("/Reports-waste-collections-summary");
                        }}
                    >
                        <ListItemText primary="Collections report (Summary)" />
                    </ListItem>}
                    {apiUtil.hasPermission(this.state.permissions, PagesPermissions.ReportsCollectionDetailed.read) && <ListItem button
                        className={(window.location.pathname.indexOf("/Reports-waste-collections-detailed") !== -1 ? "active" : "")}
                        onClick={(e) => {
                            this.props.history.push("/Reports-waste-collections-detailed");
                        }}
                    >
                        <ListItemText primary="Collections report (Detailed)" />
                    </ListItem>}
                    {apiUtil.hasPermission(this.state.permissions, PagesPermissions.ReportsPurchaseDetailed.read) && <ListItem button
                        className={(window.location.pathname.indexOf("/Reports-waste-purchase-detailed") !== -1 ? "active" : "")}
                        onClick={(e) => {
                            this.props.history.push("/Reports-waste-purchase-detailed");
                        }}
                    >
                        <ListItemText primary="Purchase report (Detailed)" />
                    </ListItem>}
                   
                </List>
            </Box>)}

            {(
                apiUtil.hasPermission(this.state.permissions, PagesPermissions.ReportsDriverTripPay.read)
            ) && 
            (<Box pb={2}>
                <List 
                    component="nav"
                    subheader={
                        <Grid container>
                            <Box clone pl={2}>
                                <Grid item xs={12} >
                                    <ManageLabel
                                        className={'custom-page-name'}
                                        label={"BUSINESS OVERVIEW"}
                                        size={'xs'}
                                    />
                                </Grid>
                            </Box>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    }
                >
                    {apiUtil.hasPermission(this.state.permissions, PagesPermissions.ReportsDriverTripPay.read) && <ListItem button
                        className={(window.location.pathname.indexOf("/Reports-driver-trip-pay") !== -1 ? "active" : "")}
                        onClick={(e) => {
                            this.props.history.push("/Reports-driver-trip-pay");
                        }}
                    >
                        <ListItemText primary="Driver trip pay" />
                    </ListItem>}
                </List>
            </Box>)}

            {(
                apiUtil.hasPermission(this.state.permissions, PagesPermissions.ReportsCustomerServices.read)
            ) && 
            (<Box>
                <List 
                    component="nav"
                    subheader={
                        <Grid container>
                            <Box clone pl={2}>
                                <Grid item xs={12} >
                                    <ManageLabel
                                        className={'custom-page-name'}
                                        label={"SERVICES"}
                                        size={'xs'}
                                    />
                                </Grid>
                            </Box>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    }
                >
                    {apiUtil.hasPermission(this.state.permissions, PagesPermissions.ReportsCustomerServices.read) && <ListItem button
                        className={(window.location.pathname.indexOf("/Reports-customer-services") !== -1 ? "active" : "")}
                        onClick={(e) => {
                            this.props.history.push("/Reports-customer-services");
                        }}
                    >
                        <ListItemText primary="Price list" />
                    </ListItem>}
                </List>
            </Box>)}

		</Box>;
	}
}

export default withRouter(MenuLeftItems);
