import React from 'react';
import { Form } from "react-bootstrap-v5";
import { usePhoneInput } from 'react-international-phone';
import Utils from '../../utils/utils';

const PhoneField = ({ value, onChange, ...restProps }) => {
    const { inputValue, handlePhoneValueChange, inputRef } = usePhoneInput({
        defaultCountry: Utils.getCountryCode().toLowerCase(),
        // disableFormatting: true,
        // charAfterDialCode: '',

        value: (value && value != '') ? value : '',
        onChange: (data) => {
          onChange(data.phone);
        },
    });
  
    return (
        <Form.Control
            type="tel"
            value={inputValue}
            onChange={handlePhoneValueChange}
            inputRef={inputRef}
            {...restProps}
        />
    );
}

export default PhoneField;