import React from 'react'
import styled from 'styled-components'
import { ProgressBar } from "react-bootstrap-v5";

import ProgressOverlay from "../../components/LoadingOverlay/ProgressOverlay";

import { useDispatch, useSelector } from "react-redux";
// import signalRslice from "./signalRslice";


const Styles = styled.div`
  width: 80vw;

  @media only screen and (min-width: 768px) {
    width: 40vw;
  }
`;


const ProgressBarPopup: React.FC<any> = React.forwardRef((props, ref) => {
  // const dispatch = useDispatch();
  const { percentage, progressMessage } = useSelector((state) => state.signalRslice);

  const PLEASE_WAIT_TEXT = 'Please Wait...';

  const [loading, setLoading] = React.useState(false);
  const [percent, setPercent] = React.useState(0);
  const [text, setText] = React.useState((progressMessage && progressMessage != '') ? progressMessage : PLEASE_WAIT_TEXT);


  React.useEffect(() => {
    if (typeof percentage === 'number') {
      setLoading(true);
      setPercent(percentage);
      setText((progressMessage && progressMessage != '') ? progressMessage : PLEASE_WAIT_TEXT);
    } else {
      setLoading(false);
      setPercent(0);
      setText(PLEASE_WAIT_TEXT);
    }
  }, [percentage]);

  React.useEffect(() => {
    setText((progressMessage && progressMessage != '') ? progressMessage : PLEASE_WAIT_TEXT);
  }, [progressMessage]);


  return <div>
    <ProgressOverlay active={loading}>
      <Styles>
        <h3 style={{ color: 'white' }}>{text} ({percent}%)</h3>
        <ProgressBar min={0} max={100} now={percent} />
      </Styles>
    </ProgressOverlay>
  </div>
});

export default ProgressBarPopup
