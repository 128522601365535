/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import axios from 'axios';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import _ from'lodash';
import Tinycon from 'tinycon';
import scrollToElement from'scroll-to-element';
import { PagesPermissions, JobStepType, JobStepBinActivity, AccountDocSettingVariables, StepType, StepAddress, UserCookieSettingType, PageCookieSettingType, AuditTrailObjectTypeText } from '../utils/enums';

import Cookies from 'js-cookie'
import lzutf8 from 'lzutf8'

import userCookieSettingApi from "../api/CommonProfile/UserCookieSetting.jsx";

//TODO OLD file

const moment = extendMoment(Moment);


const { REACT_APP_IS_PRODUCTION } = process.env;


const apiUtil = {
    getBaseUrl: () => {
        return window.location.protocol + '//' + window.location.host;
    },

    isProduction: () => {
        return (REACT_APP_IS_PRODUCTION === 'true') ? true : false;
    },

    getUserInfo: (user = '') => {
        let userObj = null;
    
        if(user && user !== ''){
          try {
            userObj = JSON.parse(user);
          } catch(e){}
        }
    
        return userObj;
    },
    getUserInfoForBugsnagUser: () => {
        let user = window.localStorage.getItem("user") || null;

        let obj = null;
        let item = apiUtil.getUserInfo(user);
        if(item){
            let userId = (item && item.userId) ? item.userId : 0;
            let name = (item && item.name) ? item.name : '';
            let email = (item && item.email) ? item.email : '';

            obj = {
                id: userId,
                name: name,
                email: email
            };
        }
        
        return obj;
    },
    getUserInfoForBugsnagCompany: () => {
        let user = window.localStorage.getItem("user") || null;

        let obj = null;
        let item = apiUtil.getUserInfo(user);
        if(item){
            let userAccount = (item && item.userAccount) ? item.userAccount : null;
            if(userAccount){
                let accountId = (userAccount && userAccount.accountId) ? userAccount.accountId : 0;
                let accountName = (userAccount && userAccount.accountName) ? userAccount.accountName : '';
                let accountEmail = (userAccount && userAccount.accountEmail) ? userAccount.accountEmail : '';

                obj = {
                    id: accountId,
                    name: accountName,
                    email: accountEmail
                };
            }
        }
        
        return obj;
    },

    getUserTimezoneUtcOffset: () => {
        let userInfo = JSON.parse(localStorage.getItem("user"));
        let userAccount = (userInfo && userInfo.userAccount) ? userInfo.userAccount : null;
        
        return userAccount.timezoneUtcOffset;
    },
    getUtcDateFromSettings: () => {
        return moment.utc().add(apiUtil.getUserTimezoneUtcOffset(), "minutes").format();
    },

    rndChars(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for(var i = 0; i < length; i++) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    },

    generateFileName: (path = '', fileName = '') => {
        let pathArr = path.split('/');
    
        let generatedFileName = '';
        if(pathArr && pathArr.length > 0){
            for(let i = 0; i < pathArr.length; i++) {
                generatedFileName += ((i === 0) ? '' : '-') + pathArr[i];
            }
        }
        
        generatedFileName += moment().format('YYYYMMDD');
        generatedFileName += apiUtil.rndChars(6);
        generatedFileName += '-' + fileName;

        return generatedFileName;
    },

    getCancelToken: getToken => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        return source;
    },
    cookieParse: cookie => {
        return cookie.split(/[;] */).reduce(function(result, pairStr) {
            var arr = pairStr.split('=');
            if (arr.length === 2) { result[arr[0]] = arr[1]; }
            return result;
        }, {});
    },

    reorder(list, startIndex, endIndex) {
        const items = Array.from(list);
        const [reorderedItem] = items.splice(startIndex, 1);
        items.splice(endIndex, 0, reorderedItem);
        return items
    },

    compareArrays(array1, array2) {
        try {
            return array1.every(i => array2.includes(i));
        } catch(err) {
            return false;
        }
    },
    isInArray(array, value) {
        return array.indexOf(value) > -1;
    },
    addToArray(array, value) {
        if(array.indexOf(value) === -1){
            array.push(value);
        }
        return array;
    },
    removeFromArray(array, value) {
        let index = array.indexOf(value);
        if (index > -1) {
            array.splice(index, 1);
        }
        return array;
    },
    
    isEqual(obj1, obj2) {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    isEmptyField(data, field) {
        return (data) ? (data[field] === '') ? true : false : false;
    },
    isEmptyRow(obj, fields) {
        try {
            return !fields.map((f) => {
                return obj[f] === '' ? true : false
            }).some(e => e === false);
        } catch(err) {}
    },
    hasDuplicatedObjects(array = [], fields = []) {
        return array.map((value) => {
            return fields.map((f, i) => {
                return value[f]
            }).join('')
        }).some((value, index, array) => { 
            return array.indexOf(value) !== array.lastIndexOf(value);  
        })
    },
    hasDuplicatedObjectsArray(array = [], fields = []) {
        return array.map((value) => {
            return fields.map((f, i) => {
                if(Array.isArray(value[f])){
                    return value[f].sort((a, b) => a - b)
                } else {
                    return value[f]
                }
            }).join('')
        }).some((value, index, array) => { 
            return array.indexOf(value) !== array.lastIndexOf(value);  
        })
    },
    hasDuplicatedValue(array = [], fields = [], texts = []) {
        let status = false;
        if(texts && texts.length > 0) {
            for(let t = 0; t < texts.length; t++){
                let text = texts[t];
                
                if(fields && fields.length > 0) {
                    for(let f = 0; f < fields.length; f++){
                        let field = fields[f];
                        
                        let count = array.filter(x => x[field] === text).length;
                        if(count > 1){
                            status = true;
                            break;
                        }
                    }
                }

            }
        }
        return status;
    },
    hasDuplicated(array = [], fields = [], texts = []) {
        let status = false;
        if(texts && texts.length > 0) {
            for(let t = 0; t < texts.length; t++){
                let text = texts[t];
                
                if(fields && fields.length > 0) {
                    for(let f = 0; f < fields.length; f++){
                        let field = fields[f];
                        
                        let count = array.filter(x => x[field].toString().toLowerCase() === text.toString().toLowerCase()).length;
                        if(count > 0){
                            status = true;
                            break;
                        }
                    }
                }

            }
        }
        return status;
    },
    removeEmptyRows(array = [], fields = []) {
        return array.map((value) => {
            let row = fields.map((f) => {
                return value[f]
            }).filter((e) => {
                return e === 0 ? '0' : e
            });
            return (row && row.length > 0) ? value : null;
        }).filter(x => x !== null)
    },
    
    getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    },
    filterByAll(array, value) {
        return array.filter(o => Object.keys(o).some(k => o[k].toString().toLowerCase().includes(value.toString().toLowerCase())));
    },
    filterByParam(array, param, value) {
        return array.filter(o => Object.keys(o).some(k => o[param].toString().toLowerCase().includes(value.toString().toLowerCase())));
    },
    filterByExactParam(array, param, value) {
        return array.filter(o => Object.keys(o).some(k => o[param].toString().toLowerCase() === value.toString().toLowerCase()));
    },
    filterByParams(array, params, value) {
        return array.filter(o => Object.keys(o).some(k => {
            for(let i = 0; i < params.length; i++){
                let param = params[i];

                if(o[param]){
                    return o[param].toString().toLowerCase().includes(value.toString().toLowerCase());
                }
            }
        }));
    },
    getIndexOfArray(array, param, value) {
        if(array && array.length > 0){
            let index = 0;

            for(var i = 0; i < array.length; i++){
                if(array[i][param] === value){
                    index = i;
                    break;
                }
            }

            return index;
        } else {
            return null;
        }
    },
    getArrayDistinct(array, param) {
        const result = [];
        if(array && array.length > 0){
            const map = new Map();
            for (const item of array) {
                if(!map.has(item[param])){
                    map.set(item[param], true);
                    result.push(item);
                }
            }
        }
        return result;
    },

    validateLatLng(lat, lng) {    
        let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
        
        return pattern.test(lat) && pattern.test(lng);
    },
    validateCoordinates(coordinates) {    
        let args = coordinates.split(',');

        let lat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
        let lng = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
        
        return (lat.test(args[0].trim()) == true && lng.test(args[1].trim()) == true)
    },

    dateRegex(result) {
        try {
            return result.replace(/new (D|d)ate\(|([*^]-?[\d]+)|\)*/g, '');
        } catch (ex) {
            return null;
        }
    },

    formatString: (str = "") => {
        try {
            var regExpr = /[^a-zA-Z0-9]/g;
            return str.toLowerCase().replace(regExpr, "");
        } catch (ex) {
            return str.toLowerCase();
        }
    },

    getMyLocation(callback = null) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                if(callback !== null)
                    callback(position.coords);
            });
        } else {
            if(callback !== null)
                callback(null);
        }
    },
    getFromAddress(components, type) {
        return components.filter((component) => component.types.indexOf(type) === 0).map((item) => item.long_name).pop() || null;
    },

    isSmallScreen(width = 992) {
        return (window.innerWidth <= width) ? true : false;
    },

    bytesToSizeWithDecimal(bytes) {
        let units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        let l = 0, n = parseInt(bytes, 10) || 0;

        while(n >= 1024 && ++l){
            n = n/1024;
        }
        
        return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    },
    bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },
    mToKm(m) {
        var km = m / 1000;
        return km.toFixed(1) + " km"
    },

    saveByteArray(reportName, byte, type = "application/pdf") {
        let byteArray = apiUtil.getUint8Array(byte);
        let blob = new Blob([byteArray], { type: type });
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', reportName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    },

    printByteArray(title, html) {
        window.localStorage.setItem('print_' + title, html);

        const link = document.createElement('a');
        link.href = '/Report-Iframe?title=' + title;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
    },

    async getTemplateFromFile(fileName) {
        return await axios.get(fileName);
    },


    escapeChar(e = null, char = null) {
        if(e && char){
            if(char === e.key) {
                e.preventDefault();
                e.stopPropagation();
                return true;
            }
        }

        return false;
    },

    escapeChars(e = null) {
        let characters = [ '\\', '/', ':', '*', '?', '"', '<', '>', '|', ];

        if(e){
            if(characters.includes(e.key)) {
                return apiUtil.escapeChar(e, e.key);
            }
        }

        return false;
    },

    firstLetterToUpperCase(s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    },
    firstLetterToLowerCase(s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toLowerCase() + s.slice(1)
    },
    
    parseFloat(x) {
        if(x === 0 || x === "0" || x === "0.00"){
            return 0;
        } else {
            return isNaN(parseFloat(x)) ? parseFloat(0) : parseFloat(x);
        }
	},
    customParseFloat(x) {
        let numb = 0;

        try {
            numb = isNaN(parseFloat(x)) ? 0 : parseFloat(x);
        } catch(e){}

        return numb;
	},
    customParseInt(x) {
        let numb = 0;

        try {
            numb = isNaN(parseInt(x)) ? 0 : parseInt(x);
        } catch(e){}

        return numb;
	},

    parseResult(result = null, success = null, error = null, error500 = null) {
        if(result.status === 400) {
            if(error) {
                if(!result || !result.errorMessage || !result.errorMessage.error || result.errorMessage.error === ''){
                    if(result && result.errorMessage && result.errorMessage.NeedForceDelete){
                        error(result.errorMessage.Error, 'error', [], true);
                    } else {
                        error('The application has encountered an unknown error!', 'warning', null, false);
                    }
                } else if(result && result.errorMessage && result.errorMessage.error && result.errorMessage.error !== ''){
                    error(result.errorMessage.error, 'error', [], false);
                } else {
                    error('Unknown error occurred. Please try again', 'warning', null, false);
                }
            }
            return;
        } else if (result.status === 500) {
            if(error500){
                error500();
            } else {
                window.location.href = '/error-500';
            }
            return;
        }
        
		if(result.errorMessage === null || result.errorMessage === ''){
            if(result && result.data && result.data.data){
                let data = result.data.data;

                if(success){
                    success(data, 'success', null);
                }
            } else if(result && result.data && result.data.data === ''){
                if(success){
                    success('', 'success', null);
                }
            } else {
                if(error){
                    error('An error occurred, please try again!', 'warning', null, false);
                }
            }
        } else {
            if(typeof result.errorMessage === 'string'){
                if(error){
                    error(result.errorMessage, 'error', null, false);
                }
            } else {
                if(result.errorMessage.errors){
                    if(result.errorMessage.errors && (typeof result.errorMessage.errors === 'object')){
                        let errorsArr = [];
                        let errArr = Object.keys(result.errorMessage.errors).map((err, i) => {
                            errorsArr = {
                                field: err,
                                error: result.errorMessage.errors[err][0]
                            };
                            return result.errorMessage.errors[err][0];
                        });
                        
                        if(error){
                            error(errArr, 'error', errorsArr, false);
                        }
                    } else {
    
                        let errorsArr = [];
                        let errArr = Object.keys(result.errorMessage).map((err, i) => {
                            errorsArr = {
                                field: err,
                                error: result.errorMessage.errors[err][0]
                            };
                            return result.errorMessage[err];
                        });
                        
                        if(error){
                            error(errArr, 'error', errorsArr, false);
                        }
                    }
                } else {
                    if(result.errorMessage.error) {
                        if(error){
                            error(result.errorMessage.error, 'error', [], false);
                        }
                    } else {
                        if(error){
                            error('Error', 'error', [], false);
                        }
                    }
                }
            }
        }
    },
    
    loopObj(obj = null, callback = null) {
        if(obj !== null){
            for (let [key, value] of Object.entries(obj)) {
                if(callback !== null){
                    callback(key, value);
                }
            }
        }
    },
    objToArr(obj = null) {
        let arr = [];
        if(obj !== null){
            for (let [key, value] of Object.entries(obj)) {
                arr.push({
                    key: key,
                    value: value
                });
            }
        }
        return arr;
    },
    
    copyObj(obj = null) {
        if(obj !== null){
            try {
                return Object.assign({}, obj);
            } catch(e) {
                return null;
            }
        } else {
            return null;
        }
    },
    copyArr(obj = null) {
        if(obj !== null){
            try {
                return Object.assign([], obj);
            } catch(e) {
                return null;
            }
        } else {
            return null;
        }
    },

    focusElement(key) {
        try {
            if(key && key !== ''){
                document.getElementById(key + '-id').focus();
            }
        } catch(err){}
	},
    formValidation(form) {
        const formData = new FormData(form)
        const validationMessages = Array
        .from(formData.keys())
        .reduce((acc, key) => {
          acc[key] = form.elements[key].validationMessage
          return acc
        }, {})
		return validationMessages;
    },
    formValidate(form, focus = true) {
        const validationMessages = this.formValidation(form);
        const validationMessagesArr = apiUtil.objToArr(validationMessages);
        
        if(validationMessagesArr && validationMessagesArr.length > 0){
            for(let i = 0; i < validationMessagesArr.length; i++){
                if(validationMessagesArr[i].value !== ''){
                    if(focus){
                        this.focusElement(validationMessagesArr[i].key);
                    }
                    return validationMessagesArr[i]
                }
            }

            return null;
        } else {
            return null;
        }
    },
    passwordValidation(str, regex = null) {
        if(regex === null){
            // Minimum eight characters, at least one letter, one number and one special character
            return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}/g.test(str);
        } else {
            return regex.test(str);
        }
    },
    passwordValidationSimple(str) {
        // The password must contain at least six letters or numbers
        return /[A-Za-z\d@$!%*#?&]{6,}/g.test(str);
    },

    emailValidation() {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },

    numberValidation() {
        return /^\d{0,}$/g;
    },

    character50Value(){
        return /^[\d\w\W]{0,50}$/g;
    },

    numberValidation10() {
        return /^\d{0,10}$/g;
    },
    decimalValidation() {
        // return /^(?:[-.]?)?(?:\d{1,2})?(?:[.,]\d{1,3})?$/gm; // Validate positive and negative two-digit numbers with 3 decimal places
        return /^(?:\d{1,5})?(?:[.,]\d{1,3})?$/g; // Validate only positive two-digit numbers with 3 decimal places
    },
    decimalValidationInputText() {
        return /^(\d{0,5})?(?:[.,]+\d{0,3})?$/g; // Validate only positive two-digit numbers with 3 decimal places and dot
    },
    decimalValidation150() {
        return /^\d(^[0-9]?(?:[.,]+\d{0,1})?$|^10$)|(^[0-9][0-9]?(?:[.,]+\d{0,1})?$|^100$)|(^[0-9][0-4][0-9]?(?:[.,]+\d{0,1})?$|^150$)+?$/gm; // Validate payroll slider range 1-150 with decimal
    },
    decimalValidationMustHaveDot() {
        return /^(\d{0,5})?(?:[.,]+\d{1,1})?$/g; // Validate only positive two-digit numbers with 1 decimal places and dot
    },
    character3Validation() {
        return /^[\d\w\W]{0,3}$/g;
    },
    decimal3Validation() {
        // return /^\d+(?:[.,]\d{1,3})?$/g;
        return /^(?:\d{1,2}||^$)+(?:[.,]\d{0,3})?$/g;
    },
    character6Validation(){
        return /^[\d\w\W^-]{0,6}$/g;
    },
    character6AndDashAtTheEndValidation(){
        // return /^(^[a-z0-9A-Z]{0,6}$)|((?!^-)(^[a-z0-9A-Z]{0,5})(-$))$/g;
        return /^[a-z0-9A-Z-]{0,6}$/g;
    },
    removeDashAtTheEndOfString(){
        return /[-]+$/g;
    },
    maxInputNumber012() {
        return /^[012]$/g;
    },
    maxInputNumber12() {
        return /^[12]$/g;
    },
    
    
    getAuditTrailLabel(type) {
        if(type != null){
            return AuditTrailObjectTypeText[type];
        } else {
            return '';
        }
    },


    getJobTypeInfoByText(jobType) {
        if(jobType === "Pull") {
            return JobStepType.Pull.toString();
        } else if (jobType === "Put") {
            return JobStepType.Put.toString();
        } else if (jobType === "Task") {
            return JobStepType.Task.toString();
        } else if (jobType === "Exchange") {
            return JobStepType.Exchange.toString();
        } else if (jobType === "On The Spot") {
            return JobStepType.OnTheSpot.toString();
        } else if (jobType === "Out") {
            return JobStepType.Out.toString();
        } else if (jobType === "Shift") {
            return JobStepType.Shift.toString();
        } else if (jobType === "Throw At Customer") {
            return JobStepType.ThrowAtCustomer.toString();
        } else {
            return "";
        }
    },
    getJobTypeInfo(jobType) {
        if(jobType === JobStepType.Pull.toString()) {
            return "Pull";
        } else if (jobType === JobStepType.Put.toString()) {
            return "Put";
        } else if (jobType === JobStepType.Task.toString()) {
            return "Task";
        } else if (jobType === JobStepType.Exchange.toString()) {
            return "Exchange";
        } else if (jobType === JobStepType.OnTheSpot.toString()) {
            return "On The Spot";
        } else if (jobType === JobStepType.Out.toString()) {
            return "Out";
        } else if (jobType === JobStepType.Shift.toString()) {
            return "Shift";
        } else if (jobType === JobStepType.ThrowAtCustomer.toString()) {
            return "Throw At Customer";
        } else {
            return "";
        }
    },

    getInOut(stepType, wasteBinType) {
		if(stepType === JobStepType.Put){
			return "OUT";
		} else if (stepType === JobStepType.Pull) {
			return "IN";
		} else if (stepType === JobStepType.Exchange) {
			if(wasteBinType === 1) {
				return "OUT";
			} else {
				return "IN";
			}
		} else if (stepType === JobStepType.OnTheSpot) {
			return "";
		} else if (stepType === JobStepType.Out) {
			return "OUT";
		} else if (stepType === JobStepType.Shift) {
			return "OUT";
		} else if (stepType === JobStepType.ThrowAtCustomer) {
			return "";
		} else {
			return "";
		}
    },
    getBinActivity(step, jobType) {
		if(jobType === JobStepType.Put){
            if(step === 1){
                return JobStepBinActivity.LoadBin;
            } else if(step === 2){
                return JobStepBinActivity.PutBin;
            } else if(step === 3){
                return JobStepBinActivity.NoActivity;
            } else {
                return null;
            }
		} else if (jobType === JobStepType.Task) {
			if(step === 1){
                return JobStepBinActivity.NoActivity;
            } else if(step === 2){
                return JobStepBinActivity.NoActivity;
            } else if(step === 3){
                return JobStepBinActivity.NoActivity;
            } else {
                return null;
            }
		} else if (jobType === JobStepType.Pull) {
			if(step === 1){
                return JobStepBinActivity.LoadBin;
            } else if(step === 2){
                return JobStepBinActivity.PutBin;
            } else if(step === 3){
                return JobStepBinActivity.NoActivity;
            } else {
                return null;
            }
		} else if (jobType === JobStepType.Exchange) {
			if(step === 1){
                return JobStepBinActivity.LoadBin;
            } else if(step === 2){
                return JobStepBinActivity.ExchangeBin;
            } else if(step === 3){
                return JobStepBinActivity.PutBin;
            } else {
                return null;
            }
		} else if (jobType === JobStepType.OnTheSpot) {
			if(step === 1){
                return JobStepBinActivity.LoadBin;
            } else if(step === 2){
                return JobStepBinActivity.NoActivity;
            } else if(step === 3){
                return JobStepBinActivity.PutBin;
            } else {
                return null;
            }
		} else if (jobType === JobStepType.Out) {
			if(step === 1){
                return JobStepBinActivity.LoadBin;
            } else if(step === 2){
                return JobStepBinActivity.PutBin;
            } else if(step === 3){
                return JobStepBinActivity.NoActivity;
            } else {
                return null;
            }
		} else if (jobType === JobStepType.Shift) {
			if(step === 1){
                return JobStepBinActivity.LoadBin;
            } else if(step === 2){
                return JobStepBinActivity.PutBin;
            } else if(step === 3){
                return JobStepBinActivity.NoActivity;
            } else {
                return null;
            }
		} else if (jobType === JobStepType.ThrowAtCustomer) {
			if(step === 1){
                return JobStepBinActivity.LoadBin;
            } else if(step === 2){
                return JobStepBinActivity.NoActivity;
            } else if(step === 3){
                return JobStepBinActivity.PutBin;
            } else {
                return null;
            }
		} else {
			return null;
		}
    },
    getStepStruct(jobT) {
        // stepTypeDraw - if it is NONE it will not be displayed in form
        
        let jobType = jobT;
        try {
            jobType = parseInt(jobT);
        } catch(e){}

		if(jobType === JobStepType.Put){
            return [ 
                {
                    jobType: JobStepType.Put,
                    stepTypeDraw: StepType.BIN_OUT,
                    stepTypeSave: StepType.BIN_OUT,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.LoadBin,
                    stepTitle: 'Put',
                    stepDescription: 'Deliver Bin to Site',
                    stepName: 'Start',
                    binCount: -1,
                },
                {
                    jobType: JobStepType.Put,
                    stepTypeDraw: StepType.BIN_EXCHANGE,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.PutBin,
                    stepTitle: 'Put',
                    stepDescription: 'Deliver Bin to Site',
                    stepName: 'Complete',
                    binCount: 1,
                }
            ];
		} else if (jobType === JobStepType.Pull) {
            return [ 
                {
                    jobType: JobStepType.Pull,
                    stepTypeDraw: StepType.BIN_EXCHANGE,
                    stepTypeSave: StepType.BIN_IN,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.LoadBin,
                    stepTitle: 'Pull',
                    stepDescription: 'Collect bin on site',
                    stepName: 'Start',
                    binCount: -1,
                },
                {
                    jobType: JobStepType.Pull,
                    stepTypeDraw: StepType.BIN_IN,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.PutBin,
                    stepTitle: 'Pull',
                    stepDescription: 'Collect bin on site',
                    stepName: 'Complete',
                    binCount: 1,
                }
            ];
		} else if (jobType === JobStepType.Exchange) {
            return [ 
                {
                    jobType: JobStepType.Exchange,
                    stepTypeDraw: StepType.BIN_OUT,
                    stepTypeSave: StepType.BIN_OUT,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.LoadBin,
                    stepTitle: 'Exchange',
                    stepDescription: 'Deliver Bin to Site & Collect Bin from Site',
                    stepName: 'Start',
                    binCount: -1,
                },
                {
                    jobType: JobStepType.Exchange,
                    stepTypeDraw: StepType.BIN_EXCHANGE,
                    stepTypeSave: StepType.BIN_IN,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.ExchangeBin,
                    stepTitle: 'Exchange',
                    stepDescription: 'Deliver Bin to Site & Collect Bin from Site',
                    stepName: 'In Progress',
                    binCount: 0,
                },
                {
                    jobType: JobStepType.Exchange,
                    stepTypeDraw: StepType.BIN_IN,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.PutBin,
                    stepTitle: 'Exchange',
                    stepDescription: 'Deliver Bin to Site & Collect Bin from Site',
                    stepName: 'Complete',
                    binCount: 1,
                }
            ];
		} else if (jobType === JobStepType.OnTheSpot) {
            return [ 
                {
                    jobType: JobStepType.OnTheSpot,
                    stepTypeDraw: StepType.BIN_OUT,
                    stepTypeSave: StepType.BIN_OUT,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.LoadBin,
                    stepTitle: 'On The Spot',
                    stepDescription: 'Instant dumping of waste',
                    stepName: 'Start',
                    binCount: -1,
                },
                {
                    jobType: JobStepType.OnTheSpot,
                    stepTypeDraw: StepType.WASTE_COLLECTION,
                    stepTypeSave: StepType.BIN_IN,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.NoActivity,
                    stepTitle: 'On The Spot',
                    stepDescription: 'Instant dumping of waste',
                    stepName: 'In Progress',
                    binCount: 0,
                },
                {
                    jobType: JobStepType.OnTheSpot,
                    stepTypeDraw: StepType.BIN_IN,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.PutBin,
                    stepTitle: 'On The Spot',
                    stepDescription: 'Instant dumping of waste',
                    stepName: 'Complete',
                    binCount: 1,
                }
            ];
		} else if (jobType === JobStepType.Shift) {
            return [ 
                {
                    jobType: JobStepType.Shift,
                    stepTypeDraw: StepType.SHIFT_EXCHANGE,
                    stepTypeSave: StepType.SHIFT_OUT,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.LoadBin,
                    stepTitle: 'Shift Bin',
                    stepDescription: 'Shift bin from address 1 to address 2',
                    stepName: 'Start',
                    binCount: -1,
                },
                {
                    jobType: JobStepType.Shift,
                    stepTypeDraw: StepType.SHIFT_EXCHANGE,
                    stepTypeSave: StepType.SHIFT_IN,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.PutBin,
                    stepTitle: 'Shift Bin',
                    stepDescription: 'Shift bin from address 1 to address 2',
                    stepName: 'Complete',
                    binCount: 1,
                }
            ];

		} else if (jobType === JobStepType.Out) {
            return [ 
                {
                    jobType: JobStepType.Out,
                    stepTypeDraw: StepType.BIN_OUT,
                    stepTypeSave: StepType.BIN_OUT,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.LoadBin,
                    stepTitle: 'Out',
                    stepDescription: 'Deliver Bin to Site',
                    stepName: 'Start',
                    binCount: -1,
                },
                {
                    jobType: JobStepType.Out,
                    stepTypeDraw: StepType.BIN_EXCHANGE,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.PutBin,
                    stepTitle: 'Out',
                    stepDescription: 'Deliver Bin to Site',
                    stepName: 'Complete',
                    binCount: 1,
                }
            ];

        } else if(jobType === JobStepType.Task){
            return [ 
                {
                    jobType: JobStepType.Task,
                    stepTypeDraw: StepType.BIN_OUT,
                    stepTypeSave: StepType.BIN_OUT,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.NoActivity,
                    stepTitle: 'Task',
                    stepDescription: 'Do task from this address',
                    stepName: 'Start',
                    binCount: 0,
                },
                {
                    jobType: JobStepType.Task,
                    stepTypeDraw: StepType.BIN_EXCHANGE,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.NoActivity,
                    stepTitle: 'Task',
                    stepDescription: 'Do task to this address',
                    stepName: 'Complete',
                    binCount: 0,
                }
            ];

        /* this below is not used for now */
		} else if (jobType === JobStepType.ThrowAtCustomer) {
            return [ 
                {
                    jobType: JobStepType.ThrowAtCustomer,
                    stepTypeDraw: StepType.NONE,
                    stepTypeSave: StepType.BIN_IN,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.LoadBin,
                    stepTitle: 'Throw At Customer Site',
                    stepDescription: 'Act on customer\'s request',
                    stepName: 'Start',
                    binCount: -1,
                },
                {
                    jobType: JobStepType.ThrowAtCustomer,
                    stepTypeDraw: StepType.WASTE_COLLECTION,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.NoActivity,
                    stepTitle: 'Throw At Customer Site',
                    stepDescription: 'Act on customer\'s request',
                    stepName: 'In Progress',
                    binCount: 0,
                },
                {
                    jobType: JobStepType.ThrowAtCustomer,
                    stepTypeDraw: StepType.NONE,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.PutBin,
                    stepTitle: 'Throw At Customer Site',
                    stepDescription: 'Act on customer\'s request',
                    stepName: 'Complete',
                    binCount: 1,
                }
            ];
		} else {
			return null;
		}
    },
    
    getBinActivityText: (binActivity, stepType, jobType) => {
        if((stepType === StepType.WASTE_COLLECTION)){
            return 'Collect waste at';
        } else {
            if(binActivity === JobStepBinActivity.LoadBin){
                return 'Collect bin from';
            } else if(binActivity === JobStepBinActivity.PutBin){
                if((jobType === JobStepType.Put) || (jobType === JobStepType.Shift)){
                    return 'Deliver bin to';
                } else {
                    return 'Return bin to';
                }
            } else if(binActivity === JobStepBinActivity.ExchangeBin){
                return 'Exchange bin at';
            } else {
                return '';
            }
        }
    },

    formatCustomerSiteAddress(item = null) {
        let address = '';

        if(item){
            let siteName = (item.siteName && item.siteName !== '') ? item.siteName : '';
            let blockNo = (item.blockNo && item.blockNo !== '') ? item.blockNo : '';
            let street = (item.street && item.street !== '') ? item.street : '';
            let unitNo = (item.unitNo && item.unitNo !== '') ? item.unitNo : '';
            let postalCode = (item.postalCode && item.postalCode !== '') ? item.postalCode : '';
    
            let addressParts = [];
            if(blockNo !== ''){
                addressParts.push('Block ' + blockNo);
            }
            if(street !== ''){
                addressParts.push(street);
            }
            if(unitNo !== ''){
                addressParts.push('Unit No. ' + unitNo);
            }
            if(postalCode !== ''){
                addressParts.push('Post Code ' + postalCode);
            }

            address = siteName;
            if(addressParts && addressParts.length > 0){
                address += ' (' + addressParts.join(', ') + ')';
            }
        }

        return address;
    },

    isCompletedOrCancelledOrFailed(status = '') {
        if(status.toString().toLowerCase() === 'completed'){
            return true;
        } else if(status.toString().toLowerCase() === 'cancelled'){
            return true;
        } else if(status.toString().toLowerCase() === 'Failed'){
            return true;
        } else {
            return false;
        }
    },
    isCancelledOrFailed(status = '') {
        if(status.toString().toLowerCase() === 'cancelled'){
            return true;
        } else if(status.toString().toLowerCase() === 'Failed'){
            return true;
        } else {
            return false;
        }
    },
    sortStatus(status, field = 'order') {
        if(status && status.length > 0){
            return status.sort((a, b) => {
                return a[field] - b[field];
            });
        } else {
            return [];
        }
    },
    findStatus(statuses = [], param = '', text = '') {
		let status = null;

		if(statuses && statuses.length > 0){
			let statusItem = statuses.filter(x => x[param].toLowerCase().trim() === text.toLowerCase().trim());

			if(statusItem && statusItem.length > 0){
				status = statusItem[0];
			}
		}

		return status;
    },
    
    findSettings(settings = [], text = '', param = 'variableName') {
		let status = null;

		if(settings && settings.length > 0){
			let statusItem = settings.filter(x => x[param].toLowerCase().trim() === text.toLowerCase().trim());

			if(statusItem && statusItem.length > 0){
				status = statusItem[0];
			}
        }
        
		return status;
    },
    findSettingsItem(settings = null, type = AccountDocSettingVariables.String, param = 'variableValue') {
		let status = (type === AccountDocSettingVariables.Show) ? false : '';

		if(settings){
			status = settings[param];

			if(type === AccountDocSettingVariables.Show){
                status = apiUtil.strToBool(status);
			}
        }
        
		return status;
    },
    setSettingsItem(settings = null, param = '', value = '', paramKey = 'variableName', paramValue = 'variableValue') {
		if(settings){
            let index = settings.findIndex(x => x[paramKey] === param);
            if(index !== -1){
                settings[index][paramValue] = value;
            }
        }
        
		return settings;
    },
    
    customConcat(array = [], separator = '') {
        return array.filter(x => (x !== null && x !== '')).join(separator);
    },

    strToBool(status = '') {
        if(status == 'True'){
            return true;
        } else if(status == 'False'){
            return false;
        } else {
            return false;
        }
    },
    boolToStr(status = false) {
        if(status === true){
            return 'True';
        } else if(status === false){
            return 'False';
        } else {
            return 'False';
        }
    },

    getPaginationCount(totalRows, rowsPerPageCount) {
        let mod = totalRows % rowsPerPageCount;
        let pages = totalRows / rowsPerPageCount;
        return (mod > 0) ? (parseInt(pages) + 1) : pages;
    },

    getMaxPageSize: () => {
        return 99999;
    },
    
    getMinDate: () => {
        return '0001-01-01T00:00:00';
    },
    getMaxDate: () => {
        return '9999-12-31T23:59:59';
    },

    
    hideDropDown: () => {
        let elements = document.getElementsByClassName('react-autosuggest__suggestions-container');
        if(elements && elements.length > 0){
            for (let i = 0; i < elements.length; i++) {
                elements[i].classList.remove('react-autosuggest__suggestions-container--open');
            }
        }
    },

    getCSVDateFormat: () => {
        return "DD/MM/YYYY";
    },
    getCSVDateTimeFormat: () => {
        return "DD/MM/YYYY hh:mm A";
    },

    getAPIDateFormat: () => {
        return "YYYY-MM-DD";
    },
    getAPIDateTimeFormat: () => {
        return "YYYY-MM-DD hh:mm A";
    },

    getDefaultDateFormat: () => {
        return "DD/MM/YYYY";
    },
    getDefaultTimeFormat: () => {
        return "hh:mm A";
    },
    getDefaultDateTimeFormat: () => {
        return "DD/MM/YYYY hh:mm A";
    },

    getHours: () => {
        return [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
    },
    getMinutes: () => {
        return [0,30];
    },

    getDateTimeRangesDiff: (dateTimes = []) => {
        let ranges = apiUtil.getDateTimeRanges(dateTimes);
        return apiUtil.getDateTimeDiff(ranges);
    },
    getDateTimeRanges: (ranges = []) => {
        let array = [];
        
        if(ranges && ranges.length > 0){
            ranges.map(x => {
                let from = moment(x[0]);
                let to = moment(x[1]);

                let secondDate = to.format("YYYY-MM-DDTHH:mm:ss");
                if(apiUtil.isSameOrAfter(from, to)){
                    secondDate = to.add(1, 'day').format("YYYY-MM-DDTHH:mm:ss");
                }

                array.push(apiUtil.getDateTimeRange(x[0], secondDate));
            });
        }

        return array;
    },
    getDateTimeRange: (from, to) => {
        let range = moment.range(from, to);
        
        let array = Array.from(range.by('minutes', { excludeEnd: false, step: 60 }));

        let h = _.union(array.map(x => parseInt(x.format('H'))));
        let m = _.union(array.map(x => parseInt(x.format('m'))));

        return {
            hours: h,
            minutes: m
        }
    },
    getDateTimeDiff: (array = []) => {
        let h = _.union(...array.map(x => x.hours)).sort((a, b) => a - b);
        let m = _.union(...array.map(x => x.minutes)).sort((a, b) => a - b);

        let diffH = _.difference(apiUtil.getHours(), h);
        let diffM = _.difference(apiUtil.getMinutes(), m);

        return {
            hours: diffH,
            minutes: diffM
        }
    },
    getMinMaxDateTime: (ranges = []) => {
        let moments = [];
        let minDate = null;
        let maxDate = null;

        if(ranges && ranges.length > 0){
            let array = [];
        
            ranges.map(x => {
                let from = moment(x[0]);
                let to = moment(x[1]);
                
                let secondDate = to;
                if(from.isAfter(to)){
                    secondDate = to.add(1, 'day');
                }

                array.push(from);
                array.push(secondDate);
            });

            moments = array.map(d => moment(d));
            minDate = moment.min(moments);
            maxDate = moment.max(moments);
        }
        
        return {
            min: minDate.format("YYYY-MM-DDTHH:mm:ss"),
            max: maxDate.format("YYYY-MM-DDTHH:mm:ss")
        }
    },

    getDateTimeArray: (ranges = []) => {
        let array = [];

        if(ranges && ranges.length > 0){
            ranges.map(x => {
                let dates = apiUtil.convertFromTo(moment(x[0]), moment(x[1]));
                array.push([ dates.from, dates.to ]);
            });
        }
        
        return array
    },
    isDateTimeExist: (dates, from, to) => {
        let isBetween = false;
        let isBetweenFrom = false;
        let isBetweenTo = false;
        
        if(dates && dates.length > 0){
            for(let i = 0; i < dates.length; i++){
                if(!isBetweenFrom)
                    isBetweenFrom = apiUtil.isBetween(from, dates[i][0], dates[i][1]);

                if(!isBetweenTo)
                    isBetweenTo = apiUtil.isBetween(to, dates[i][0], dates[i][1]);
            }
        }

        if(isBetweenFrom && isBetweenTo){
            isBetween = true;
        }

        return isBetween;
    },
    isFromToExist: (dates, from, to) => {
        let isBetween = false;
        
        let isBetweenFromTo = apiUtil.isDateTimeExist(dates, from, to);

        if(isBetweenFromTo){
            isBetween = true;
        }

        return isBetween;
    },
    convertFromTo: (from, to) => {
        let firstDate = moment();
        if(from.isAfter(to)){
            firstDate = moment().subtract(1, 'day');
        }

        let currentDate = moment().format(apiUtil.getAPIDateFormat());
        let date = firstDate.format(apiUtil.getAPIDateFormat());

        let fromDate = from.format(apiUtil.getDefaultTimeFormat());
        let toDate = to.format(apiUtil.getDefaultTimeFormat());

        let dateF = moment(date + ' ' + fromDate, apiUtil.getAPIDateTimeFormat());
        let dateT = moment(currentDate + ' ' + toDate, apiUtil.getAPIDateTimeFormat());

        return {
            from: dateF,
            to: dateT
        };
    },
    isSameOrAfter: (from, to) => {
        return from.isSameOrAfter(to)
    },
    isSameOrBefore: (from, to) => {
        return from.isSameOrBefore(to)
    },
    isBetween: (date, from, to) => {
        return moment(date).isBetween(from, to, undefined, '[]');
    },

    getDateRanges: () => {
        return [
            {
                label: 'Today',
                value: [moment().toDate(), moment().toDate()]
            },
            {
                label: 'Tomorrow',
                value: [moment().add(1, 'days').toDate(), moment().add(1, 'days').toDate()]
            },
            {
                label: 'Yesterday',
                value: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()]
            },  
            {
                label: 'This Week',
                value: [moment().startOf('week').toDate(), moment().endOf('week').toDate()]
            },  
            {
                label: 'Last Week',
                value: [moment().subtract(1, 'week').startOf('week').toDate(), moment().subtract(1, 'week').endOf('week').toDate()]
            }, 
            {
                label: 'This Month',
                value: [moment().startOf('month').toDate(), moment().endOf('month').toDate()]
            }, 
            {
                label: 'Last Month',
                value: [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()]
            }, 
            {
                label: 'This Year',
                value: [moment().startOf('year').toDate(), moment().endOf('year').toDate()]
            }, 
            {
                label: 'Last Year',
                value: [moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()]
            }, 
            {
                label: 'Custom',
                value: [null, null]
            }, 
        ]
    },
    getDateRangeLabel: (startDate, endDate) => {
        let label = 'Custom';

        let sD = moment(startDate).startOf('day');
        let eD = moment(endDate).startOf('day');

        if(sD.isSameOrAfter(eD)){
            let pom = sD;
            sD = eD;
            eD = pom;
        }

        let ranges = apiUtil.getDateRanges();
        if(ranges && ranges.length > 0){
            for(let i = 0; i < ranges.length; i++){
                let sDate = moment(ranges[i].value[0]).startOf('day');
                let eDate = moment(ranges[i].value[1]).startOf('day');
                
                if((sDate.isSame(sD)) && (eDate.isSame(eD))){
                    label = ranges[i].label;
                    break;
                }
            }
        }

        return label;
    },
    getDateRangeValue: (label) => {
        let value = null;

        let ranges = apiUtil.getDateRanges();
        if(ranges && ranges.length > 0){
            for(let i = 0; i < ranges.length; i++){
                if(ranges[i].label === label){
                    value = ranges[i].value;
                    break;
                }
            }
        }

        return value;
    },

    getLocalIsoDateTime: (dtString) => {
        try {
            if(dtString == "")
                return "";

            var offset = new Date().getTimezoneOffset();
            var localISOTime = (new Date(new Date(dtString) - offset * 60000 /*offset in milliseconds*/)).toISOString().slice(0,-1);
            //Next two lines can be removed if zone isn't needed.
            //var absO = Math.abs(offset);
            //var zone = (offset < 0 ? "+" : "-") + ("00" + Math.floor(absO / 60)).slice(-2) + ":" + ("00" + (absO % 60)).slice(-2);
            return localISOTime;// + zone;
        }catch(e){}
    },

    getUint8Array: (base64) => {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        return new Uint8Array(byteNumbers);
    },

    fixedOnLeftElement: (fixedLeftClass, scrollX) => {
        let fixedLeftElements = document.getElementsByClassName(fixedLeftClass);

        if(fixedLeftElements && fixedLeftElements.length > 0){
            for(var i = 0; i < fixedLeftElements.length; i++) {
                try {
                    fixedLeftElements[i].style.position = 'relative';
                    fixedLeftElements[i].style.left = scrollX + 'px';
                } catch(err){}
            }
        }
    },

    scrollTo(className = '', offset = -65, duration = 1500) {
        if(className && className !== ''){
            var elements = document.getElementsByClassName(className);
            if(elements && elements.length > 0){
                scrollToElement(elements[0], {
                    offset: offset,
                    ease: 'out-expo',
                    duration: duration
                });
            }
        }
    },

    scrollToBottom(ref = null){
        try {
            ref.current.scrollTop = ref.current.scrollHeight;
        } catch(e) {}
    },

    isNumeric: (x) => {
        return parseFloat(String(x)) == x;
    },
    
    hasPermission: (permissions = [], permissionId = 0) => {
        if(permissionId !== null){
            if(permissions && permissions.length > 0){
                let permissionItem = permissions.filter(x => x.permissionId === permissionId);
                return (permissionItem && permissionItem.length > 0) ? true : false;
            } else {
                return false;
            }
        } else {
            return true;
        }
    },
    hasPagePermission: (path = '') => {
        let noPermission = '/no-permission';
        
        if(path === '/Dashboard'){
            return '/Customers';
        }
        
        if(path === '/Customers'){
            return '/Jobs';
        }
        
        if(path === '/Jobs'){
            return '/Billing';
        }
        
        if(path === '/Billing'){
            return '/Manage';
        }

        if(path === '/Manage'){
            return '/Reports';
        }

        return noPermission;
    },
    hasManagePermission: (permissions = []) => {
        let noPermission = '/Reports';

        if(permissions && permissions.length > 0){
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManageDrivers.read) !== -1){
                return '/manage/resource/drivers';
            }
            
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManageVehicles.read) !== -1){
                return '/manage/resource/vehicles';
            }
            
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManageWaste.read) !== -1){
                return '/manage/resource/waste-types';
            }
            
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManageBins.read) !== -1){
                return '/manage/resource/bin-types';
            }
            
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManageService.read) !== -1){
                return '/manage/job/additional-services';
            }
            
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManageUsers.read) !== -1){
                return '/manage/user/users';
            }
            
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManageCompany.read) !== -1){
                return '/manage/profile/company';
            }
            
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManageJobtemplate.read) !== -1){
                return '/manage/job/job-types';
            }
            
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManageSubscription.read) !== -1){
                return '/manage/setting/subscription';
            }
            
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManageGeneral.read) !== -1){
                return '/manage/setting/general';
            }
            
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManagePayroll.read) !== -1){
                return '/manage/payroll/payroll';
            }

            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManageWorkHours.read) !== -1){
                return '/manage/payroll/hours';
            }

            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManageHoliday.read) !== -1){
                return '/manage/setting/holiday';
            }

            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManageTime.read) !== -1){
                return '/manage/job/time';
            }
        }

        return noPermission;
    },
    hasReportsPermission: (permissions = []) => {
        let noPermission = '/no-permission';

        if(permissions && permissions.length > 0){
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ReportsBins.read) !== -1){
                return '/Reports-bins-on-site';
            }

            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ReportsBinsActivities.read) !== -1){
                return '/Reports-bin-activities';
            }

            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ReportsCollectionSummary.read) !== -1){
                return '/Reports-waste-collections-summary';
            }
            
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ReportsCollectionDetailed.read) !== -1){
                return '/Reports-waste-collections-detailed';
            }
            
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ReportsPurchaseDetailed.read) !== -1){
                return '/Reports-waste-purchase-detailed';
            }

            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ReportsDriverTripPay.read) !== -1){
                return '/Reports-driver-trip-pay';
            }
            
            if(permissions.findIndex(x => x.permissionId === PagesPermissions.ReportsCustomerServices.read) !== -1){
                return '/Reports-customer-services';
            }
        }

        return noPermission;
    },

    

    getJsonFromString: (data = null) => {
        let item = [];

        if(data && data !== ''){
          try {
            item = JSON.parse(data);
          } catch(e){}
        }

        return item;
    },

    setTemplateFunctionsToColumns: (initialColumns = [], columnsFromCookie = [], field = 'id') => {
        let newColomuns = [];
        if(columnsFromCookie && columnsFromCookie.length > 0){
            for(let i = 0; i < columnsFromCookie.length; i++){
                let item = columnsFromCookie[i];

                let colItem = initialColumns.filter(x => x[field] === item[field]);
                let index = initialColumns.findIndex(x => x[field] === item[field]);
                if(colItem && colItem.length > 0){
                    if(colItem[0].template){
                        item.template = colItem[0].template;
                    }
                    if(colItem[0].exportTemplate){
                        item.exportTemplate = colItem[0].exportTemplate;
                    }
                }
                
                newColomuns.push(columnsFromCookie[i]);

                if (index > -1) {
                    initialColumns.splice(index, 1);
                }
            }
        }

        return newColomuns;
    },

    getUserCookieSettingsIndex: (settings = null, settingsType = null) => {
        let index = -1;

        if((settings && settings.length > 0) && (settingsType !== null)){
            index = settings.findIndex(x => x.type === settingsType);
        }

        return index;
    },
    getUserCookieSettings: (settings = null, settingsType = null) => {
        let item = null;

        let settingsObj = apiUtil.getJsonFromString(settings);

        if((settingsObj && settingsObj.length > 0) && (settingsType !== null)){
            let index = apiUtil.getUserCookieSettingsIndex(settingsObj, settingsType);
            if(index !== -1 && settingsObj[index] && settingsObj[index].value && settingsObj[index].value !== ''){
                item = apiUtil.getJsonFromString(settingsObj[index].value);
            }
        }

        return item;
    },
    resetUserCookieSettingsByDate: (settings = []) => {
        if(settings && settings.length > 0){
            for(let i = 0; i < settings.length; i++){
                let createdDate = (settings[i].created && settings[i].created !== '') ? moment.utc(settings[i].created).startOf('day') : null;
                let updatedDate = (settings[i].updated && settings[i].updated !== '') ? moment.utc(settings[i].updated).startOf('day') : null;
                // let nowDate = moment().utc().startOf('day');
                let nowDate = moment.utc(apiUtil.getUtcDateFromSettings()).startOf('day');

                let isToday = false;
                if(createdDate){
                    if(createdDate.isSame(nowDate)){
                        isToday = true;
                    }
                }

                if(updatedDate){
                    if(updatedDate.isSame(nowDate)){
                        isToday = true;
                    }
                }

                let item = apiUtil.getJsonFromString(settings[i].value);

                if(!isToday){
                    let newValue = Object.assign({}, item);
                    delete newValue[PageCookieSettingType.fromDate];
                    delete newValue[PageCookieSettingType.toDate];
                    delete newValue[PageCookieSettingType.dateRange];
                    delete newValue[PageCookieSettingType.selectedDateFilterIndex];
                    delete newValue[PageCookieSettingType.search];
                    settings[i].value = JSON.stringify(newValue);
                }
            }
        }
        
        return settings;
    },
    saveUserCookieSettings: (row, token, callback = null) => {
        userCookieSettingApi.create(row, token).then((result) => {
            apiUtil.parseResult(result,
                (data) => {
                    if(callback){
                        callback(data);
                    }
                },
                (error, type) => {
                    if(callback){
                        callback(null);
                    }
                },
                () => {}
            );
        });
    },
    deleteUserCookieSettings: (row, token, callback = null) => {
        userCookieSettingApi.delete(row, token).then((result) => {
            apiUtil.parseResult(result,
                (data) => {
                    if(callback){
                        callback();
                    }
                },
                (error, type) => {},
                () => {}
            );
        });
    },
    deleteAllUserCookieSettings: (token) => {
        apiUtil.deleteUserCookieSettings({ name: 'customerPrice', type: UserCookieSettingType.Customer }, token);
        apiUtil.deleteUserCookieSettings({ name: 'Customer Sites', type: UserCookieSettingType.CustomerSite }, token);
        apiUtil.deleteUserCookieSettings({ name: 'Customer Services', type: UserCookieSettingType.CustomerServices }, token);
        apiUtil.deleteUserCookieSettings({ name: 'jobs-page', type: UserCookieSettingType.Job }, token);
        apiUtil.deleteUserCookieSettings({ name: 'BillingBySite', type: UserCookieSettingType.BillingBySite }, token);
        apiUtil.deleteUserCookieSettings({ name: 'billingGroup', type: UserCookieSettingType.BillingByCustomer }, token);
        apiUtil.deleteUserCookieSettings({ name: 'billing-group-details', type: UserCookieSettingType.BillingByCustomer }, token);
        apiUtil.deleteUserCookieSettings({ name: 'billing', type: UserCookieSettingType.BillingByJobDates }, token);
        apiUtil.deleteUserCookieSettings({ name: 'BillingBySiteTable', type: UserCookieSettingType.BillingBySiteTable }, token);
        apiUtil.deleteUserCookieSettings({ name: 'BillingBySitePanel', type: UserCookieSettingType.BillingBySitePanel }, token);
        apiUtil.deleteUserCookieSettings({ name: 'Reminders', type: UserCookieSettingType.Reminders }, token);
        apiUtil.deleteUserCookieSettings({ name: 'BillingBySite', type: UserCookieSettingType.BillingBySite }, token);
        apiUtil.deleteUserCookieSettings({ name: 'Alert', type: UserCookieSettingType.Alert }, token);
    },
    
    getDuration: (hours, minutes) => {
        return parseFloat(parseInt(hours) + (parseInt(minutes) / 60));
    },
    getHoursMinutes: (duration) => {
        let totalDuration = parseFloat(duration) * 60;
        let hours = parseInt(totalDuration / 60);
        let minutes = parseInt(totalDuration % 60);

        return {
            hours: parseInt(hours),
            minutes: parseInt(minutes),
        }
    },
    getDiff: (completedDate, startedDate) => {
        let date1 = moment(completedDate);
        let date2 = moment(startedDate);

        let duration = moment.duration(date1.diff(date2));
        
        return {
            h: duration.hours(),
            m: duration.minutes(),
        }
    },

    getFilteredItem: (filter, field) => {
        if(filter && filter.length > 0){
            return filter[0];
        } else {
            return { field: '' };
        }
    },

    setFavNumb: (x = '') => {
        Tinycon.setBubble(x);

        Tinycon.setOptions({
            color: 'white',
            background: '#f50057',
            fallback: true
        });
    },

    setCookie: (cookieName = '', cooieData, isJson = true) => {
        let cooieDataJson = (isJson) ? JSON.stringify(cooieData) : cooieData.toString();
        var compressed = lzutf8.compress(cooieDataJson, {outputEncoding: "Base64"});
        Cookies.set(cookieName, compressed, { path: '/' });
    },
    getCookie: (cookieName = '', isJson = true) => {
        let cookieData = null;

        let cookieCompressedData = Cookies.get(cookieName);
        var decompressed = lzutf8.decompress(cookieCompressedData, {inputEncoding: "Base64"});
        cookieData = (isJson) ? JSON.parse(decompressed) : decompressed;

        return cookieData;
    },
    removeCookie: (cookieName = '') => {
        Cookies.remove(cookieName, { path: '/' });
    },
    
    setLocalStorage: (pageName = '', fieldName = '', value, defaultValue, isJson = false) => {
        let valueData = (value) ? value : defaultValue;
        let data = (isJson) ? JSON.stringify(valueData) : valueData.toString();
        var compressed = lzutf8.compress(data, { outputEncoding: "Base64" });
        window.localStorage.setItem(pageName + '_' + fieldName, compressed);
    },
    getLocalStorage: (pageName = '', fieldName = '', defaultValue, isJson = false) => {
        let compressedData = window.localStorage.getItem(pageName + '_' + fieldName);
        if(compressedData){
            let decompressed = lzutf8.decompress(compressedData, { inputEncoding: "Base64" });
            return (isJson) ? JSON.parse(decompressed) : decompressed;
        } else {
            return defaultValue;
        }
    },
    removeLocalStorage: (pageName = '', fieldName = '') => {
        localStorage.removeItem(pageName + '_' + fieldName);
    },

    getElementByClassName: (className = '', element = null) => {
        let el = null;
        if(className && className !== ''){
            try {
                if(element){
                    el = element.getElementsByClassName(className);
                } else {
                    el = document.getElementsByClassName(className);
                }
            } catch(err){}
        }
        return el;
    },
    flash: (element = null, className = '', anim = 'highlight-anim') => {
        if(element && className !== ''){
            try {
                let els = apiUtil.getElementByClassName(className, element);
                if(els && els.length > 0){
                    for(let i = 0; i < els.length; i++){
                        els[i].classList.add(anim);
                    }
                }

                setTimeout(() => {
                    try {
                        if(els && els.length > 0){
                            for(let i = 0; i < els.length; i++){
                                els[i].classList.remove(anim);
                            }
                        }
                    } catch(err){}
                }, 2000);
            } catch(err){}
        }
    },

    getJoinSeparator: (separator = null) => {
        return separator ? separator : ';';
    },
    getSelectedColumns: (columns, searchColumnKey = 'id', advancedColumnKey = 'id') => {
        let items = [];

        if (columns && columns.length > 0) {
            for (let i = 0; i < columns.length; i++) {
                if(searchColumnKey === advancedColumnKey){
                    if(columns[i][searchColumnKey]){
                        items.push(columns[i][searchColumnKey]);
                    } else {
                        items.push(columns[i]['id']);
                    }
                } else {
                    if(columns[i][advancedColumnKey]){
                        items.push(columns[i][searchColumnKey]);
                    } else {
                        items.push(columns[i]['id']);
                    }
                }
            }
        }
  
        return items.join(apiUtil.getJoinSeparator());
    },
    getSelectedOperator: (operators, defaultOperator = 'or') => {
        return operators && operators.length > 0 ? operators[0].operator : defaultOperator;
    },
    joinItems: (items) => {
        return (items && items.length > 0) ? items.join(apiUtil.getJoinSeparator()) : '';
    },


    getRangesValue: (mainItem = null, from = 'distanceFrom', to = 'distanceTo') => {
        let data = [];

        if(mainItem && (mainItem.payrollTemplateVariableRanges && mainItem.payrollTemplateVariableRanges.length > 0)){
            data = mainItem.payrollTemplateVariableRanges.map((x, i) => {
                return [
                    x[from],
                    x[to],
                ]
            });
        }
        
        return data.sort((a, b) => a[0] - b[0]);;
    },
    getRangesWithoutChildValue: (mainItem = null, childItem = null, from = 'distanceFrom', to = 'distanceTo') => {
        let data = [];

        if(mainItem && childItem && (mainItem.payrollTemplateVariableRanges && mainItem.payrollTemplateVariableRanges.length > 0)){
            mainItem.payrollTemplateVariableRanges.forEach(x => {
                if((x[from] == childItem[from]) && (x[to] == childItem[to])){
                    // nop
                } else {
                    data.push([
                        x[from],
                        x[to],
                    ])
                }
            });
        }
        
        return data.sort((a, b) => a[0] - b[0]);
    },
    getAvailableRangesValue: (data = []) => {
        let ranges = [];

        if(data && data.length > 0){
            ranges = apiUtil.fillAvailableRangeData(apiUtil.fillRangesData(data));
        }
        
        return ranges;
    },
    fillRangesData: (data = []) => {
        let ranges = [];

        if(data && data.length > 0){
            for(let i = 0; i < data.length; i++){
                let values = apiUtil.swapMinMax(data[i][0], data[i][1]);
                ranges.push(apiUtil.fillRangeData(values));
            }
        }

        return [].concat(...ranges);
    },
    fillRangeData: (data = []) => {
        let STEP = 0.10;
        let FIXED = 1;
        
        let ranges = [];
        if(data && data.length === 2){
            let start = parseFloat(parseFloat(data[0]).toFixed(FIXED))
            let end = parseFloat(parseFloat(data[1]).toFixed(FIXED))
            
            for(let i = start; i <= end; i = i + STEP){
                ranges.push(parseFloat(parseFloat(i).toFixed(FIXED)));
            }

            if(ranges.findIndex(x => x === start) === -1){
                ranges.unshift(start);
            }

            if(ranges.findIndex(x => x === end) === -1){
                ranges.push(end);
            }
        }

        return ranges;
    },
    fillAvailableRangeData: (data = []) => {
        let MIN = 0;
        let MAX = 150;
        let STEP = 0.10;
        let FIXED = 1;

        let ranges = [];
        for(let i = MIN; i <= MAX; i = i + STEP){
            let i1 = parseFloat(parseFloat(i).toFixed(FIXED))
            
            let index = data.findIndex(x => {
                let x1 = parseFloat(parseFloat(x).toFixed(FIXED))

                return x1 === i1;
            });
            
            if(index === -1){
                ranges.push(i1);
            }
        }

        return ranges;
    },
    fillRangeArray: (data = []) => {
        let STEP = 0.10;
        let FIXED = 1;
        
        let prevVal = null;
        let childRange = [];

        let ranges = [];
        if(data && data.length > 0){
            for(let i = 0; i < data.length; i++){
                if(prevVal){
                    if(i === (data.length-1)){
                        prevVal = data[i];
                        childRange.push(data[i]);
                        ranges.push(childRange);
                        prevVal = null;
                        childRange = [];
                    } else {
                        let z = parseFloat(parseFloat(data[i] - prevVal).toFixed(FIXED))
                        if(z === STEP){
                            childRange.push(data[i]);
                            prevVal = data[i];
                        } else {
                            ranges.push(childRange);
                            prevVal = null;
                            childRange = [data[i]];
                        }
                    }
                } else {
                    childRange.push(data[i]);
                    prevVal = data[i];
                }
            }
        }

        return ranges;
    },
    getMinMaxRangeArray: (data = []) => {
        let ranges = [];

        if(data && data.length > 0){
            for(let i = 0; i < data.length; i++){
                let min = data[i][0];
                let max = data[i][data[i].length-1];

                ranges.push([ min, max ]);
            }
        }

        return ranges;
    },
    getMinRange: (data = []) => {
        return (data && data.length > 0) ? data[0] : null;
    },
    swapMinMax: (oldVal, newVal) => {
        let FIXED = 1;

        let start = parseFloat(parseFloat(oldVal).toFixed(FIXED))
        let end = parseFloat(parseFloat(newVal).toFixed(FIXED))

        if(start < end){
            return [ start, end ]
        } else {
            return [ end, start ]
        }
    },
    isRangeBetween: (data = [], oldVal = 0, newVal = 0) => {
        let FIXED = 1;

        let values = apiUtil.swapMinMax(oldVal, newVal);

        let state = false;
        if(data && data.length > 0){
            for(let i = 0; i < data.length; i++){
                let start = parseFloat(parseFloat(data[i][0]).toFixed(FIXED))
                let end = parseFloat(parseFloat(data[i][1]).toFixed(FIXED))

                if(values[0] <= start && values[1] >= end){
                    state = true;
                    break;
                }
            }
        }

        return state;
    },
    isBetweenRange: (index = 0, value = 0, mainItem = null, childItem = null) => {
        let ranges = apiUtil.getRangesWithoutChildValue(mainItem, childItem);
        let connectedRanges = apiUtil.getMinMaxRangeArray(apiUtil.fillRangeArray(apiUtil.fillRangesData(ranges)));
        
        let state = false;
        let range = null;
        let rangePrev = null;
        let rangeNext = null;
        if(connectedRanges && connectedRanges.length > 0){
            for(let i = 0; i < connectedRanges.length; i++){
                let values = apiUtil.swapMinMax(connectedRanges[i][0], connectedRanges[i][1]);
                let start = values[0]
                let end = values[1]

                if(value >= start && value <= end){
                    state = true;
                    range = connectedRanges[i];
                    rangePrev = (connectedRanges[(i-1)]) ? connectedRanges[(i-1)] : null;
                    rangeNext = (connectedRanges[(i+1)]) ? connectedRanges[(i+1)] : null;
                    break; 
                }
            }
        }

        return {
            index: index,
            state: state,
            range: range,
            rangePrev: rangePrev,
            rangeNext: rangeNext,
        };
    },
    getPrevNextRanges: (value = 0, ranges = []) => {
        let FIXED = 1;

        let rangePrev = null;
        let rangeNext = null;

        if(ranges && ranges.length > 0){
            let rangePrevItem = ranges.filter(x => {
                let end = parseFloat(parseFloat(x[1]).toFixed(FIXED))
                return end < value;
            });
            if(rangePrevItem && rangePrevItem.length > 0){
                rangePrev = rangePrevItem[rangePrevItem.length - 1];
            }

            let rangeNextItem = ranges.filter(x => {
                let start = parseFloat(parseFloat(x[0]).toFixed(FIXED))
                return start > value;
            });
            if(rangeNextItem && rangeNextItem.length > 0){
                rangeNext = rangeNextItem[0];
            }
        }
        
        return {
            rangePrev: rangePrev,
            rangeNext: rangeNext,
        };
    }
}

export default apiUtil;