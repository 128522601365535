import React from "react";

import _ from 'lodash';
import CurrencyInput from 'react-currency-input-field';

import { 
    Row,
    Col,
    Form,
    InputGroup,
    DropdownButton,
    Dropdown,
    Spinner,
    Button,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { ReactComponent as LightbulbIcon } from '../../../../_metronic/assets/img/icons/material-lightbulb-outline.svg';

import { Formik, FieldArray } from 'formik';
import * as yup from 'yup';

import Utils from "../../../utils/utils";
import { BillingTypes, ServiceType, ActiveInactive, ReportUOM, AccountSettingType } from "../../../utils/enums";

import InputLayout from "../../../components/input/InputLayout";
import SmartInputDropdown from '../../../components/dropdown/smartInputDropdown/SmartInputDropdown';
import makeCRUD from "../../../components/dropdown/smartInputDropdown/makeCRUD";
import RemoveDialog from '../../../components/dialog/RemoveDialog';
import Popup from '../../../components/popup/Popup';
import LoadingButton from '../../../components/Buttons/LoadingButton';
import RSuiteCheckPicker from '../../../components/OLD/Checkbox/RSuiteCheckPicker';
import BillingCategoryForm from '../../billingCategory/partial/BillingCategoryForm';
import Tooltip from '../../../components/Tooltip/Tooltip';

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import taxRate from "../../../../setup/redux/slices/taxRate";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'

import DefaultTaxRatePopup from '../../globalService/defaultTaxRate/Form';


const SmartDropdown = makeCRUD(SmartInputDropdown);


const StyledDropdownButton = styled(DropdownButton)`
    button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        color: #181C32 !important;
        background-color: #F5F8FA !important;
        border: 1px solid #E4E6EF !important;

        &:after {
            color: #181C32 !important;
        }

        &:hover {
            background-color: #f5f5f5 !important;
            color: #181C32 !important;
        }

        &[disabled] {
            &:after {
                display: none !important;
            }
        }
    }
`;
const StyledAddButton = styled.a`
    border: 1px solid #DFDFDF;
    border-radius: 20px;
    padding: 7px 15px;
    color: #444444 !important;
    font-weight: 500;

    &:hover {
        color: #8F8F8F !important;
    }
`;


const formFields = {
    isActive: {
      id: 'isActive',
      label: 'Status',
      placeholder: ' ',
    },
    serviceTag: {
        id: 'serviceTag',
        label: 'Service Name',
        placeholder: ' ',
    },
    wasteTypeId: {
        id: 'wasteTypeId',
        label: 'Waste Type',
        placeholder: ' ',
    },
    binTypeId: {
        id: 'binTypeId',
        label: 'Bin Type',
        placeholder: ' ',
    },
    description: {
        id: 'description',
        label: 'Description',
        placeholder: Utils.placeholderRows(5),
    },
    billingType: {
        id: 'billingType',
        label: 'Billing Type',
        placeholder: ' ',
    },
    price: {
        id: 'price',
        label: 'Rate',
        placeholder: ' ',
    },
    isTaxInclusive: {
        id: 'isTaxInclusive',
        label: 'Tax Inclusive',
        placeholder: ' ',
    },
    defaultTaxRate: {
        id: 'defaultTaxRate',
        label: 'Default Tax Rate',
        placeholder: ' ',
    },
    chargeCategoryId: {
        id: 'chargeCategoryId',
        label: 'Billing Category',
        placeholder: ' ',
    },
    isSelectAllSites: {
        id: 'isSelectAllSites',
        label: 'Apply to sites',
        labelTrue: 'All sites',
        labelFalse: 'Selected sites',
        placeholder: ' ',
    },
    autoApplyToNewSites: {
        id: 'autoApplyToNewSites',
        label: 'Auto apply to new sites',
        placeholder: ' ',
    },
    customerSiteId: {
        id: 'customerSiteId',
        label: ' ',
        placeholder: ' ',
    },

    bundleServices: {
        id: 'bundleServices',
        label: '',
        placeholder: ' ',
    },
    bundleServiceTag: {
        id: 'bundleServiceTag',
        label: 'Bundle Name',
        placeholder: ' ',
    },
    bundleBillingType: {
        id: 'bundleBillingType',
        label: 'Billing Type',
        placeholder: ' ',
    },
    bundlePrice: {
        id: 'bundlePrice',
        label: 'Rate',
        placeholder: ' ',
    },
    bundleIsTaxInclusive: {
        id: 'bundleIsTaxInclusive',
        label: 'Tax Inclusive',
        placeholder: ' ',
    },
    bundleChargeCategoryId: {
        id: 'bundleChargeCategoryId',
        label: 'Billing Category',
        placeholder: ' ',
    },
    bundleUseBinWeightQuantity: {
        id: 'bundleUseBinWeightQuantity',
        label: 'Link Billable Weight To Service Item Qty',
        placeholder: ' ',
        info: 'When this is enabled, this bundle service item uses the billable weight as its quantity (QTY).',
    },
    bundleNonBillableWeight: {
        id: 'bundleNonBillableWeight',
        label: 'Cap Non-Billable Weight To',
        placeholder: ' ',
        info: 'Refers to first few weight units that is complementary\nNett Weight - Non billable Weight = Billable Weight',
    },
}
const formSchema = yup.object().shape({
    isEdit: yup.bool().oneOf([true, false]),
    isActive: yup.bool().oneOf([true, false]),
    serviceTag: yup.string().required().label(formFields.serviceTag.label),
    wasteTypeId: yup.number().nullable().label(formFields.wasteTypeId.label),
    binTypeId: yup.number().nullable().label(formFields.binTypeId.label),
    description: yup.string().label(formFields.description.label),
    billingType: yup.number().nullable().min(0).label(formFields.billingType.label),

    // price: yup.number().nullable().min(0).required().label(formFields.price.label),
    hasBillingPermission: yup.bool().oneOf([true, false]),
    price: yup.number().when('hasBillingPermission', {
        is: true,
        then: (schema) => schema.nullable().min(0).required().label(formFields.price.label),
        otherwise: (schema) => schema.nullable().label(formFields.price.label),
    }),
    isTaxInclusive: yup.bool().oneOf([true, false]),
    chargeCategoryId: yup.number().when('hasBillingPermission', {
        is: true,
        then: (schema) => schema.nullable().required().label(formFields.chargeCategoryId.label),
        otherwise: (schema) => schema.nullable().label(formFields.chargeCategoryId.label),
    }),

    isSelectAllSites: yup.bool().oneOf([true, false]),
    autoApplyToNewSites: yup.bool().oneOf([true, false]),
    // customerSiteId: yup.array().nullable().label(formFields.customerSiteId.label),

    customerSiteId: yup.array().when(['isSelectAllSites'], (isSelectAllSites) => {
        if(isSelectAllSites){
            return yup.array().nullable().label(formFields.customerSiteId.label);
        } else {
            return yup.array().min(1).label(formFields.customerSiteId.label);
        }
    }),

    useBinWeightQuantity: yup.bool().oneOf([true, false]).label(formFields.bundleUseBinWeightQuantity.label),
    nonBillableWeight: yup.number().when(['useBinWeightQuantity'], (useBinWeightQuantity, schema) => {
        if(useBinWeightQuantity){
            if (Utils.getBillingUOM() === ReportUOM.kg ) {
            return yup.number()
                .integer()
                .test('noDecimal', 'Decimal values are not allowed', val => {
                    if (val !== undefined && val !== null) {
                        const strVal = val.toString();
                        return strVal.indexOf('.') === -1;
                    }
                    return true;
                })
                .min(0)
                .max(99999)
                .nullable()
                .label(formFields.bundleNonBillableWeight.label);
                
            } else {
            return yup.number()
                .typeError('Invalid ' + formFields.bundleNonBillableWeight.label)
                .transform((value, originalValue) => {
                if (originalValue === undefined || originalValue === null || originalValue === '') {
                    return undefined;
                }
                return parseFloat(originalValue);
                })
                .test('maxDecimal', 'You can enter a maximum of 5 numbers with max 3 decimal places', val => {
                if (val === null || val === undefined) return true;
                    const strVal = val.toString();
                    const parts = strVal.split('.');
                if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 3)) {
                    return true;
                }
                return false;
                })
                .min(0)
                .max(99.999)
                .nullable()
                .label(formFields.bundleNonBillableWeight.label);
            }
        } else {
            return yup.number().nullable().min(0).label(formFields.bundleNonBillableWeight.label)
        }
    }),

    isBundled: yup.bool().oneOf([true, false]),
    bundleServices: yup.array().of(yup.object().shape({
        serviceTag: yup.string().required().label(formFields.bundleServiceTag.label),
        billingType: yup.number().nullable().min(0).label(formFields.bundleBillingType.label),

        // price: yup.number().nullable().min(0).required().label(formFields.bundlePrice.label),
        hasBillingPermission: yup.bool().oneOf([true, false]),
        price: yup.number().when('hasBillingPermission', {
            is: true,
            then: (schema) => schema.nullable().min(0).required().label(formFields.bundlePrice.label),
            otherwise: (schema) => schema.nullable().label(formFields.bundlePrice.label),
        }),
        isTaxInclusive: yup.bool().oneOf([true, false]),
        chargeCategoryId: yup.number().when('hasBillingPermission', {
            is: true,
            then: (schema) => schema.nullable().required().label(formFields.bundleChargeCategoryId.label),
            otherwise: (schema) => schema.nullable().label(formFields.bundleChargeCategoryId.label),
        }),
        
        useBinWeightQuantity: yup.bool().oneOf([true, false]).label(formFields.bundleUseBinWeightQuantity.label),
        // nonBillableWeight: yup.number().nullable().min(0).label(formFields.bundleNonBillableWeight.label)
        nonBillableWeight: yup.number().when(['useBinWeightQuantity'], (useBinWeightQuantity, schema) => {
            if(useBinWeightQuantity){
              if (Utils.getBillingUOM() === ReportUOM.kg ) {
                return yup.number()
                  .integer()
                  .test('noDecimal', 'Decimal values are not allowed', val => {
                      if (val !== undefined && val !== null) {
                          const strVal = val.toString();
                          return strVal.indexOf('.') === -1;
                      }
                      return true;
                  })
                  .min(0)
                  .max(99999)
                  .nullable()
                  .label(formFields.bundleNonBillableWeight.label);
                  
              } else {
                return yup.number()
                  .typeError('Invalid ' + formFields.bundleNonBillableWeight.label)
                  .transform((value, originalValue) => {
                    if (originalValue === undefined || originalValue === null || originalValue === '') {
                      return undefined;
                    }
                    return parseFloat(originalValue);
                  })
                  .test('maxDecimal', 'You can enter a maximum of 5 numbers with max 3 decimal places', val => {
                    if (val === null || val === undefined) return true;
                      const strVal = val.toString();
                      const parts = strVal.split('.');
                    if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 3)) {
                        return true;
                    }
                    return false;
                  })
                  .min(0)
                  .max(99.999)
                  .nullable()
                  .label(formFields.bundleNonBillableWeight.label);
              }
            } else {
                return yup.number().nullable().min(0).label(formFields.bundleNonBillableWeight.label)
            }
        })
    })),
});

  
class LocalServiceForm extends React.Component {
  
    constructor(props) {
        super(props);

        let customerId = Utils.getIntProps(props, 'customerId');

        this.pageName = 'local_service_form';
        this.backLink = '/Customer-overview-local-services?id=' + customerId;

        this.refForm = React.createRef();
        this.refInput = React.createRef();
        this.refBillingCategoryForm = React.createRef();
        this.defaultTaxRatePopupRef = React.createRef();

        let id = Utils.getIntProps(props);
        let isCopy = Utils.getBoolProps(props);

        this.panelWidth = '320px';


        this.defaultUOM = ReportUOM.tons;
        if(this.props.auth && this.props.auth.dispatchUser && this.props.auth.dispatchUser.defaultUoM){
            this.defaultUOM = this.props.auth.dispatchUser.defaultUoM;
        }
        
        let user = (this.props.auth && this.props.auth.user) ? this.props.auth.user : null;
        this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];

        let accountPermissions = (this.props.auth && this.props.auth.accountPermissions) ? this.props.auth.accountPermissions : null;
        this.accountPermissions = (accountPermissions && accountPermissions.length > 0) ? accountPermissions : [];


        this.bundleService = {
            serviceTag: '',
            billingType: BillingTypes.Invoice,
            hasBillingPermission: Utils.hasBillingPermission(this.accountPermissions),
            price: '',
            isTaxInclusive: false,
            chargeCategoryId: null,
            chargeCategoryName: '',
            useBinWeightQuantity: false,
            nonBillableWeight: '',
        };

        
        this.state = {
            customerId: customerId,

            id: id,
            isCopy: isCopy,
            details: null,

            popupShowBillingCategoryForm: false,
            popupLoadingBillingCategoryForm: false,
            popupIdBillingCategoryForm: 0,
            popupIdBillingCategoryObj: null,
            popupIsCopyBillingCategoryForm: false,
            popupIdBillingCategoryNewItem: null,
            
            isRemoveDialog: false,
            
            initialValues: {
                isEdit: false,
                isActive: true,
                serviceTag: '',
                wasteTypeId: null,
                wasteTypeName: '',
                binTypeId: null,
                binTypeName: '',
                description: '',
                billingType: BillingTypes.Invoice,
                hasBillingPermission: Utils.hasBillingPermission(this.accountPermissions),
                price: '',
                isTaxInclusive: false,
                chargeCategoryId: null,
                chargeCategoryName: '',

                isSelectAllSites: true,
                autoApplyToNewSites: true,
                customerSiteId: [],

                useBinWeightQuantity: false,
                nonBillableWeight: '',

                bundleServices: [],
                customFormParams: {
                    wasteTypeItems: [],
                    binTypeItems: [],
                    chargeCategoryItems: [],
                    serviceTagItems: [],
                    serviceTagIsLoading: false,

                    customerSiteItems: [],
                    customerSiteIsSelectAll: false,
                    customerSiteIsLoading: false,
                }
            },

            isLoadingCheckBillingCategory: false,
        };
    }


    async componentDidMount(){
        this.setLayoutForm();

        this.callDetailsApi(this.state.id, (details) => {
            if(details){
                this.setLayoutTitle(this.state.isCopy ? 'Copy Local Service' : 'Edit Local Service');
                this.prepareForm(details);
                this.setLoading(false);
            } else {
                this.setLayoutTitle('Add Local Service');
            }
        });
        
        await this.props.action.taxRate.getCustomerProfileApi(this.state.customerId);
        await this.props.action.taxRate.getDefaultTaxRateApi();
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-details', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-create', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );
    
        Utils.reduxProps(nextProps,
            this.pageName + '-update', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );
    
        Utils.reduxProps(nextProps,
            this.pageName + '-delete', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-copy', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-additional_charge', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.serviceItemTemplateId;
                        let title = item.serviceTag;
                        let arrItem = {
                            value: value,
                            title: title,
                            label: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(this.state.isError){
                        Utils.toast(this.state.err, 'error'); 
                    }
                
                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-service_item_check_name', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback();
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-customer_site', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = (variables && variables.items && variables.items.length > 0) ? variables.items : [];

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.customerSiteId;
                        let title = item.siteNameDisplay;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-service_item_check_billing_category',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoadingCheckBillingCategory: isLoading,
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (callback) {
                        callback(data);
                    }
                });
            }
        );
    }
	
    callDetailsApi = (id = null, callback = null) => {
        if(id){
            this.setLoading(true);
            this.props.dispatchApiCallGet(null, this.pageName + '-details', 'service-item/' + id, null, callback, () => {});
        } else {
            if(callback){
                callback(null)
            }
        }
    }

    callCreateApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallPost(data, this.pageName + '-create', 'service-item', data, callback, () => {});
    }

    callUpdateApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallPut(data, this.pageName + '-update', 'service-item', data, callback, () => {});
    }

    callDeleteApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallDelete(data, this.pageName + '-delete', 'service-item', data, callback, () => {});
    }

    callCopyApi = (data, callback = null) => {
        this.setLoading(true);

        data['isCopy'] = true;
        delete data['serviceItemId'];

        if(data && data.bundleServices && data.bundleServices.length > 0){
            for(let i = 0; i < data.bundleServices.length; i++){
                delete data.bundleServices[i]['serviceItemId'];
            }
        }
    
        this.props.dispatchApiCallPost(data, this.pageName + '-copy', 'service-item', data, callback, null);
    }

    callReadAdditionalServicesApi = (items, search = '', callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: 30,
            searchQuery: search,
            sortColumn: 'serviceTag',
            sortDir: 'desc',
            customerId: this.state.customerId,
            type: ServiceType.AdditionalService,
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-additional_charge', 'service-item-template', { items: items }, callback, () => {});
    }

    callChargeCheckNameApi = (serviceItemId = 0, serviceTag = '', callback = null) => {
        if(serviceTag !== ''){
            let data = {
                serviceItemId: serviceItemId,
                serviceTag: serviceTag,
                customerId: this.state.customerId,
            };
        
            this.props.dispatchApiCallPost(data, this.pageName + '-service_item_check_name', 'service-item/check-name', null, callback, () => {});
        }
    }

    callReadCustomerSiteApi = (items = [], callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'siteName',
            sortDir: 'asc',
            isIncludeInactive: false,
            isActive: true,
            includeServices: false,
            customerId: this.state.customerId,
        };
     
        this.props.dispatchApiCallGet(data,  this.pageName + '-customer_site', 'customersite/list', { items: items }, callback, () => {});
    }
    
    callCheckBillingCategoryApi = (wasteTypeId = null, binTypeId = null, callback = null) => {
        if (wasteTypeId !== null && binTypeId !== null) {
            this.setState({
                isLoadingCheckBillingCategory: true
            });

            let data = {
                wasteTypeId: wasteTypeId,
                binTypeId: binTypeId,
                billingProfileId: this.props.taxRate.accountBillingProfileId,
            };

            this.props.dispatchApiCallGet(data, this.pageName + '-service_item_check_billing_category', 'service-item/query', null, callback, () => { });
        }
    }
    /* END API */


    /* LAYOUT FUNCTIONS */
    setLayoutForm = (options) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutForm(this, options);
        }
    }
    setLayoutLoading = (state = false) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutLoading(state);
        }
    }
    setLayoutTitle = (title = '') => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setlayoutTitle(title);
        }
    }
    setLayoutSubmit = (form) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutSubmit(form);
        }
    }
    /* END LAYOUT FUNCTIONS */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        this.setLayoutLoading(state);

        if(this.props.onLoading){
            this.props.onLoading(state);
        }
    }
    prepareForm = (data) => {
        if(data){
            var newData = _.cloneDeep(data);

            let customFormParams = this.state.initialValues.customFormParams;


            let wasteTypeItem = (newData && newData.wasteType) ? newData.wasteType : null;
            let wasteTypeId = (wasteTypeItem && wasteTypeItem.wasteTypeId) ? wasteTypeItem.wasteTypeId : null;
            let wasteTypeName = (wasteTypeItem && wasteTypeItem.wasteTypeName) ? wasteTypeItem.wasteTypeName : '';
            let wasteTypeItems = (wasteTypeItem) ? [{
                value: wasteTypeId,
                title: wasteTypeName,
                item: wasteTypeItem,
            }] : [];

            let binTypeItem = (newData && newData.binType) ? newData.binType : null;
            let binTypeId = (binTypeItem && binTypeItem.binTypeId) ? binTypeItem.binTypeId : null;
            let binTypeName = (binTypeItem && binTypeItem.binTypeName) ? binTypeItem.binTypeName : '';
            let binTypeItems = (binTypeItem) ? [{
                value: binTypeId,
                title: binTypeName,
                item: binTypeItem,
            }] : [];
        
            let chargeCategoryItem = (newData && newData.chargeCategory) ? newData.chargeCategory : null;
            let chargeCategoryId = (chargeCategoryItem && chargeCategoryItem.chargeCategoryId) ? chargeCategoryItem.chargeCategoryId : null;
            let chargeCategoryName = (chargeCategoryItem && chargeCategoryItem.chargeCategoryName) ? chargeCategoryItem.chargeCategoryName : '';
            let chargeCategoryItems = (chargeCategoryItem) ? [{
                value: chargeCategoryId,
                title: chargeCategoryName,
                item: chargeCategoryItem,
            }] : [];

        
            let bundleServices = [];
            let bundleServicesItems = (newData && newData.bundleServices && newData.bundleServices.length > 0) ? newData.bundleServices : [];
            if(bundleServicesItems && bundleServicesItems.length > 0){
                bundleServices = bundleServicesItems.map((item, i) => {
                    let chargeCategoryItemChild = (item && item.chargeCategory) ? item.chargeCategory : null;
                    let chargeCategoryIdChild = (chargeCategoryItemChild && chargeCategoryItemChild.chargeCategoryId) ? chargeCategoryItemChild.chargeCategoryId : null;
                    let chargeCategoryNameChild = (chargeCategoryItemChild && chargeCategoryItemChild.chargeCategoryName) ? chargeCategoryItemChild.chargeCategoryName : '';
                    let chargeCategoryItemsChild = {
                        value: chargeCategoryIdChild,
                        title: chargeCategoryNameChild,
                        item: chargeCategoryItemChild,
                    }
                    
                    chargeCategoryItems = Utils.addToArrayIfNotExist(chargeCategoryItems, chargeCategoryIdChild, chargeCategoryItemsChild);

                    return {
                        serviceItemId: item.serviceItemId,
                        serviceTag: item.serviceTag,
                        billingType: item.billingType,
                        hasBillingPermission: Utils.hasBillingPermission(this.accountPermissions),
                        price: item.price,
                        isTaxInclusive: ((item.isTaxInclusive === false) || (item.isTaxInclusive === true)) ? item.isTaxInclusive : false,
                        chargeCategoryId: chargeCategoryIdChild,
                        chargeCategoryName: chargeCategoryNameChild,
                        useBinWeightQuantity: item.useBinWeightQuantity,
                        nonBillableWeight: item.nonBillableWeight,
                    }
                });
            }
        

            let customerSiteId = [];
            let customerSiteItems = [];
            if(newData.serviceItemCustomerSites && newData.serviceItemCustomerSites.length > 0){
                customerSiteItems = newData.serviceItemCustomerSites.map((item, i) => {
                    customerSiteId.push(item.customerSiteId);
          
                    return {
                      value: item.customerSiteId,
                      title: item.customerSite.siteNameDisplay,
                      item: item,
                    }
                  });
            }

            
            customFormParams.wasteTypeItems = wasteTypeItems;
            customFormParams.binTypeItems = binTypeItems;
            customFormParams.chargeCategoryItems = chargeCategoryItems;
            customFormParams.customerSiteItems = customerSiteItems;
            customFormParams.customerSiteIsSelectAll = (customerSiteId && customerSiteId.length > 0) ? false : true;
        
            let isSelectAllSites = ((newData.isSelectAllSites === false) || (newData.isSelectAllSites === true)) ? newData.isSelectAllSites : false;
            let autoApplyToNewSites = ((newData.autoApplyToNewSites === false) || (newData.autoApplyToNewSites === true)) ? newData.autoApplyToNewSites : false;

            let rowValues = {
                isEdit: (this.state.isCopy) ? false : true,
                isActive: ((newData.isActive === false) || (newData.isActive === true)) ? (newData.isActive ? ActiveInactive.ActiveValue : ActiveInactive.InactiveValue) : ActiveInactive.InactiveValue,
                isBundled: ((newData.isBundled === false) || (newData.isBundled === true)) ? newData.isBundled : false,

                billingType: newData.billingType,

                serviceTag: (newData && newData.serviceTag && newData.serviceTag !== '') ? newData.serviceTag + (this.state.isCopy ? ' - copy' : '') : '',
                description: (newData && newData.description && newData.description !== '') ? newData.description : '',

                hasBillingPermission: Utils.hasBillingPermission(this.accountPermissions),
                price: (newData && newData.price && newData.price !== '' || newData.price == '0') ? newData.price : '',
                isTaxInclusive: ((newData.isTaxInclusive === false) || (newData.isTaxInclusive === true)) ? newData.isTaxInclusive : false,

                wasteTypeId: wasteTypeId,
                wasteTypeName: wasteTypeName,
                binTypeId: binTypeId,
                binTypeName: binTypeName,
                chargeCategoryId: chargeCategoryId,
                chargeCategoryName: chargeCategoryName,

                customerSiteId: customerSiteId,
                isSelectAllSites: isSelectAllSites,
                autoApplyToNewSites: isSelectAllSites ? true : autoApplyToNewSites,

                useBinWeightQuantity: ((newData.useBinWeightQuantity === false) || (newData.useBinWeightQuantity === true)) ? newData.useBinWeightQuantity : false,
                nonBillableWeight: (newData && newData.nonBillableWeight && newData.nonBillableWeight !== '' || newData.nonBillableWeight == '0') ? newData.nonBillableWeight : '',
                
                bundleServices: bundleServices,

                customFormParams: customFormParams,
            };
        
            this.setState({
                initialValues: rowValues,
                details: newData,
            });
        }
    }
    prepareData = (form) => {
        var newForm = _.cloneDeep(form);

        // let customerSiteIsSelectAll = ((form.customFormParams.customerSiteIsSelectAll === false) || (form.customFormParams.customerSiteIsSelectAll === true)) ? form.customFormParams.customerSiteIsSelectAll : false;
    
        let serviceItemCustomerSites = [];
        if(form.customerSiteId && form.customerSiteId.length > 0) {
            serviceItemCustomerSites = form.customerSiteId.map((x, i) => ({
                customerSiteId: x,
            }));
        }
        

        let wasteTypeItem = null;
        let wasteTypeId = newForm.wasteTypeId;
        if(wasteTypeId){
            wasteTypeItem = {
                wasteTypeId: wasteTypeId,
                wasteTypeName: newForm.wasteTypeName,
            }
        }

        let binTypeItem = null;
        let binTypeId = newForm.binTypeId;
        if(binTypeId){
            binTypeItem = {
                binTypeId: binTypeId,
                binTypeName: newForm.binTypeName,
            }
        }

        let chargeCategoryItem = null;
        let chargeCategoryId = newForm.chargeCategoryId;
        if(chargeCategoryId){
            chargeCategoryItem = {
                chargeCategoryId: chargeCategoryId,
                chargeCategoryName: newForm.chargeCategoryName,
            }
        }

        let bundleServices = [];
        if(newForm.bundleServices && newForm.bundleServices.length > 0){
            bundleServices = newForm.bundleServices.map((item, i) => {
                item['price'] = (item['price'] != '') ? item['price'] : 0;
                return item;
            })
        }

        let data = {
            customerId: this.state.customerId,

            isActive: (newForm.isActive == ActiveInactive.ActiveValue) ? true : false,
            isBundled: newForm.isBundled,

            serviceTag: newForm.serviceTag,
            description: newForm.description,
            price: (newForm.price != '') ? newForm.price : 0,
            isTaxInclusive: ((newForm.isTaxInclusive === false) || (newForm.isTaxInclusive === true)) ? newForm.isTaxInclusive : false,
            billingType: newForm.billingType,

            wasteTypeId: wasteTypeId,
            wasteType: wasteTypeItem,
            binTypeId: binTypeId,
            binType: binTypeItem,
            chargeCategoryId: chargeCategoryId,
            // chargeCategory: chargeCategoryItem,

            serviceItemCustomerSites: serviceItemCustomerSites,
            isSelectAllSites: form.isSelectAllSites,
            autoApplyToNewSites: form.autoApplyToNewSites,

            useBinWeightQuantity: newForm.useBinWeightQuantity,
            nonBillableWeight: newForm.nonBillableWeight,

            bundleServices: bundleServices,
        };

        if ((!this.state.isCopy) && this.state.id > 0) {
            data['serviceItemId'] = this.state.id;
        }

        return data;
    }
    setSubmit = (form) => {
        this.setLayoutSubmit(form);

        if(this.props.onSubmit){
            this.props.onSubmit(form);
        }
    }

    handleRemove = () => {
        this.setState({
            isRemoveDialog: true,
        });
    }
    handleCopy = () => {
        if(this.props.onCopy){
            this.props.onCopy();
        }
    }

    addBillingCategory = async (formOptions, data) => {
        if(data){
            let {
                setFieldValue,
            } = formOptions;

            let chargeCategory = (data && data.chargeCategory) ? data.chargeCategory : null;
            let chargeCategoryId = (chargeCategory) ? chargeCategory.chargeCategoryId : null;
            let chargeCategoryName = (chargeCategory) ? chargeCategory.chargeCategoryName : '';
            
            await setFieldValue('chargeCategoryId', chargeCategoryId);
            await setFieldValue('chargeCategoryName', chargeCategoryName);
            
            let billingType = (data) ? data.billingType : 0;
            let useBinWeightQuantity = (data) ? data.useBinWeightQuantity : false;

            await setFieldValue('billingType', billingType);
            await setFieldValue('useBinWeightQuantity', useBinWeightQuantity);

            let invoiceTaxRate = (chargeCategory) ? chargeCategory.invoiceTaxRate : null;
            let purchaseTaxRate = (chargeCategory) ? chargeCategory.purchaseTaxRate : null;
            this.props.action.taxRate.setMainTaxRate({ invoiceTaxRate: invoiceTaxRate, purchaseTaxRate: purchaseTaxRate });
        }
    }

    checkIsNotTaxRateBetween0and100 = (value) => {
        const numericValue = parseFloat(value || '0');
        if (numericValue >= 0 && numericValue <= 100) {
          return false
        } else {
          return true
        }
    }
    /* END FUNCTIONS */


    /* FORM */
    form = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>

            {/* {(values.isEdit) && <InputLayout
                label={formFields.isActive.label}
                inputProps={{
                    xs: 12,
                    lg: 6
                }}
            >
                <Form.Control
                    as = {SmartInputDropdown}
                    isInvalid={false}
                    errorText={''}
        
                    value={values.isActive ? ActiveInactive.ActiveValue : ActiveInactive.InactiveValue}
                    label={values.isActive ? ActiveInactive.ActiveTitle : ActiveInactive.InactiveTitle}
                    options={[
                        {
                            value: ActiveInactive.InactiveValue,
                            title: ActiveInactive.InactiveTitle,
                            color: '#FF0090',
                        },
                        {
                            value: ActiveInactive.ActiveValue,
                            title: ActiveInactive.ActiveTitle,
                            color: '#185cff',
                        }
                    ]}
                    placeholder={formFields.isActive.placeholder}
                    disabled={false}
                    isLoading={false}
                    showSearch={false}
                    showClear={false}
                    showFooter={false}
                    isInfiniteScroll={false}

                    onChange={async (value, item, i) => {
                        await setFieldValue('isActive', value);
                    }}
                />
            </InputLayout>} */}

            <InputLayout
                label={formFields.serviceTag.label}
                required
                inputProps={{
                    xs: 12,
                    lg: 6
                }}
            >
                <Form.Control
                    type="text"
                    id={formFields.serviceTag.id}
                    placeholder={formFields.serviceTag.placeholder}
                    value={values.serviceTag}
                    onChange={handleChange}
                    onBlur={() => {
                        this.callChargeCheckNameApi(values.serviceItemId, values.serviceTag);
                    }}
                    isInvalid={!!errors.serviceTag}
                />
                <Form.Control.Feedback type="invalid">{errors.serviceTag}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.wasteTypeId.label}
                inputProps={{
                    xs: 12,
                    lg: 6
                }}
            >
                <SmartDropdown
                    isInvalid={!!errors.wasteTypeId}
                    errorText={errors.wasteTypeId}

                    componentTitle={'wasteType'}
                    componentApi={'wasteType'}
                    componentId={'wasteTypeId'}
                    componentName={'wasteTypeName'}

                    placeholder={formFields.wasteTypeId.placeholder}
                    value={values.wasteTypeId}
                    label={values.wasteTypeName}

                    disabled={false}
                    showDefault={false}
                    isInfiniteScroll={false}

                    onChange={async (value, item, i) => {
                        let oldServiceTagName = Utils.getServiceTagName(values.wasteTypeName, values.binTypeName);

                        await setFieldValue('wasteTypeId', value);
                        await setFieldValue('wasteTypeName', item.title);
                        
                        if(values.binTypeId){
                            let serviceTag = (values.serviceTag && values.serviceTag != '') ? values.serviceTag : '';
                            serviceTag = serviceTag.replace(oldServiceTagName, '').replace(/\s+/g, ' ').trim();
                            let serviceTagName = Utils.getServiceTagName(item.title, values.binTypeName);
                            let serviceName = Utils.getServiceName(serviceTag, serviceTagName, values.binTypeId, value);
                            this.callChargeCheckNameApi(values.serviceItemId, serviceName);
                            this.callCheckBillingCategoryApi(value, values.binTypeId, (data) => {
                                this.addBillingCategory(formOptions, data);
                            });
                            await setFieldValue('serviceTag', serviceName);
                        }
                    }}
                    onClear={async () => {
                        await setFieldValue('wasteTypeId', null);
                        await setFieldValue('wasteTypeName', '');
                        await setFieldValue('serviceTag', '');
                    }}
                />
            </InputLayout>
            
            <InputLayout
                label={formFields.binTypeId.label}
                inputProps={{
                    xs: 12,
                    lg: 6
                }}
            >
                <SmartDropdown
                    isInvalid={!!errors.binTypeId}
                    errorText={errors.binTypeId}

                    componentTitle={'binType'}
                    componentApi={'binType'}
                    componentId={'binTypeId'}
                    componentName={'binTypeName'}

                    placeholder={formFields.binTypeId.placeholder}
                    value={values.binTypeId}
                    label={values.binTypeName}

                    disabled={false}
                    showDefault={false}
                    isInfiniteScroll={false}

                    onChange={async (value, item, i) => {
                        let oldServiceTagName = Utils.getServiceTagName(values.wasteTypeName, values.binTypeName);

                        await setFieldValue('binTypeId', value);
                        await setFieldValue('binTypeName', item.title);
                        
                        if(values.wasteTypeId){
                            let serviceTag = (values.serviceTag && values.serviceTag != '') ? values.serviceTag : '';
                            serviceTag = serviceTag.replace(oldServiceTagName, '').replace(/\s+/g, ' ').trim();
                            let serviceTagName = Utils.getServiceTagName(values.wasteTypeName, item.title);
                            let serviceName = Utils.getServiceName(serviceTag, serviceTagName, value, values.wasteTypeId);
                            this.callChargeCheckNameApi(values.serviceItemId, serviceName);
                            this.callCheckBillingCategoryApi(values.wasteTypeId, value, (data) => {
                                this.addBillingCategory(formOptions, data);
                            });
                            await setFieldValue('serviceTag', serviceName);
                        }
                    }}
                    onClear={async () => {
                        await setFieldValue('binTypeId', null);
                        await setFieldValue('binTypeName', '');
                        await setFieldValue('serviceTag', '');
                    }}
                />
            </InputLayout>

            <InputLayout
                label={formFields.description.label}
                inputProps={{
                    xs: 12,
                    lg: 6
                }}
            >
                <Form.Control
                    as="textarea"
                    rows={5}
                    id={formFields.description.id}
                    placeholder={formFields.description.placeholder}
                    value={values.description}
                    onChange={async (e) => {
                        Utils.limitRows(e.target.value, 5, async (value) => {
                            await setFieldValue('description', value);
                        });
                    }}
                    isInvalid={!!errors.description}
                />
                <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
            </InputLayout>
            
            {values.hasBillingPermission && <>
                <InputLayout
                    label={formFields.chargeCategoryId.label}
                    required={true}
                    inputProps={{
                        xs: 12,
                        lg: 6
                    }}
                >
                    <SmartDropdown
                        isInvalid={!!errors.chargeCategoryId}
                        errorText={errors.chargeCategoryId}

                        componentTitle={'chargeCategory'}
                        componentApi={'chargeCategory'}
                        componentId={'chargeCategoryId'}
                        componentName={'chargeCategoryName'}
                        // componentData={(values.binTypeId && values.wasteTypeId) ? { useBinWeightQuantity: false } : null}
                        componentData={(values.binTypeId && values.wasteTypeId) ? { includeTaxRate: true, billingProfileId: this.props.taxRate.accountBillingProfileId } : { includeTaxRate: true, billingProfileId: this.props.taxRate.accountBillingProfileId }}
                        customName={'0'}

                        placeholder={formFields.chargeCategoryId.placeholder}
                        value={values.chargeCategoryId}
                        label={values.chargeCategoryName}

                        disabled={this.state.isLoadingCheckBillingCategory}
                        showDefault={false}
                        showFooter={Utils.hasBillingCategoryPermission(this.permissions)}
                        isInfiniteScroll={false}

                        isCreateUpdatePopup={true}
                        onClickAdd={async (obj, search) => {
                            Utils.clickOutside();
                            this.setState({
                                popupShowBillingCategoryForm: true,
                                popupLoadingBillingCategoryForm: false,
                                popupIdBillingCategoryForm: 0,
                                popupIdBillingCategoryObj: obj,
                                popupIsCopyBillingCategoryForm: false,
                                popupIdBillingCategoryNewItem: search,
                            });
                        }}
                        onClickEdit={async (item, obj) => {
                            Utils.clickOutside();
                            this.setState({
                                popupShowBillingCategoryForm: true,
                                popupLoadingBillingCategoryForm: false,
                                popupIdBillingCategoryForm: item.value,
                                popupIdBillingCategoryObj: obj,
                                popupIsCopyBillingCategoryForm: false,
                                popupIdBillingCategoryNewItem: null,
                            });
                        }}

                        onChange={async (value, item, i) => {
                            await setFieldValue('chargeCategoryId', value);
                            await setFieldValue('chargeCategoryName', item.title);
                            
                            let billingType = (item && item.item) ? item.item.billingType : 0;
                            let useBinWeightQuantity = (item && item.item) ? item.item.useBinWeightQuantity : false;

                            await setFieldValue('billingType', billingType);
                            await setFieldValue('useBinWeightQuantity', useBinWeightQuantity);

                            let invoiceTaxRate = (item && item.item) ? item.item.invoiceTaxRate : null;
                            let purchaseTaxRate = (item && item.item) ? item.item.purchaseTaxRate : null;
                            this.props.action.taxRate.setMainTaxRate({ invoiceTaxRate: invoiceTaxRate, purchaseTaxRate: purchaseTaxRate });
                        }}
                        onClear={async () => {
                            await setFieldValue('chargeCategoryId', null);
                            await setFieldValue('chargeCategoryName', '');
                            await setFieldValue('useBinWeightQuantity', false);
                            await setFieldValue('nonBillableWeight', '');

                            this.props.action.taxRate.setMainTaxRate({ invoiceTaxRate: null, purchaseTaxRate: null });
                        }}
                    />
                </InputLayout>

                <InputLayout
                    label={formFields.price.label}
                    required={true}
                    inputProps={{
                        xs: 12,
                        lg: 6,
                    }}
                    cols={<Row>
                        {values.useBinWeightQuantity && <Col xs={12} lg={'auto'}>
                            <div
                                style={{
                                    display: 'block',
                                    paddingTop: '12px',
                                    paddingBottom: '10px',
                                }}
                            >
                                {(values.useBinWeightQuantity) ? (Utils.getBillingUOM() == ReportUOM.kg) ? 'Per kg' : 'Per ton(s)' : ''}
                            </div>
                        </Col>}
                        <Col xs={12} lg={'auto'}>
                            <Form.Check
                                className={'mb-2 mb-lg-0 mt-0 mt-lg-4 pt-4 pt-lg-0 d-inline-block'}
                                id={formFields.isTaxInclusive.id}
                                label={formFields.isTaxInclusive.label}
                                checked={values.isTaxInclusive}
                                onChange={handleChange}
                                disabled={!Utils.isNumberAvoidZero(values.price)}
                            />
                            {
                                this.props.taxRate.isConnected
                                ?
                                <>
                                    <Tooltip title={'Company settings'} className={'dark-tooltip mb-3'}>
                                        <a href={this.props.taxRate.accountBillingProfileId ? '/manage/profile/company?id=' + this.props.taxRate.accountBillingProfileId + '&selectedTab=1' : '/manage/profiles'}
                                            target={'_blank'}
                                            rel="noopener noreferrer"
                                            className={'link-main'}
                                            style={{
                                                position: 'relative',
                                                top: '5px',
                                                left: '10px',
                                            }}
                                        >
                                            <span className={'material-icons'} style={{ fontSize: '18px' }}>settings</span>
                                        </a>
                                    </Tooltip>
                                    {!this.props.taxRate.isLoading && <span className="ps-5">Tax rate is {(values.billingType === BillingTypes.Purchase) ? this.props.taxRate.purchaseTaxRate : this.props.taxRate.invoiceTaxRate}%</span>}
                                </>
                                :
                                <>
                                    <Tooltip title={'Set default tax rate'} className={'dark-tooltip mb-3'}>
                                        <a href={'/'}
                                            className={'link-main-icon ' + (this.props.taxRate.showSettings ? 'active' : '')}
                                            style={{
                                                position: 'relative',
                                                top: '5px',
                                                left: '10px',
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();

                                                // this.props.action.taxRate.setShowSettings(!this.props.taxRate.showSettings);
                                                if(this.defaultTaxRatePopupRef && this.defaultTaxRatePopupRef.current){
                                                    this.defaultTaxRatePopupRef.current.show(e, true);
                                                }
                                            }}
                                        >
                                            <span className={'material-icons'} style={{ fontSize: '18px' }}>settings</span>
                                        </a>
                                    </Tooltip>
                                    {!this.props.taxRate.isLoading && <span className="ps-5">Tax rate is {this.props.taxRate.defaultTaxRate}%</span>}
                                </>
                            }
                        </Col>
                    </Row>}
                >
                    <InputGroup>
                        <StyledDropdownButton
                            variant="outline-secondary"
                            title={(values.billingType === BillingTypes.Purchase) ? 'Purchase' : (values.billingType === BillingTypes.Expense) ? 'Expense' : 'Invoice'}
                            id={formFields.billingType.id}
                            disabled={(values.chargeCategoryId) ? true : false}
                        >
                            <Dropdown.Item
                                active={(values.billingType === BillingTypes.Invoice)}
                                onClick={async () => {
                                    await setFieldValue('billingType', BillingTypes.Invoice);
                                }}
                            >Invoice</Dropdown.Item>
                            <Dropdown.Item
                                active={(values.billingType === BillingTypes.Purchase)}
                                onClick={async () => {
                                    await setFieldValue('billingType', BillingTypes.Purchase);
                                }}
                            >Purchase</Dropdown.Item>
                            <Dropdown.Item
                                active={(values.billingType === BillingTypes.Expense)}
                                onClick={async () => {
                                    await setFieldValue('billingType', BillingTypes.Expense);
                                }}
                            >Expense</Dropdown.Item>
                        </StyledDropdownButton>
                        <Form.Control
                            as = {CurrencyInput}
                            isInvalid={!!errors.price}
                            
                            allowDecimals={true}
                            allowNegativeValue={false}
                            disableGroupSeparators={true}
                            prefix={Utils.getCurrency()}

                            id={formFields.price.id}
                            placeholder={formFields.price.placeholder}
                            disabled={this.state.isLoadingCheckBillingCategory}
                            value={values.price}
                            onValueChange={async (value) => {
                                await setFieldValue('price', value);
                                                                    
                                if(!Utils.isNumberAvoidZero(value)){
                                    await setFieldValue('isTaxInclusive', false);
                                }
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
                        <div className={'text-danger w-100 ' + ((values.isTaxInclusive && Utils.isNumber(values.price)) ? 'd-block' : 'd-none')} style={{ position: 'relative', left: '100px' }}>{taxRate.getTaxRate(values.isTaxInclusive, values, this.props.taxRate)}</div>
                    </InputGroup>
                </InputLayout>
            </>}
            
            <InputLayout
                label={formFields.bundleUseBinWeightQuantity.label}
                labelProps={{
                    xs: 'auto',
                    lg: 3,
                }}
                inputProps={{
                    xs: 'auto',
                    lg: 6,
                    className: 'd-flex align-items-center',
                }}
            >
                <div>{values.useBinWeightQuantity ? 'Yes' : 'No'}</div>
            </InputLayout>
            
            {values.useBinWeightQuantity && <InputLayout
                label={formFields.bundleNonBillableWeight.label}
                inputProps={{
                    xs: 12,
                    lg: 6
                }}
            >
                <InputGroup>
                    <InputGroup.Text>First</InputGroup.Text>
                    <Form.Control
                        type="number"
                        id={formFields.bundleNonBillableWeight.id}
                        placeholder={formFields.bundleNonBillableWeight.placeholder}
                        disabled={this.state.isLoadingCheckBillingCategory}
                        value={values.nonBillableWeight}
                        onChange={async (e) => {
                            await setFieldValue('nonBillableWeight', e.target.value);
                        }}
                        isInvalid={!!(errors && errors.nonBillableWeight)}
                    />
                    <InputGroup.Text>{(Utils.getBillingUOM() == ReportUOM.kg) ? 'kg' : 'ton(s)'}</InputGroup.Text>
                    <Form.Control.Feedback type="invalid">{(errors && errors.nonBillableWeight) && errors.nonBillableWeight}</Form.Control.Feedback>
                </InputGroup>
            </InputLayout>}
            
            <InputLayout
                label={formFields.isSelectAllSites.label}
                inputProps={{
                    xs: 12,
                    lg: 6,
                    className: 'mt-3'
                }}
            >
                <Row className={'align-items-center'}>
                    <Col xs={12} md={true} className={'mb-5 mb-md-0'}>
                        <Form.Check
                            inline
                            type={'radio'}
                            id={formFields.isSelectAllSites.id + '_1'}
                            label={formFields.isSelectAllSites.labelTrue}
                            checked={values.isSelectAllSites == true}
                            onChange={async (e) => {
                                await setFieldValue('isSelectAllSites', true);
                                await setFieldValue('customerSiteId', []);
                                
                                await setFieldValue('autoApplyToNewSites', true);
                            }}
                        />
                        <Form.Check
                            inline
                            type={'radio'}
                            id={formFields.isSelectAllSites.id + '_2'}
                            label={formFields.isSelectAllSites.labelFalse}
                            checked={values.isSelectAllSites == false}
                            onChange={async (e) => {
                                await setFieldValue('isSelectAllSites', false);
                            }}
                        />
                    </Col>
                    <Col xs={12} md={'auto'}>
                        <Form.Check
                            inline
                            type={'checkbox'}
                            id={formFields.autoApplyToNewSites.id + '_3'}
                            label={formFields.autoApplyToNewSites.label}
                            disabled={values.isSelectAllSites}
                            checked={values.autoApplyToNewSites}
                            onChange={async (e) => {
                                await setFieldValue('autoApplyToNewSites', e.target.checked);
                            }}
                        />
                    </Col>
                </Row>
            </InputLayout>

            {!values.isSelectAllSites && <InputLayout
                label={<></>}
                inputProps={{
                    xs: 12,
                    lg: 6
                }}
            >
                <Form.Control
                    as = {RSuiteCheckPicker}
                    isInvalid={!!errors.customerSiteId}
                    
                    id={formFields.customerSiteId.id}
                    placeholder={formFields.customerSiteId.placeholder}
                    items={values.customFormParams.customerSiteItems}
                    value={values.customerSiteId}
                    // isSelectedAll={values.customFormParams.customerSiteIsSelectAll}
                    isLoading={values.customFormParams.customerSiteIsLoading}
                    searchable={true}
                    countable={true}
                    cleanable={true}
                    // showSelectAll={true}
                    isDefault={true}
                    placement={'topStart'}
                    onClick={async () => {
                        await setFieldValue('customFormParams.customerSiteIsLoading', true);
                        this.callReadCustomerSiteApi(values.customFormParams.customerSiteItems, async (items) => {
                            await setFieldValue('customFormParams.customerSiteItems', items);
                            await setFieldValue('customFormParams.customerSiteIsLoading', false);
                        });
                    }}
                    onSelectAll={async (checked, isIndeterminate) => {
                        if(checked){
                            await setFieldValue('customerSiteId', values.customFormParams.customerSiteItems.map(x => x.value));
                            await setFieldValue('customFormParams.customerSiteIsSelectAll', true);
                        } else {
                            await setFieldValue('customerSiteId', []);
                            await setFieldValue('customFormParams.customerSiteIsSelectAll', false);
                        }
                    }}
                    onChange={async (values, items) => {
                        await setFieldValue('customerSiteId', values);
                        
                        if(items.length > 0){
                            await setFieldValue('customFormParams.customerSiteIsSelectAll', (values.length === items.length));
                        } else {
                            await setFieldValue('customFormParams.customerSiteIsSelectAll', false);
                        }
                    }}
                    onClear={async () => {
                        await setFieldValue('customerSiteId', []);
                        await setFieldValue('customFormParams.customerSiteIsSelectAll', false);
                    }}
                />
                <Form.Control.Feedback type="invalid">{errors.customerSiteId}</Form.Control.Feedback>
            </InputLayout>}

        </>
    }

    bundleServicesForm = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>
        
            <FieldArray 
                name={formFields.bundleServices.id}
                validateOnChange={false}
            >
                {({ remove, push }) => (
                    <>
                        <div className={'mb-10'}>
                            {
                                (values.bundleServices && values.bundleServices.length > 0)
                                ?
                                values.bundleServices.map((item, i) => {
                                    return <Row key={i} className={'mb-5'}>
                                        <Col xs={12}>

                                            <InputLayout
                                                label={formFields.bundleServiceTag.label + ' ' + (i+1)}
                                                required
                                                inputProps={{
                                                    xs: 12,
                                                    lg: 6,
                                                }}
                                                colProps={{
                                                    style: {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }
                                                }}
                                                cols={<a href={'/'} 
                                                    className={'link-default-underline'}
                                                    style={{ 
                                                        display: 'block',
                                                        paddingTop: '10px',
                                                        paddingBottom: '10px',
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        remove(i);
                                                    }}
                                                >Remove</a>}
                                            >
                                                <SmartDropdown
                                                    isInvalid={!!(errors && errors.bundleServices && errors.bundleServices[i] && errors.bundleServices[i].serviceTag)}
                                                    errorText={(errors && errors.bundleServices && errors.bundleServices[i] && errors.bundleServices[i].serviceTag) && errors.bundleServices[i].serviceTag}

                                                    componentTitle={'serviceItemTemplate'}
                                                    componentApi={'service-item-template'}
                                                    componentId={'serviceItemTemplateId'}
                                                    componentName={'serviceTag'}
                                                    componentData={{
                                                        type: ServiceType.AdditionalService,
                                                    }}
                                                    customName={'' + (i+1)}
                                                    caption={'History'}

                                                    placeholder={formFields.bundleServiceTag.placeholder}
                                                    value={item.serviceTag}
                                                    label={item.serviceTag}

                                                    disabled={false}
                                                    showDefault={false}
                                                    showFooter={true}
                                                    showAdd={false}
                                                    showEdit={false}
                                                    isInfiniteScroll={false}
                                                    isFreeText={true}

                                                    itemRender={(label, item, keyLabel) => {
                                                        return <>
                                                            <i className={'material-icons'}>history</i>
                                                            <span className={'ps-2 align-items-center d-flex'}>{label}</span>
                                                        </>
                                                    }}

                                                    onChange={async (value, item, j) => {
                                                        await setFieldValue('bundleServices.' + i + '.serviceTag', item.title);

                                                        let chargeCategory = (item && item.item && item.item.chargeCategory) ? item.item.chargeCategory : null;
                                                        let chargeCategoryId = (chargeCategory && chargeCategory.chargeCategoryId) ? chargeCategory.chargeCategoryId : null;
                                                        let chargeCategoryName = (chargeCategory && chargeCategory.chargeCategoryName) ? chargeCategory.chargeCategoryName : '';

                                                        let chargeCategoryItems = values.customFormParams.chargeCategoryItems;
                                                        if (chargeCategory) {
                                                            let chargeCategoryItem = {
                                                                value: chargeCategoryId,
                                                                title: chargeCategoryName,
                                                                item: chargeCategory,
                                                            };

                                                            chargeCategoryItems = Utils.addToArray(chargeCategoryItems, chargeCategoryId, chargeCategoryItem);

                                                            await setFieldValue('customFormParams.chargeCategoryItems', chargeCategoryItems);
                                                            await setFieldValue('bundleServices.' + i + '.chargeCategoryId', chargeCategoryId);
                                                            await setFieldValue('bundleServices.' + i + '.chargeCategoryName', chargeCategoryName);
                                                                
                                                                
                                                            let billingType = (chargeCategory) ? chargeCategory.billingType : 0;
                                                            let useBinWeightQuantity = (chargeCategory) ? chargeCategory.useBinWeightQuantity : false;

                                                            await setFieldValue('bundleServices.' + i + '.billingType', billingType);
                                                            await setFieldValue('bundleServices.' + i + '.useBinWeightQuantity', useBinWeightQuantity);
                                                        }


                                                        let billingType = (item && item.item) ? item.item.billingType : null;
                                                        if(billingType){
                                                            await setFieldValue('bundleServices.' + i + '.billingType', billingType);
                                                        }
                                                    }}
                                                    onClear={async () => {
                                                        await setFieldValue('bundleServices.' + i + '.serviceTag', '');
                                                    }}
                                                />
                                            </InputLayout>
                                            
                                            {item.hasBillingPermission && <>
                                                <InputLayout
                                                    label={formFields.bundleChargeCategoryId.label}
                                                    required={true}
                                                    inputProps={{
                                                        xs: 12,
                                                        lg: 6
                                                    }}
                                                    // colProps={{
                                                    //     style: {
                                                    //         display: 'flex',
                                                    //         alignItems: 'center',
                                                    //     }
                                                    // }}
                                                    // cols={<a href={'/'} 
                                                    //     className={'link-default-underline'}
                                                    //     style={{ 
                                                    //         display: 'block',
                                                    //         paddingTop: '10px',
                                                    //         paddingBottom: '10px',
                                                    //         }}
                                                    //     onClick={(e) => {
                                                    //         e.preventDefault();
                                                    //         e.stopPropagation();

                                                    //     }}
                                                    // >Manage Category</a>}
                                                >
                                                    <SmartDropdown
                                                        isInvalid={!!(errors && errors.bundleServices && errors.bundleServices[i] && errors.bundleServices[i].chargeCategoryId)}
                                                        errorText={(errors && errors.bundleServices && errors.bundleServices[i] && errors.bundleServices[i].chargeCategoryId) && errors.bundleServices[i].chargeCategoryId}

                                                        componentTitle={'chargeCategory'}
                                                        componentApi={'chargeCategory'}
                                                        componentId={'chargeCategoryId'}
                                                        componentName={'chargeCategoryName'}
                                                        customName={'' + (i+1)}

                                                        placeholder={formFields.bundleChargeCategoryId.placeholder}
                                                        value={item.chargeCategoryId}
                                                        label={item.chargeCategoryName}

                                                        disabled={false}
                                                        showDefault={false}
                                                        showFooter={Utils.hasBillingCategoryPermission(this.permissions)}
                                                        isInfiniteScroll={false}

                                                        isCreateUpdatePopup={true}
                                                        onClickAdd={async (obj, search) => {
                                                            Utils.clickOutside();
                                                            this.setState({
                                                                popupShowBillingCategoryForm: true,
                                                                popupLoadingBillingCategoryForm: false,
                                                                popupIdBillingCategoryForm: 0,
                                                                popupIdBillingCategoryObj: obj,
                                                                popupIsCopyBillingCategoryForm: false,
                                                                popupIdBillingCategoryNewItem: search,
                                                            });
                                                        }}
                                                        onClickEdit={async (item, obj) => {
                                                            Utils.clickOutside();
                                                            this.setState({
                                                                popupShowBillingCategoryForm: true,
                                                                popupLoadingBillingCategoryForm: false,
                                                                popupIdBillingCategoryForm: item.value,
                                                                popupIdBillingCategoryObj: obj,
                                                                popupIsCopyBillingCategoryForm: false,
                                                                popupIdBillingCategoryNewItem: null,
                                                            });
                                                        }}

                                                        onChange={async (value, itemC, j) => {
                                                            await setFieldValue('bundleServices.' + i + '.chargeCategoryId', value);
                                                            await setFieldValue('bundleServices.' + i + '.chargeCategoryName', itemC.title);

                                                            let billingType = (itemC && itemC.item) ? itemC.item.billingType : 0;
                                                            let useBinWeightQuantity = (itemC && itemC.item) ? itemC.item.useBinWeightQuantity : false;

                                                            await setFieldValue('bundleServices.' + i + '.billingType', billingType);
                                                            await setFieldValue('bundleServices.' + i + '.useBinWeightQuantity', useBinWeightQuantity);
                                                                
                                                            let invoiceTaxRate = (itemC && itemC.item) ? itemC.item.invoiceTaxRate : null;
                                                            let purchaseTaxRate = (itemC && itemC.item) ? itemC.item.purchaseTaxRate : null;
                                                            this.props.action.taxRate.setTaxRate({ index: value, invoiceTaxRate: invoiceTaxRate, purchaseTaxRate: purchaseTaxRate });
                                                        }}
                                                        onClear={async () => {
                                                            await setFieldValue('bundleServices.' + i + '.chargeCategoryId', null);
                                                            await setFieldValue('bundleServices.' + i + '.chargeCategoryName', '');
                                                            
                                                            await setFieldValue('bundleServices.' + i + '.useBinWeightQuantity', false);

                                                            this.props.action.taxRate.setTaxRate({ index: null, invoiceTaxRate: null, purchaseTaxRate: null });
                                                        }}
                                                    />
                                                </InputLayout>

                                                <InputLayout
                                                        label={formFields.bundlePrice.label}
                                                        required={true}
                                                        inputProps={{
                                                            xs: 12,
                                                            lg: 6
                                                        }}
                                                        cols={<Row className={'align-items-center'}>
                                                            {item.useBinWeightQuantity && <Col xs={12} lg={'auto'}>
                                                                <div
                                                                    style={{
                                                                        display: 'block',
                                                                        paddingTop: '12px',
                                                                        paddingBottom: '10px',
                                                                    }}
                                                                >
                                                                    {(item.useBinWeightQuantity) ? (Utils.getBillingUOM() == ReportUOM.kg) ? 'Per kg' : 'Per ton(s)' : ''}
                                                                </div>
                                                            </Col>}
                                                            <Col xs={12} lg={'auto'}>
                                                                <Form.Check
                                                                    className={'mb-2 mb-lg-0 mt-0 mt-lg-1 pt-4 pt-lg-0 ' + ((item.useBinWeightQuantity) ? 'ps-lg-12' : '')}
                                                                    id={formFields.bundleIsTaxInclusive.id + '_' + i}
                                                                    label={formFields.bundleIsTaxInclusive.label}
                                                                    checked={item.isTaxInclusive}
                                                                    onChange={async (e) => {
                                                                        await setFieldValue('bundleServices.' + i + '.isTaxInclusive', e.target.checked);
                                                                    }}
                                                                    disabled={!Utils.isNumberAvoidZero(item.price)}
                                                                />
                                                            </Col>
                                                        </Row>}
                                                >
                                                    <InputGroup>
                                                        <StyledDropdownButton
                                                            variant="outline-secondary"
                                                            title={(item.billingType === BillingTypes.Purchase) ? 'Purchase' : (item.billingType === BillingTypes.Expense) ? 'Expense' : 'Invoice'}
                                                            id={formFields.bundleBillingType.id}
                                                            disabled={(item.chargeCategoryId) ? true : false}
                                                        >
                                                            <Dropdown.Item
                                                                active={(item.billingType === BillingTypes.Invoice)}
                                                                onClick={async () => {
                                                                    await setFieldValue('bundleServices.' + i + '.billingType', BillingTypes.Invoice);
                                                                }}
                                                            >Invoice</Dropdown.Item>
                                                            <Dropdown.Item
                                                                active={(item.billingType === BillingTypes.Purchase)}
                                                                onClick={async () => {
                                                                    await setFieldValue('bundleServices.' + i + '.billingType', BillingTypes.Purchase);
                                                                }}
                                                            >Purchase</Dropdown.Item>
                                                            <Dropdown.Item
                                                                active={(item.billingType === BillingTypes.Expense)}
                                                                onClick={async () => {
                                                                    await setFieldValue('bundleServices.' + i + '.billingType', BillingTypes.Expense);
                                                                }}
                                                            >Expense</Dropdown.Item>
                                                        </StyledDropdownButton>
                                                        <Form.Control
                                                            as = {CurrencyInput}
                                                            isInvalid={!!(errors && errors.bundleServices && errors.bundleServices[i] && errors.bundleServices[i].price)}
                                                            
                                                            allowDecimals={true}
                                                            allowNegativeValue={false}
                                                            disableGroupSeparators={true}
                                                            prefix={Utils.getCurrency()}

                                                            id={formFields.bundlePrice.id}
                                                            placeholder={formFields.bundlePrice.placeholder}
                                                            value={item.price}
                                                            onValueChange={async (value) => {
                                                                await setFieldValue('bundleServices.' + i + '.price', value);
                                                                    
                                                                if(!Utils.isNumberAvoidZero(value)){
                                                                    await setFieldValue('bundleServices.' + i + '.isTaxInclusive', false);
                                                                }
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{(errors && errors.bundleServices && errors.bundleServices[i] && errors.bundleServices[i].price) && errors.bundleServices[i].price}</Form.Control.Feedback>
                                                        <div className={'text-danger w-100 ' + ((item.isTaxInclusive && Utils.isNumber(item.price)) ? 'd-block' : 'd-none')} style={{ position: 'relative', left: '100px' }}>{taxRate.getTaxRate(item.isTaxInclusive, item, this.props.taxRate)}</div>
                                                    </InputGroup>
                                                </InputLayout>
                                            </>}
                                            
                                            <InputLayout
                                                label={formFields.bundleUseBinWeightQuantity.label}
                                                labelProps={{
                                                    xs: 'auto',
                                                    lg: 3,
                                                }}
                                                inputProps={{
                                                    xs: 'auto',
                                                    lg: 6,
                                                    className: 'd-flex align-items-center',
                                                }}
                                            >
                                                {/* <a href={'/'} 
                                                    //className={'link-default-underline'}
                                                    style={{ 
                                                        userSelect: 'none',
                                                        display: 'flex',
                                                        paddingTop: '10px',
                                                        paddingBottom: '10px',
                                                    }}
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        //await setFieldValue('bundleServices.' + i + '.useBinWeightQuantity', !item.useBinWeightQuantity);
                                                    }}
                                                >{item.useBinWeightQuantity ? 'Yes' : 'No'}</a> */}
                                                <div>{item.useBinWeightQuantity ? 'Yes' : 'No'}</div>
                                            </InputLayout>
                                            
                                            {item.useBinWeightQuantity && <InputLayout
                                                label={formFields.bundleNonBillableWeight.label}
                                                inputProps={{
                                                    xs: 12,
                                                    lg: 6
                                                }}
                                            >
                                                <InputGroup>
                                                    <InputGroup.Text>First</InputGroup.Text>
                                                    <Form.Control
                                                        type="number"
                                                        id={formFields.bundleNonBillableWeight.id}
                                                        placeholder={formFields.bundleNonBillableWeight.placeholder}
                                                        value={item.nonBillableWeight}
                                                        onChange={async (e) => {
                                                            await setFieldValue('bundleServices.' + i + '.nonBillableWeight', e.target.value);
                                                        }}
                                                        isInvalid={!!(errors && errors.bundleServices && errors.bundleServices[i] && errors.bundleServices[i].nonBillableWeight)}
                                                    />
                                                    <InputGroup.Text>{(Utils.getBillingUOM() == ReportUOM.kg) ? 'kg' : 'ton(s)'}</InputGroup.Text>
                                                    <Form.Control.Feedback type="invalid">{(errors && errors.bundleServices && errors.bundleServices[i] && errors.bundleServices[i].nonBillableWeight) && errors.bundleServices[i].nonBillableWeight}</Form.Control.Feedback>
                                                </InputGroup>
                                            </InputLayout>}
                                            
                                        </Col>
                                        {(i < (values.bundleServices.length-1)) && <Col xs={12}><hr /></Col>}
                                    </Row>
                                })
                                :
                                <></>
                            }
                        </div>

                        {(values.binTypeId && values.wasteTypeId) && <StyledAddButton href={'/'} 
                            className={'link-secondary'}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                validateForm().then((err) => {
                                    if(!!err.contactPersons){
                                        Utils.toast('You must fill in the required fields', 'error');
                                    } else {
                                        push(this.bundleService);
                                    }
                                })
                            }}
                        >+ Add Bundle Service</StyledAddButton>}

                    </>
                )}
            </FieldArray>

        </>
    }
    
    infoForm = (formOptions) => {
        return <Row className={'justify-content-center justify-content-lg-start'}>
            <Col xs={12} sm={4} lg={3}>
                <Row>
                    <Col xs={'auto'} className={'pt-10'}>
                        <LightbulbIcon />
                    </Col>
                    <Col xs={true}>
                        <Row className={'pt-10 pb-10'}>
                            <Col xs={12} className={'mb-2 fw-bold'}>Waste / Bin Type As Main Service</Col>
                            <Col xs={12}>A service involving waste and bin type is considered as your main service. You need to declare waste type and bin type for your collection or disposal jobs.</Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

            <Col xs={'auto'} className={'d-none d-sm-flex'}>
                <div className={'vr'}></div>
            </Col>
            
            <Col xs={12} sm={4} lg={3}>
                <Row>
                    <Col xs={'auto'} className={'pt-10'}>
                        <LightbulbIcon />
                    </Col>
                    <Col xs={true}>
                        <Row className={'pt-10 pb-10'}>
                            <Col xs={12} className={'mb-2 fw-bold'}>Additional Service</Col>
                            <Col xs={12}>Service that does not involve waste/bin types is considered as additional service. Examples may be administrative charges, cleaning services etc. </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

            <Col xs={'auto'} className={'d-none d-lg-flex'}>
                <div className={'vr'}></div>
            </Col>

            <Col xs={12} sm={8} lg={3}>
                <Row>
                    <Col xs={'auto'} className={'pt-10'}>
                        <LightbulbIcon />
                    </Col>
                    <Col xs={true}>
                        <Row className={'pt-10 pb-10'}>
                            <Col xs={12} className={'mb-2 fw-bold'}>Bundle Service</Col>
                            <Col xs={12}>A Bundle Service binds 1 main service with at least 1 additional service item together and is treated as a single service.</Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    }

    footer = (formOptions) => {
        let {
            values,
            validateForm,
        } = formOptions;
        
        return <Row>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >
                    Cancel
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'primary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        validateForm().then((err) => {
                            if(!_.isEmpty(err)){
                                Utils.toast('You must fill in the required fields', 'error');
                            } else {
                                if(this.props.onSave){
                                    this.props.onSave(() => {
                                        return this.prepareData(values);
                                    });
                                }
                            }
                        })
                    }}
                >
                    Save
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
        </Row>
    }
    /* END FORM */

    
    /* DIALOG */
    setRemoveDialog = () => {
        return <RemoveDialog 
          title={'Remove service item'}
          show={this.state.isRemoveDialog}
          isLoading={false}
          onCancel={() => {
            this.setState({
              isRemoveDialog: false,
            });
          }}
          onRemove={() => {
            this.setState({
                isRemoveDialog: false,
            }, () => {
                if(this.refForm && this.refForm.current){
                    let { values } = this.refForm.current;
                    
                    let data = this.prepareData(values);
    
                    this.callDeleteApi(data, (res, isError) => {
                        if(!isError){
                            this.setSubmit(values);
                        } else {
                            this.setLoading(false);
                        }
                    });
                } else {
                    this.setLoading(false);
                }
            });
          }}
        />
    }
    
    setPopupBillingCategoryForm = () => {
        return <Popup 
            className={'no-bg'}
            auth={this.props.auth}
            settingsType={this.props.settingsType}

            show={this.state.popupShowBillingCategoryForm}
            isLoading={this.state.popupLoadingBillingCategoryForm}
            id={this.state.popupIdBillingCategoryForm}
            obj={this.state.popupIdBillingCategoryObj}
            isCopy={this.state.popupIsCopyBillingCategoryForm}
            newItem={this.state.popupIdBillingCategoryNewItem}

            showHeader={true}
            header={this.state.popupIdBillingCategoryForm ? this.state.popupIsCopyBillingCategoryForm ? 'Copy Billing Category' : 'Edit Billing Category' : 'Add Billing Category'}
            showFooter={true}
            centerFooter={'start'}
            footer={({ isLoading, id, obj, onHide, onSave }) => {
                return <Row style={{ width: '100%' }}>
                    <Col xs={'auto'}>
                        <LoadingButton
                            isLoading={isLoading}
                            onClick={() => {
                                onSave(obj)
                            }}
                        >Save</LoadingButton>
                    </Col>
                    <Col xs={'auto'}>
                        <Button
                            variant={'light'}
                            disabled={isLoading}
                            onClick={onHide}
                        >Cancel</Button>
                    </Col>
                </Row>
            }}

            scrollable={true}
            centered={true}
            closeButton={true}
            fullscreen={true}
            noBodyPadding={true}

            onHide={() => {
                this.setState({
                    popupShowBillingCategoryForm: false,
                    popupLoadingBillingCategoryForm: false,
                    popupIdBillingCategoryForm: 0,
                    popupIdBillingCategoryObj: null,
                    popupIsCopyBillingCategoryForm: false,
                    popupIdBillingCategoryNewItem: null,
                });
            }}
            onSave={(obj) => {
                if(this.refBillingCategoryForm && this.refBillingCategoryForm.current && this.refBillingCategoryForm.current.refForm && this.refBillingCategoryForm.current.refForm.current){
                    let form = this.refBillingCategoryForm.current;
                    let { validateForm, values } = this.refBillingCategoryForm.current.refForm.current;

                    validateForm().then((err) => {
                        if(!_.isEmpty(err)){
                            Utils.toast('You must fill in the required fields', 'error');
                        } else {
                            form.setLoading(true);

                            let data = form.prepareData(values);

                            if (values.isEdit) {
                                obj.callUpdateApi(data, (newItem, index) => {
                                    this.setState({
                                        popupShowBillingCategoryForm: false,
                                        popupLoadingBillingCategoryForm: false,
                                        popupIdBillingCategoryForm: 0,
                                        popupIdBillingCategoryObj: null,
                                        popupIsCopyBillingCategoryForm: false,
                                        popupIdBillingCategoryNewItem: null,
                                    }, () => {
                                        if(newItem && obj.props.onChange){
                                            obj.props.onChange(newItem.value, newItem, index)
                                        }

                                        form.setLoading(false);
                                    });
                                });
                            } else {
                                obj.callCreateApi(data, (newItem, index) => {
                                    this.setState({
                                        popupShowBillingCategoryForm: false,
                                        popupLoadingBillingCategoryForm: false,
                                        popupIdBillingCategoryForm: 0,
                                        popupIdBillingCategoryObj: null,
                                        popupIsCopyBillingCategoryForm: false,
                                        popupIdBillingCategoryNewItem: null,
                                    }, () => {
                                        if(newItem && obj.props.onChange){
                                            obj.props.onChange(newItem.value, newItem, index)
                                        }

                                        form.setLoading(false);
                                    });
                                });
                            }
                        }
                    })
                }
            }}
            
            onLoading={(state) => {
                this.setState({
                    popupLoadingBillingCategoryForm: state,
                });
            }}
        >
            {(props) => {
                return <BillingCategoryForm
                    ref={this.refBillingCategoryForm}
                    {...props}
                />
            }}
        </Popup>
    }
    /* END DIALOG */


    render() {
        return <>
            <Formik
                innerRef={this.refForm}
                validationSchema={formSchema}
                initialValues={this.state.initialValues}
                enableReinitialize={true}
                validateOnMount={false}
                validateOnChange={false}
                onSubmit={(form, e) => {
                    this.setLoading(true);
                    let data = this.prepareData(form);

                    if(this.state.isCopy){
                        this.callCopyApi(data, (res, isError) => {
                            if(!isError){
                                this.setSubmit(form);
                            } else {
                                this.setLoading(false);
                            }
                        });
                    } else {
                        if(form.isEdit){
                            this.callUpdateApi(data, (res, isError) => {
                                if(!isError){
                                    this.setSubmit(form);
                                } else {
                                    this.setLoading(false);
                                }
                            });
                        } else {
                            this.callCreateApi(data, (res, isError) => {
                                if(!isError){
                                    this.setSubmit(form);
                                } else {
                                    this.setLoading(false);
                                }
                            });
                        }
                    }
                }}
            >
                {(formOptions) => {
                    return <Form className='w-100' style={{ overflowX: 'hidden' }} noValidate autoComplete="off" onSubmit={formOptions.handleSubmit} onKeyDown={(keyEvent) => {
                        // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                        //     keyEvent.preventDefault();
                        // }
                    }}>
                        <Row style={{ paddingBottom: (this.props.isFooter) ? '100px' : 'initial' }}>
                            <Col xs={12} className={'ps-7 pe-7 pt-5 pb-5'}  style={{ backgroundColor: '#F7F7F7' }}>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={10} className={'ps-2 pe-2 ps-md-7 pe-md-7'}>{this.form(formOptions)}</Col>
                                </Row>
                            </Col>
                            <Col xs={12} className={'ps-7 pe-7 pt-5 pb-5'}>
                                <Row>
                                    <Col xs={12} md={12} lg={12} xl={10} className={'ps-2 pe-2 ps-md-7 pe-md-7'}>{this.bundleServicesForm(formOptions)}</Col>
                                </Row>
                            </Col>
                            <Col xs={12} className={'ps-7 pe-7'}><hr /></Col>
                            <Col xs={12} className={'ps-7 pe-7'}>{this.infoForm(formOptions)}</Col>
                            {(this.props.isFooter) && <Col
                                xs={12} 
                                className={'ps-2 pe-2 ps-md-7 pe-md-7'}
                                style={{
                                    position: 'fixed',
                                    left: '0px',
                                    bottom: '0px',
                                    backgroundColor: 'white',
                                    paddingBottom: '10px',
                                    paddingTop: '10px',
                                    borderTop: '1px solid #EFF2F5',
                                }}
                            >{this.footer(formOptions)}</Col>}
                        </Row>
                        {this.setRemoveDialog()}
                        {this.setPopupBillingCategoryForm()}
                        
                        <DefaultTaxRatePopup ref={this.defaultTaxRatePopupRef} />
                    </Form>
                }}
            </Formik>
        </>
    }
}


const mapStateToProps = state => {
    return {
      ...state,
      ...Utils.mapStateToProps(state),
    };
};
const mapDispatchToProps = (dispatch) => ({
    dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
    dispatchApiCallPost: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPost(p1, p2, p3, p4, p5, p6)),
    dispatchApiCallPut: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPut(p1, p2, p3, p4, p5, p6)),
    dispatchApiCallDelete: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallDelete(p1, p2, p3, p4, p5, p6)),
    clear: (payload) => dispatch(clear(payload)),
    action: {
        taxRate: {
            getCustomerProfileApi: (payload) => dispatch(taxRate.getCustomerProfileApi(payload)),
            setMainTaxRate: (payload) => dispatch(taxRate.setMainTaxRate(payload)),
            setTaxRate: (payload) => dispatch(taxRate.setTaxRate(payload)),
            getDefaultTaxRateApi: () => dispatch(taxRate.getDefaultTaxRateApi()),
            callSaveDefaultTaxRateApi: (payload, callback) => dispatch(taxRate.callSaveDefaultTaxRateApi(payload, callback)),
            setShowSettings: (payload) => dispatch(taxRate.setShowSettings(payload)),
            setDefaultTaxRate: (payload) => dispatch(taxRate.setDefaultTaxRate(payload)),
        },
    }
});
  
  
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LocalServiceForm);
